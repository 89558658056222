import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { iBisAuthService, iBisAuthModule, iBisEditUserService } from '@dohu/ibis-auth';
import { DataService, UIService, ScreenService } from './services/index';
import { FooterModule } from './shared/footer/footer.component';
import { LoginFormModule } from './shared/login/login-form.component';
import { NavToolbarModule } from './shared/nav-toolbar/nav-toolbar.component';
import { SingleCardModule } from './shared/single-card/single-card.component';
import { DxFormModule, DxScrollViewModule, DxLoadPanelModule } from 'devextreme-angular';
import { ViewModule } from './view/view.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { iBisCommonModule, iBisGridConfigService, iBisLanguageService } from '@dohu/ibis-common';
import { ProxyService } from './services/proxy.service';
import { EditFidelityService } from './view/admin/fidelity-card/edit-fidelity/edit-fidelity.service';
import { EditReceiptService } from './view/sales/receipt/edit-receipt/edit-receipt.service';
import { ReceivingService } from './view/supply/receiving/receiving.service';
import { GlobalSearchService } from './shared/global-search/global-search.service';
import { EditCompanyService } from './view/admin/company/edit-company/edit-company.service';
import { EditPersonService } from './view/admin/person/edit-person/edit-person.service';
import { EditOrderService } from './view/supply/order/edit-order/order-edit.service';
import { PromoEditService } from './view/sales/promo/edit-promo/promo-edit.service';
import { PromoItemService } from './view/sales/promo/edit-item/promo-item.service';
import { EditReceivingItemService } from './view/supply/receiving/edit-receiving-item/edit-receiving-item.service';
import { EditFacilityService } from './view/admin/facility/edit-facility/edit-facility.service';
import { EditEntityRoleService } from './view/admin/user/edit-entity-role/edit-entity-role.service';
import { EditInvoiceService } from './view/sales/sales-invoice/edit-invoice/edit-invoice.service';
import { SearchFidelityService } from './view/admin/fidelity-card/search-fidelity-card/search-fidelity-card.service';
import { EditFidelityPersonService } from './shared/edit-fidelity-person/edit-fidelity-person.service';
import { EditPrescriptionService } from './view/sales/receipt/edit-receipt/edit-prescription.service';
import { ReturnProductService } from './view/supply/return/return-product.service';
import { EditWithdrawalService } from './view/stock/withdrawal/edit-withdrawal/edit-withdrawal.service';
import { EditPhysicalInventoryService } from './view/stock/physical-inventory/edit-physical-inventory.service';
import { EditPriceChangeService } from './view/stock/price-change/edit-price-change.service';
import { EditProductService } from './shared/edit-product/edit-product.service';
import { EditCashRegisterKitService } from './view/admin/cash-register-kit/edit-kit/edit.kit.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ViewModule,
    AppRoutingModule,
    FooterModule,
    LoginFormModule,
    NavToolbarModule,
    SingleCardModule,
    DxFormModule,
    iBisAuthModule,
    iBisCommonModule,
    DxScrollViewModule,
    DxLoadPanelModule
  ],
  providers: [iBisAuthService,
    iBisLanguageService,
    DataService,
    UIService,
    ScreenService,
    HttpClient,
    iBisGridConfigService,
    ProxyService,
    EditInvoiceService,
    EditReceiptService,
    EditFacilityService,
    EditEntityRoleService,
    EditCompanyService,
    EditPersonService,
    EditOrderService,
    EditPhysicalInventoryService,
    EditReceivingItemService,
    PromoEditService,
    PromoItemService,
    EditFidelityService,
    EditPriceChangeService,
    SearchFidelityService,
    EditFidelityPersonService,
    ReceivingService,
    GlobalSearchService,
    EditPrescriptionService,
    ReturnProductService,
    EditWithdrawalService,
    iBisEditUserService,
    EditProductService,
    EditCashRegisterKitService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
