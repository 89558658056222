import { Component, OnInit, ViewChild } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import { iBisAuthService } from '@dohu/ibis-auth';
import CustomStore from 'devextreme/data/custom_store';
import { EditCashRegisterKitService } from './edit-kit/edit.kit.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-cash-register-kit',
    templateUrl: './cash-register-kit.component.html'
})
export class CashRegisterKitComponent implements OnInit {

    dsCashRegisterKit: CustomStore;
    @ViewChild('cashRegisterGrid', { static: false }) cashRegisterGrid: DxDataGridComponent;

    constructor(public ui: UIService, public ds: DataService, public edit: EditCashRegisterKitService) { }

    ngOnInit() {
        this.dsCashRegisterKit = this.ds.getCashRegister();
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: 'Adaugă casă de marcat', icon: 'plus', onClick: (e: any) => {
                    this.edit.showPopup({ uploadTime: new Date() }, true).then((res: any) => {
                        if (res && res.isSaving) {
                            this.cashRegisterGrid.instance.refresh();
                        }
                    });
                }
            },
            location: 'after'
        });
    }

    editCashRegister = (ev: any) => {
        this.edit.showPopup(ev.row.data, true).then((res: any) => {
            if (res && res.isSaving) {
                this.cashRegisterGrid.instance.refresh();
            }
        });
    }

    downloadUserGuide = (ev: any) => {
        this.ds.getFileInfo(ev.row.data.id).load().then((res: any) => {
            if (res.data && res.data.length) {
                for (let i = 0; i < res.data.length; i++) {
                    const item = res.data[i];
                    if (this.edit.checkIfManual(item)) {
                        const apiUrl = environment.saasServer + 'Download/' + item.id;
                        this.ds.getFile(apiUrl);
                        return;
                    }
                }
            }
        });
    }

    downloadCashRegisterKit = (ev: any) => {
        this.ds.getFileInfo(ev.row.data.id).load().then((res: any) => {
            if (res.data && res.data.length) {
                for (let i = 0; i < res.data.length; i++) {
                    const item = res.data[i];
                    if (!this.edit.checkIfManual(item)) {
                        const apiUrl = environment.saasServer + 'Download/' + item.id;
                        this.ds.getFile(apiUrl);
                        return;
                    }
                }
            }
        });
    }
}
