import { Component, OnInit } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'app-medic',
  templateUrl: './medic.component.html',
  styleUrls: ['./medic.component.css']
})
export class MedicComponent implements OnInit {
  dsMedics: CustomStore;
  dsSpecialities: CustomStore;
  dsInsuranceHouse: CustomStore;

  constructor(public ui: UIService, public ds: DataService) { }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.dsMedics = this.ds.getMedics();
    this.dsSpecialities = this.ds.getEnumValue('SPECIALITY_TYPE');
    this.dsInsuranceHouse = this.ds.getEnumValue('INSURANCE_HOUSE');
  }

  onInitDetail(event: any, data: any) {
    if (event.component.option('dataSource') == null) {
      event.component.option('dataSource', this.ds.getMedicSpeciality(data.data.id));
    }
  }

  onMedicExpand(event) {
    event.component.collapseAll(-1);
  }

  toolbarPreparing(event: any) {
    this.ui.prepareToolbar(event, true);
  }
}
