import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import { EditOrderService } from './order-edit.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { ProxyService } from 'src/app/services/proxy.service';
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html'
})
export class EditOrderComponent implements OnInit, OnDestroy {
  dsOrderType: any;
  dsCurrency: any;
  dsFacility: any;
  dsOrderStatus: { id: number; name: string; }[];
  dsPartyTo: any;
  dsPartyFrom: any;
  dsContactMech: any;
  dseditOrderItem: any;
  readyForSaving = false;
  gridChange: Subject<any> = new Subject<any>();
  gridChangeSubscription: Subscription;

  @ViewChild('editOrderItemGrid', { static: false }) editOrderItemGrid: DxDataGridComponent;
  constructor(public ds: DataService, public ui: UIService, public edit: EditOrderService, public proxy: ProxyService) { }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.dsOrderType = this.ds.getEnumValue('ORDER_TYPE');
    this.dsCurrency = this.ds.getEnumValue('CURRENCY');
    this.dsFacility = this.ds.getFacility();
    this.dsPartyTo = this.ds.getParty(0);
    this.dsPartyFrom = this.ds.getParty(1);
    this.dsContactMech = this.ds.getEnumValue('MECH');

    this.gridChangeSubscription = this.gridChange.subscribe(
      (changes: any) => {
        if (changes && changes.length) {
          this.readyForSaving = changes.length ? true : false;
          for (let i = 0; i < changes.length; i++) {
            if (!changes[i].quantity || !changes[i].requiredDate) {
              this.readyForSaving = false;
              break;
            }
          }
        }
      });
  }

  ngOnDestroy() {
    // this.gridChangeSubscription.unsubscribe();
  }

  onDataSourceChange(ev: any) {
    if (ev.name === 'dataSource') {
      this.gridChange.next(this.edit.dsOrderItems);
    }
    if (ev.name === 'columns') {
      console.log(this.editOrderItemGrid);
    }
  }

  checkSaveCondition(ev: any, isDelete?: boolean) {
    if (isDelete) {
      const ds = this.edit.dsOrderItems;
      for (let i = 0; i < ds.length; i++) {
        ds[i].orderIndex = i + 1;
      }
    }
    this.gridChange.next(this.edit.dsOrderItems);
  }

  toolbarPreparing(e: any) {
    this.ui.prepareToolbar(e, true);
    e.toolbarOptions.items.unshift({
      widget: 'dxButton',
      location: 'after',
      options: {
        text: 'Adaugă articol',
        icon: 'plus',
        onClick: () => {
          if (this.proxy.globalSearch.gridInstance) {
            this.proxy.globalSearch.initGlobalSearch();
          }
          this.proxy.globalSearch.showPopup({ type: 'products' }, true).then((data) => {
            if (data) {
              const obj = {
                ModelName: 'OrderItem', // este irelevant
                orderIndex: this.edit.dsOrderItems.length + 1,
                productId: data.p_id || data.id,
                name: data.p_name || data.name,
                code: data.p_code || data.code,
                qtyPerPackage: data.p_qtyPerPackage || data.qtyPerPackage,
                quantity: 1,
                presentationMode: data.p_presentationMode || data.presentationMode,
                taxPercent: data.tax_percent,
                // facilityId: data.i_facilityId,
                requiredDate: null
              };

              let ep: any;
              if (this.edit.dsOrderItems.length !== 0) {
                ep = this.edit.dsOrderItems.find((x: any) => x.productId === obj.productId);
              }

              if (ep) {
                this.edit.dsOrderItems[ep.orderIndex - 1].quantity += obj.quantity;
              } else {
                this.edit.dsOrderItems.push(obj);
              }
              this.editOrderItemGrid.instance.refresh();
              this.gridChange.next(this.edit.dsOrderItems);
            }
          });
        }
      }
    });
  }

}


