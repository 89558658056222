import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import { ProxyService } from 'src/app/services/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fidelity-card',
  templateUrl: './fidelity-card.component.html'
})
export class FidelityCardComponent implements OnInit {

  dsfidelityCards: any;

  @ViewChild('gridfidelityCard', { static: false }) gridfidelityCard: DxDataGridComponent;
  onEditFidelityClick: any;
  onDeleteFidelityClick: any;
  dsFidelityType: any;

  constructor(public ds: DataService, public ui: UIService, public proxy: ProxyService, private router: Router) {
    if (!this.ds.isAdmin()) {
      this.router.navigateByUrl('/supply/receiving');
    }
  }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.dsfidelityCards = this.ds.getFidelityCardPersonView();
    this.dsFidelityType = this.ds.getEnumValue('FIDELITY_TYPE');

    this.onEditFidelityClick = this.onEditFidelityClickEv.bind(this);
    this.onDeleteFidelityClick = this.onDeleteFidelityClickEv.bind(this);
  }

  onDeleteFidelityClickEv(e: any) {
    e.event.preventDefault();
    this.proxy.editFidelity.remove(e.row.data.fc_id).then(() => {
      notify('Success', 'success', 2000);
      this.gridfidelityCard.instance.refresh();
    });
  }

  onEditFidelityClickEv(e: any) {
    e.event.preventDefault();
    this.proxy.editFidelity.showPopup(e.row.data.fc_id, true).then(() => {
      this.gridfidelityCard.instance.refresh();
    }, (error) => this.ds.serverError(error)
    );
  }

  toolbarPreparing(e: any) {
    this.ui.prepareToolbar(e, true);

    e.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        hint: 'Adauga card fidelitate',
        icon: 'plus',
        onClick: () => this.proxy.editFidelity.showPopup({ isInserting: true }).then(() => this.gridfidelityCard.instance.refresh())
      },
      location: 'after'
    });
  }
}
