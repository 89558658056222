import { Component, OnInit, ViewChild } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import { DxListComponent, DxFormComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';

@Component({
  selector: 'app-enumgeneral',
  templateUrl: './enumGeneral.component.html',
  styleUrls: ['./enumGeneral.component.css']
})
export class EnumGeneralComponent implements OnInit {

  dsEnumType: any[];
  dsEnumValue: CustomStore;

  enumTypeData: any = {};
  onEnumSaveClick: any;

  isEnumTypeVisible: boolean;
  isFidelitySelected: boolean;

  @ViewChild('enumTypeList', { static: false }) enumTypeList: DxListComponent;
  @ViewChild('EnumTypeForm', { static: false }) EnumTypeForm: DxFormComponent;

  constructor(public ui: UIService, public ds: DataService, private router: Router) {
    if (!this.ds.isAdmin()) {
      this.router.navigateByUrl('/supply/receiving');
    }
  }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.initListAndGrid();
    this.isEnumTypeVisible = false;

    this.onEnumSaveClick = this.onEnumSaveClickEv.bind(this);
  }

  enumTypeInit(e: any) {
    e.component.selectItem(1);
  }

  enumTypeClick(e: any) {
    if (e.itemData.id && e.itemData.id !== 0) {
      this.isFidelitySelected = e.itemData.code === 'FIDELITY_TYPE';
      this.dsEnumValue = this.ds.getEnumValueById(e.itemData.id);
    } else {
      this.isEnumTypeVisible = true;
    }
  }

  onRowInserting(event: any) {
    event.data.typeId = this.enumTypeList.selectedItems[0].id;
  }

  onEnumSaveClickEv(ev) {
    const validation = ev.validationGroup.validate();
    if (!validation.isValid) {
      notify('Trebuie să completați toate câmpurile obligatorii.', 'error', 3000);
      return;
    }
    this.ds.isLoadPanelVisible = true;
    this.ds.insertEnumType(this.enumTypeData).then((res) => {
      this.ds.isLoadPanelVisible = false;
      notify('Configurare adaugată cu success.', 'success', 3000);
      this.initListAndGrid();
      this.enumTypeList.instance.selectItem(1);
      this.isEnumTypeVisible = false;
      this.EnumTypeForm.instance.resetValues();
    }, error => {
      this.ds.isLoadPanelVisible = false;
      this.ds.serverError(error);
    });
  }

  initListAndGrid() {
    this.ds.getEnumType().load().then(data => {
      if (data && data.data.length > 0) {
        this.dsEnumType = data.data;
        this.dsEnumValue = this.ds.getEnumValueById(data.data[0].id);
        this.dsEnumType.unshift({ id: 0, description: 'Adaugă +' });
      }
    });
  }

  toolbarPreparing(event: any, enumGeneral = false) {
    if (enumGeneral) {
      this.ui.prepareToolbar(event, true);
      event.toolbarOptions.items.unshift({
        widget: 'dxButton',
        options: { hint: 'Adaugă', icon: 'plus', onClick: (e: any) => { event.component.addRow(); } },
        location: 'after'
      });
    }
  }

}

