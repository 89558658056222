import { Component, OnInit, ViewChild } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import { EditFacilityService } from './edit-facility/edit-facility.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { Router } from '@angular/router';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html'
})
export class FacilityComponent implements OnInit {
  dsFacility: CustomStore;
  dsFacilityType: CustomStore;

  dsWcSchedule: any;

  onEditFacilityClick: any;
  onRemoveScheduleClick: any;

  currentFacility: string;

  @ViewChild('gridFacility', { static: false }) gridFacility: DxDataGridComponent;
  @ViewChild('gridSchedule', { static: false }) gridSchedule: DxDataGridComponent;

  constructor(public ui: UIService, public ds: DataService, public edit: EditFacilityService, private router: Router) {
    if (!this.ds.isAdmin()) {
      this.router.navigateByUrl('/supply/receiving');
    }
  }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.dsFacility = this.ds.getFacility();
    this.dsFacilityType = this.ds.getEnumValue('FACILITY');
    this.onEditFacilityClick = this.onEditFacilityClickEv.bind(this);
    this.onRemoveScheduleClick = this.onRemoveScheduleClickEv.bind(this);
  }

  onEditFacilityClickEv(e: any) {
    e.event.preventDefault();
    this.edit.showPopup(e.row.data, true).then(() => {
      this.gridFacility.instance.refresh();
    }, (error) => { this.ds.serverError(error); });
  }

  onFacilityExpand(ev: any) {
    ev.component.collapseAll(-1);
    this.currentFacility = ev.key;
    this.dsWcSchedule = this.ds.getFacilityPlanningSchedule(ev.key);
  }

  onScheduleInserted(ev: any) {
    if (this.currentFacility && ev.key) {
      this.ds.addScheduleToFacility(ev.key, this.currentFacility).then(data => {
        ev.component.refresh();
      });
    }
  }

  onRemoveScheduleClickEv(ev: any) {
    confirm('Ești sigur că vrei să ștergi această înregistrare ?', 'Confirmare').
      then((val: boolean) => {
        if (val) {
          this.ds.getFacilityScheduleById(ev.row.data.id).then((res: any) => {
            if (res) {
              this.ds.removeScheduleToFacility(res.id).then(() => {
                notify('Success', 'success', 2000);
                this.gridSchedule.instance.refresh();
              });
            }
          });
        }
      });
  }

  onInitNewRow(ev: any) {
    const y = new Date().getFullYear(),
      m = new Date().getMonth(),
      d = new Date().getDate();
    ev.data.fromHour = new Date(y, m, d, 0, 0, 0, 0);
    ev.data.toHour = new Date(y, m, d, 0, 0, 0, 0);
  }

  prepareToolbar(event: any) {
    this.ui.prepareToolbar(event, true);
    event.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        hint: 'Adaugă', icon: 'plus', onClick: (e: any) => {
          this.edit.showPopup({ openDate: new Date() }).then(() => {
            this.gridFacility.instance.refresh();
            this.edit.createDefault();
          });
        }
      },
      location: 'after'
    });
  }
}
