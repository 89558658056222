import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import { ProxyService } from 'src/app/services/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { ReturnProductService } from '../../supply/return/return-product.service';

@Component({
  selector: 'app-withdrawal',
  templateUrl: './withdrawal.component.html'
})
export class WithdrawalComponent implements OnInit {

  dsWithdrawal: CustomStore;
  @ViewChild('gridWithdrawal', { static: false }) gridWithdrawal: DxDataGridComponent;

  constructor(public ds: DataService, public ui: UIService, public returnProduct: ReturnProductService, public proxy: ProxyService) { }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    if (!this.ds.currentFacility) {
      this.ds.isFacilityLoaded.subscribe(() => {
        this.dsWithdrawal = this.ds.getInventory(true);
      });
    } else {
      this.dsWithdrawal = this.ds.getInventory(true);
    }

  }

  calculateValIntrare(row: any) {
    return row ? (Math.round(row.i_pricePerPackage * row.i_quantity * 100) / 100) : null;
  }
  calculateUnits(row: any) {
    return row ? (Math.round(row.p_qtyPerPackage * row.i_quantity * 100) / 100) +
      (Math.round(row.i_fQuantity / row.p_qtyPerPackage * 100) / 100) : null;
  }
  calculateStockDays(row: any) {
    return row && row.i_receivedDate ? Math.floor((new Date().getTime() -
      new Date(row.i_receivedDate).getTime()) / 1000 / 60 / 60 / 24) : null;
  }


  toolbarPreparing(e: any) {
    this.ui.prepareToolbar(e, true);

    e.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        text: 'Retrage produs',
        icon: 'plus',
        onClick: (ev: any) => {
          this.proxy.editWithdrawal.selectedKeys = [];
          this.proxy.editWithdrawal.showPopup({}, true).then(() => {
            this.gridWithdrawal.instance.refresh();
          });
        }
      },
      location: 'after'
    });
  }

}
