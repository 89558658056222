import { Component, OnInit } from '@angular/core';
import { UIService, DataService } from '../../../app/services';
import { EditProductService } from './edit-product.service';
import CustomStore from 'devextreme/data/custom_store';

@Component({
    selector: 'app-edit-product',
    templateUrl: './edit-product.component.html'
})
export class EditProductComponent implements OnInit {

    dsTaxType: CustomStore;

    constructor(public ui: UIService, public ds: DataService, public edit: EditProductService) { }

    ngOnInit() {
        if (!this.ds.auth.isAuth) { return; }
        this.dsTaxType = this.ds.getTax();
    }

    onCodeBarKey = (ev: any) => {
        if ((ev.event.keyCode === 17 || ev.event.keyCode === 74) && ev.event.ctrlKey === true) {
            ev.event.preventDefault();
        }
    }

    onBooleanChanged = (ev: any) => {
        if (ev.addedItems && ev.addedItems.length) {
            const item = this.edit.dsBooleans.find((x: any) => x.fieldName === ev.addedItems[0].fieldName);
            item.value = true;
        } else {
            const item = this.edit.dsBooleans.find((x: any) => x.fieldName === ev.removedItems[0].fieldName);
            item.value = false;
        }
    }

}
