import { Component, OnInit, ViewChild } from '@angular/core';
import { UIService } from '../../../services/ui.service';
import { DataService } from '../../../services/data.service';
import notify from 'devextreme/ui/notify';
import { ProxyService } from 'src/app/services/proxy.service';
import { EditPriceChangeService } from './edit-price-change.service';
import { DxDataGridComponent } from 'devextreme-angular';

@Component({
    selector: 'app-edit-price-change',
    templateUrl: './edit-price-change.component.html'
})
export class EditPriceChangeComponent implements OnInit {

    calculateDif: any;
    onSaveClose: any;
    @ViewChild('gridPriceChangeItem', { static: false }) gridPriceChangeItem: DxDataGridComponent;

    constructor(public ui: UIService, public ds: DataService, public edit: EditPriceChangeService, public proxy: ProxyService) {
    }

    ngOnInit() {
        this.calculateDif = this.calculateDifEv.bind(this);
        this.onSaveClose = this.onSaveCloseEv.bind(this);
    }

    toolbarPreparing(ev: any) {
        this.ui.prepareToolbar(ev, true);
        ev.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: 'Adaugă',
                text: 'Adaugă produs',
                icon: 'plus',
                onClick: (e: any) => {
                    if (this.proxy.globalSearch.gridInstance) {
                        this.proxy.globalSearch.initGlobalSearch();
                    }
                    this.proxy.globalSearch.showPopup({ type: 'stock' }, true).then((data) => {
                        if (data) {
                            const obj = {
                                orderIndex: this.edit.dsPriceChangeItem.length + 1,
                                productId: data.p_id,
                                inventoryId: data.i_id,
                                pName: data.p_name,
                                pCode: data.p_code,
                                uom: data.uom_code,
                                tax_percent: data.tax_percent,
                                pricePerPackage: data.i_pricePerPackage || 0,
                                quantity: data.i_quantity || 0,
                                newPricePerPackage: 0
                            };
                            this.edit.dsPriceChangeItem.push(obj);
                            this.gridPriceChangeItem.instance.refresh();
                        }
                    });
                }
            },
            location: 'after'
        });
    }

    calculateValueEv(rowInfo: any) {
        return Math.round((rowInfo.quantity * rowInfo.pricePerPackage) * 100) / 100;
    }

    calculateTaxEv(rowInfo: any) {
        return Math.round((((Math.round((rowInfo.quantity * rowInfo.pricePerPackage) * 100) / 100)
            * rowInfo.tax_percent) / 100) * 100) / 100;
    }

    calculateNewValueEv(rowInfo: any) {
        if (rowInfo.newPricePerPackage) {
            return Math.round((rowInfo.quantity * rowInfo.newPricePerPackage) * 100) / 100;
        }
    }

    calculateNewTaxEv(rowInfo: any) {
        if (rowInfo.newPricePerPackage) {
            return Math.round((((Math.round((rowInfo.quantity * rowInfo.newPricePerPackage) * 100) / 100)
                * rowInfo.tax_percent) / 100) * 100) / 100;
        }
    }

    calculateDifEv(rowInfo: any) {
        if (rowInfo.newPricePerPackage) {
            if (rowInfo.newPricePerPackage === 0) {
                return (0 - (rowInfo.quantity * rowInfo.pricePerPackage));
            }
            return this.ds.roundTo((rowInfo.quantity * rowInfo.newPricePerPackage) - (rowInfo.quantity * rowInfo.pricePerPackage), 2);
        }
    }

    onSaveCloseEv(ev: any) {
        for (let i = 0; i < this.edit.dsPriceChangeItem.length; i++) {
            if (this.edit.dsPriceChangeItem[i].newPricePerPackage <= 0 || this.edit.dsPriceChangeItem[i].newPricePerPackage === undefined) {
                notify('Prețul nou unitar invalid!', 'error', 3000);
                return;
            }
        }
        this.edit.onSaveClose(ev);
    }
}
