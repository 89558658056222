import { Component, OnInit } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import { EditFidelityService } from './edit-fidelity.service';
import { ProxyService } from 'src/app/services/proxy.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-edit-fidelity',
  templateUrl: './edit-fidelity.component.html'
})
export class EditFidelityComponent implements OnInit {
  dsFidelityType: any;
  dsPerson: any;
  personInstance: any;
  onSaveCustom: any;

  constructor(public ds: DataService, public ui: UIService, public edit: EditFidelityService, public proxy: ProxyService) { }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.dsFidelityType = this.ds.getEnumValue('FIDELITY_TYPE');
    this.dsPerson = this.ds.getPersonWithoutCard();
    this.onSaveCustom = this.onSaveCustomEv.bind(this);
  }

  onPersonInit(event: any) {
    this.personInstance = event.component;
  }
  openPersonAdd(event: any) {
    this.proxy.editPerson.showPopup(null).then(() => {
      if (this.personInstance) {
        this.personInstance.getDataSource().reload();
      }
    });
    event.event.preventDefault();
    event.event.stopPropagation();
  }

  onSaveCustomEv(e: any) {
    if (!this.edit.model.id) {
      notify('Trebuie sa selectezi/adaugi o persoana', 'error', 3000);
      return;
    } else {
      this.edit.onSaveClose(e);
    }
  }
}
