import { Injectable } from '@angular/core';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services';

@Injectable()
export class EditFidelityService extends iBisEditService {

  constructor(public ds: DataService) {
    super(ds.lg);
    this.validation = 'fidelityValidation';
  }

  createDefault() {
    return { date: new Date() };
  }

  getById(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      EntityStore.fromQuery(new EntityQuery('FidelityCard').eq('id', id)).single().then((obj) => {
        if (obj === null) {
          reject();
        } else {
          resolve(obj);
        }
      }, (e) => reject(e));
    });
  }

  onRemove(id: string): Promise<void> {
    return EntityStore.fromQuery(new EntityQuery('FidelityCard')).remove(id);
  }

  onSaveEv(): Promise<any> {
    const s = EntityStore.fromQuery(new EntityQuery('FidelityCard'));
    return this.model.isInserting ? s.insert(this.model) : s.update(this.model.id, this.model);
  }

  reset(): void {
    this.model = {};
  }
}
