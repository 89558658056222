import { Component, OnInit } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';

@Component({
    selector: 'app-expired-product',
    templateUrl: './expired-product.component.html'
})
export class ExpiredProductComponent implements OnInit {

    dsExpired: CustomStore;
    constructor(public ui: UIService, public ds: DataService) { }

    ngOnInit() {
        if (!this.ds.auth.isAuth) { return; }
        // const nextDay = new Date();
        // nextDay.setDate(nextDay.getDate() + 1);
        // this.dsExpired = this.ds.getExpiredProducts(nextDay);
    }


    onMedicExpand(event) {
        event.component.collapseAll(-1);
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);

        event.toolbarOptions.items.unshift({
            widget: 'dxDateBox',
            options: {
                width: '150px',
                placeholder: 'Selectează data',
                showClearButton: true,
                displayFormat: 'dd.MM.yyyy',
                value: new Date(),
                onInitialized: (e: any) => {
                    const nextDay = new Date();
                    nextDay.setDate(nextDay.getDate() + 1);
                    this.dsExpired = this.ds.getExpiredProducts(nextDay);
                },
                onValueChanged: (e: any) => {
                    if (e.event) {
                        const nextDay = new Date(e.value);
                        nextDay.setDate(nextDay.getDate() + 1);
                        this.dsExpired = this.ds.getExpiredProducts(nextDay);
                    }
                },
            },
            location: 'before'
        });
    }
}
