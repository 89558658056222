import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent } from 'devextreme-angular';
import { ProxyService } from 'src/app/services/proxy.service';
import { EditReceiptService } from './edit-receipt.service';
import { EntityStore } from '@dohu/ibis-entity';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-receipt',
  templateUrl: './edit-receipt.component.html'
})
export class EditReceiptComponent implements OnInit, OnDestroy {

  dsUom: CustomStore;
  dsProductType: CustomStore;
  dsTax: CustomStore;
  dsFidelityCards: CustomStore;
  dsReceipt: CustomStore;

  onPercentDiscountChange: any;
  onNumericDiscountChange: any;

  maxDate: any;
  calcTotalDiscount: any;

  @ViewChild('gridProduct', { static: false }) gridProduct: DxDataGridComponent;
  personInstance: any;
  onFidelityFindClick: any;
  onFidelityCardChange: any;
  onFidelityKeyDown: any;
  customizeSummary: any;
  quantityEditorOptions: any;
  receiptData: any = {};

  isPrintReceiptVisible = false;

  addBtInst: any;
  editModelSubscription: Subscription;

  constructor(public ui: UIService, public ds: DataService, public edit: EditReceiptService, public proxy: ProxyService) { }

  ngOnInit() {
    this.editModelSubscription = this.edit.modelChange.subscribe(
      (modelChanged: boolean) => {
        if (modelChanged) {
          if (this.addBtInst) {
            this.addBtInst.option('visible', !this.edit.model.fromPrescription && this.edit.model.statusId !== 1);
          }
        }
      });
    this.onFidelityFindClick = this.onFidelityFindClickEv.bind(this);
    this.onFidelityKeyDown = this.onFidelityKeyDownEv.bind(this);
    this.onFidelityCardChange = this.onFidelityCardChangeEv.bind(this);
    this.onPercentDiscountChange = this.onPercentDiscountChangeEv.bind(this);
    this.onNumericDiscountChange = this.onNumericDiscountChangeEv.bind(this);
    this.calcTotalDiscount = this.calcTotalDiscountEv.bind(this);
    this.customizeSummary = this.customizeSummaryEv.bind(this);
    // this.calcTotalPrice = this.calcTotalPriceEv.bind(this);
    this.maxDate = new Date();

    this.quantityEditorOptions = {
      format: 'fixedPoint',
      precision: 0
    };
    if (!this.ds.auth.isAuth) { return; }
    this.dsReceipt = this.ds.getReceipt();
    this.dsFidelityCards = this.ds.getFidelityCardPersonView(true);
  }

  ngOnDestroy() {
    if (this.editModelSubscription) {
      this.editModelSubscription.unsubscribe();
    }
  }

  onFidelityKeyDownEv(event: any) {
    event.event.preventDefault();
    event.event.stopPropagation();
    // const code = event.event.charCode;
    // if (code !== 99999) {
    //   event.event.preventDefault();
    //   event.event.stopPropagation();
    // }
  }

  onFidelityCardChangeEv(ev: any) {
    if (ev.event && ev.previousValue && ev.value.length === 0) {
      this.edit.model.fidelityCard = { id: '', no: '', name: '' };
      this.edit.model.fidelityCardId = null;
      this.edit.model.percentDiscount = 0;
      this.edit.disableGlobalDiscount = false;
      this.edit.calculateDiscounts(2, 0, this.edit.model.discount);
    }
    if (this.edit.model.fidelityCard && this.edit.model.fidelityCard.id) {
      this.edit.model.fidelityCardId = this.edit.model.fidelityCard.id;
      this.edit.discountType = 2;
      this.edit.model.percentDiscount = this.edit.model.fidelityCard.discount;
      this.edit.calculateDiscounts(2, 0, this.edit.model.discount);
      this.edit.disableGlobalDiscount = true;
    }
    this.gridProduct.instance.refresh();
  }

  openPersonAdd(event: any) {
    this.proxy.editFidelity.showPopup(null).then(() => {
      if (this.personInstance) {
        this.personInstance.getDataSource().reload();
      }
    });
    event.event.preventDefault();
    event.event.stopPropagation();
  }

  onFidelityFindClickEv() {
    this.proxy.searchFidelity.showPopup({}, true).then((data) => {
      if (data) {
        this.edit.model.fidelityCard = { id: data.fc_id, no: data.fc_no, name: data.p_fullName, discount: data.t_code };
      }
    });
  }

  fractionCustomValidation = (options: any) => {
    if (options.data.fQuantity > options.data.qtyPerPackage) {
      options.rule.message = 'Fracția nu poate fi mai mare ca numărul de comprimate / FA (' + options.data.qtyPerPackage + ').';
      return false;
    }
    if (options.value < 0) {
      options.rule.message = 'Fracția nu poate fi negativă.';
      return false;
    }
    return true;
  }

  checkTotalQuantityValidation = (options: any) => {
    if (!options.data.fQuantity && !options.data.quantity) {
      options.rule.message = 'Cantitatea sau fracția nu poate fi nulă.';
      return false;
    }
    return true;
  }


  quantityCustomValidation = (options: any) => {
    const min = options.data.qtyPerPackage === 1 ? 1 : 0;

    if (options.value < min || (!options.value && min === 1)) {
      options.rule.message = 'Cantitatea nu poate fi mai mică de ' + min + '.';
      return false;
    }

    return true;
  }

  stockQuantityValidation = (options: any) => {
    const selectedQuantity = this.edit.calculateQuantity(options.data.quantity, options.data.fQuantity, options.data.qtyPerPackage);
    if (options.data.quantityStock < selectedQuantity) {
      options.rule.message = 'Cantitate stoc depășită, cantitate maximă permisă: ' + options.data.quantityStock;
      return false;
    }
    return true;
  }

  onEditorPreparing(ev: any) {
    if (ev.parentType === 'dataRow' && ev.dataField === 'fQuantity') {
      if (ev.row.data.qtyPerPackage === 1) {
        ev.editorOptions.readOnly = true;
      }
    }
  }

  onSaveCell(e: any) {
    let quantity;
    if (e.newData) {
      if (e.newData.quantity === null) { e.newData.quantity = 0; }
      if (e.newData.fQuantity === null) { e.newData.fQuantity = 0; }
      if (e.newData.discount === null) { e.newData.discount = 0; }

      const item = this.edit.dsReceiptItems.find(x => x.productId === e.oldData.productId && x.orderIndex === e.oldData.orderIndex);
      quantity = this.edit.calculateQuantity(e.oldData.quantity, e.oldData.fQuantity, e.oldData.qtyPerPackage);
      this.edit.totalReceipt -= this.ds.roundTo(e.oldData.pricePerPackage * quantity, 2);
      this.edit.totalDiscount -= e.oldData.discount;
      this.edit.totalReceiptWithDiscount = this.ds.roundTo(this.edit.totalReceipt - this.edit.totalDiscount, 2);
      if (e.newData.discount || e.newData.quantity || e.newData.fQuantity) {
        if (e.newData.quantity) {
          const fquant = e.newData.fQuantity === 0 ? e.newData.fQuantity : item.fQuantity;
          quantity = this.edit.calculateQuantity(e.newData.quantity, fquant, item.qtyPerPackage);
          this.edit.calculateTotalAreaValues(quantity, item.pricePerPackage, item.discount);
        }
        if (e.newData.discount) {
          quantity = this.edit.calculateQuantity(item.quantity, item.fQuantity, item.qtyPerPackage);
          this.edit.calculateTotalAreaValues(quantity, item.pricePerPackage, e.newData.discount);
        }
        if (e.newData.fQuantity) {
          const qant = e.newData.quantity === 0 ? e.newData.quantity : item.quantity;
          quantity = this.edit.calculateQuantity(qant, e.newData.fQuantity, item.qtyPerPackage);
          if (e.newData.fQuantity >= item.qtyPerPackage) {
            e.newData.quantity = Math.floor(quantity);
            e.newData.fQuantity = e.newData.fQuantity - (Math.floor(e.newData.fQuantity / item.qtyPerPackage) * item.qtyPerPackage);
          }
          this.edit.calculateTotalAreaValues(quantity, item.pricePerPackage, item.discount);
        }
      } else if (e.newData.discount === 0 || e.newData.discount === null) {

        quantity = this.edit.calculateQuantity(item.quantity, item.fQuantity, item.qtyPerPackage);
        this.edit.calculateTotalAreaValues(quantity, item.pricePerPackage, (!e.newData.discount ? 0 : e.newData.discount));
      } else if (e.newData.fQuantity === 0 || e.newData.fQuantity === null) {
        quantity = this.edit.calculateQuantity(item.quantity, e.newData.fQuantity, item.qtyPerPackage);
        this.edit.calculateTotalAreaValues(quantity, item.pricePerPackage, item.discount);
      } else if (e.newData.quantity === 0 || e.newData.quantity === null) {
        quantity = this.edit.calculateQuantity(e.newData.quantity, item.fQuantity, item.qtyPerPackage);
        this.edit.calculateTotalAreaValues(quantity, item.pricePerPackage, item.discount);
      }
    }
  }

  onRowRemoved(e: any) {
    if (e.data) {
      if (e.data.id) {
        const obj: any = {};
        Object.assign(obj, e.data);
        obj.invoiceId = this.edit.model.id;
        EntityStore.execute('RemoveInvoiceItem',obj);
      }
      if (this.edit.dsReceiptItems.length === 0) {
        this.edit.initTotal();
        this.edit.model.discount = 0;
        this.edit.model.percentDiscount = 0;
      } else {
        const quantity = this.edit.calculateQuantity(e.data.quantity, e.data.fQuantity, e.data.qtyPerPackage);
        this.edit.totalReceipt -= this.ds.roundTo(e.data.pricePerPackage * quantity, 2);
        this.edit.totalDiscount -= e.data.discount;
        this.edit.totalReceiptWithDiscount = this.ds.roundTo(this.edit.totalReceipt - this.edit.totalDiscount, 2);
        this.edit.calculateFormDiscount(this.edit.discountType);
        const ds = this.edit.dsReceiptItems;
        for (let i = 0; i < ds.length; i++) {
          ds[i].orderIndex = i + 1;
        }
      }
    }
  }

  onNumericDiscountChangeEv(ev: any) {
    if (ev.event) {
      this.edit.discountType = 1;
      const prevValue = ev.previousValue !== null ? ev.previousValue : 0;
      this.edit.calculateDiscounts(this.edit.discountType, 0, prevValue);
      this.gridProduct.instance.refresh();
    }
  }

  onPercentDiscountChangeEv(ev: any) {
    if (ev.event) {
      this.edit.discountType = 2;
      const prevValue = this.edit.model.discount !== null ? this.edit.model.discount : 0;
      this.edit.calculateDiscounts(this.edit.discountType, 0, prevValue);
      this.gridProduct.instance.refresh();
    }
  }

  calcTotalDiscountEv(row) {
    const fq = row.fQuantity / (!row.qtyPerPackage ? 1 : row.qtyPerPackage);
    const discount = this.ds.roundTo(row.discount, 2);
    const res = (((row.quantity + fq) * row.pricePerPackage) - discount);
    return row ? this.ds.roundTo(res, 2) : null;
  }

  customizeSummaryEv(e: any) {
    return this.edit.totalReceiptWithDiscount.toFixed(2) + ' lei';
  }

  generateInvoice(ev: any) {
    if (ev.validationGroup) {
      const validators = ev.validationGroup.validators.filter((x: any) => x._isHidden === false);
      const validation = ev.validationGroup;
      validation.validators = validators;
      if (!validation.validate().isValid) {
        notify('Trebuie să completați toate câmpurile obligatorii.', 'error', 3000);
        return;
      }
    }

    this.edit.hidePopup();
    this.proxy.editInvoice.createDefault();
    const receiptObj: any = {};
    Object.assign(receiptObj, this.edit.model);
    receiptObj.items = this.edit.dsReceiptItems;
    if (!this.edit.model.id) {
      this.ds.getTodayReceiptCount().then((count: number) => {
        receiptObj.refNumber = count + 1;
        EntityStore.execute('CreateOutgoing', receiptObj);
      });
    }

    this.proxy.editInvoice.discountType = this.edit.discountType;
    this.proxy.editInvoice.disableGlobalDiscount = true;
    const obj = {
      discount: this.edit.model.discount,
      percentDiscount: this.edit.model.percentDiscount,
      date: new Date(),
      typeId: -2,
      dueDays: 30,
      dueDate: this.ds.addDaysToDate(new Date(), 30),
      facilityId: this.ds.currentFacility.id,
      fromReceipt: true,
    };

    this.proxy.editInvoice.showPopup(obj).then(() => {
      if (this.edit.mainReceiptGridInstance) {
        this.edit.mainReceiptGridInstance.refresh();
      }
    });
    this.edit.dsReceiptItems.forEach(element => {
      this.proxy.editInvoice.addProduct(element, false);
    });

  }

  printReceipt(ev: any) {
    if (ev.validationGroup) {
      const validators = ev.validationGroup.validators.filter((x: any) => x._isHidden === false);
      const validation = ev.validationGroup;
      validation.validators = validators;
      if (!validation.validate().isValid) {
        notify('Trebuie să completați toate câmpurile obligatorii.', 'error', 3000);
        return;
      }
    }
    this.receiptData = {};
    Object.assign(this.receiptData, this.edit.model);
    this.receiptData.items = this.edit.dsReceiptItems;
    this.edit.closePopup();
    this.isPrintReceiptVisible = true;
  }


  toolbarPreparing(e: any) {
    this.ui.prepareToolbar(e, true);
    e.toolbarOptions.items.unshift({
      widget: 'dxButton',
      location: 'after',
      options: {
        icon: 'plus',
        text: 'Adaugă articol',
        visible: this.edit.model.statusId !== 1 && !this.edit.model.fromPrescription,
        onClick: () => {
          if (this.proxy.globalSearch.gridInstance) {
            this.proxy.globalSearch.initGlobalSearch();
          }
          this.proxy.globalSearch.showPopup({ type: 'stock' }, true).then((data) => {
            if (data) {
              this.proxy.editReceipt.addProduct(data);
              this.gridProduct.instance.refresh();
            }
          });
        },
        onInitialized: (ev: any) => { this.addBtInst = ev.component; }
      }
    });
  }
}

