import { Injectable, ViewChild } from '@angular/core';
import { EntityQuery, EntityStore } from '@dohu/ibis-entity';
import notify from 'devextreme/ui/notify';
import { Subject } from 'rxjs/internal/Subject';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services';

@Injectable()
export class EditReceiptService extends iBisEditService {
    dsReceiptItems: any = [];
    wizardItems: any = [];

    totalReceipt: number;
    totalReceiptWithDiscount: number;
    totalDiscount: number;
    discountType: number;

    disableGlobalDiscount: boolean;
    isWizardVisible: boolean;
    // isReceiptPrinted: boolean;

    prescriptionId: string;

    mainReceiptGridInstance: any;

    isReady: Subject<any> = new Subject<any>();

    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'receiptValidation';
        this.isWizardVisible = false;
        // this.isReceiptPrinted = false;
    }

    closePopup() {
        this.popup.hide();
    }

    createDefault() {
        this.initTotal();
        this.dsReceiptItems = [];
        this.discountType = 0;
        this.disableGlobalDiscount = false;
        // this.isReceiptPrinted = false;
        return { docDate: new Date(), statusId: 1 };
    }

    initTotal() {
        this.totalReceipt = 0;
        this.totalDiscount = 0;
        this.totalReceiptWithDiscount = 0;
    }

    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (!data.i_id) { reject(); }
            EntityStore.fromQuery(new EntityQuery('Invoice').eq('id', data.i_id)).single().then((obj) => {
                if (!obj) {
                    reject();
                } else {
                    this.initTotal();
                    // init receipt items;
                    this.dsReceiptItems = [];
                    EntityStore.fromQuery(new EntityQuery('InvoiceItemView').eq('ii_invoiceId', data.i_id)).load().then((res: any) => {
                        if (res && res.data && res.data.length > 0) {
                            const arr = res.data;
                            for (let i = 0; i < arr.length; i++) {
                                this.dsReceiptItems.push({
                                    ModelName: 'ReceiptItems',
                                    id: arr[i].ii_id,
                                    orderIndex: arr[i].ii_orderIndex,
                                    inventoryId: arr[i].iv_inventoryId,
                                    unitCost: arr[i].ii_unitCost,
                                    productId: arr[i].p_id,
                                    name: arr[i].p_name,
                                    quantity: arr[i].ii_quantity,
                                    fQuantity: arr[i].ii_fQuantity || 0,
                                    discount: arr[i].ii_discount || 0,
                                    pricePerPackage: arr[i].ii_pricePerPackage || 0,
                                    qtyPerPackage: arr[i].p_qtyPerPackage,
                                    prescriptionItemId: arr[i].prescriptionItemId || null
                                });
                                const quantity = this.calculateQuantity(arr[i].ii_quantity, arr[i].ii_fQuantity, arr[i].p_qtyPerPackage);
                                this.totalReceipt += this.roundTo(quantity * arr[i].ii_pricePerPackage, 2);
                                this.totalDiscount += arr[i].ii_discount;
                            }
                            this.totalDiscount += obj.discount;
                            this.totalReceiptWithDiscount = this.roundTo(this.totalReceipt - this.totalDiscount, 2);
                            this.calculateFormDiscount(1);
                        }
                    });

                    // fidelity card area
                    if (obj.fidelityCardId) {
                        this.getFidelityCardById(obj.fidelityCardId).then((result) => {
                            obj.fidelityCard = { no: result.fc_no, name: result.p_fullName, id: result.fc_id, discount: result.t_code };
                        });
                    }
                    obj.fromPrescription = false;
                    resolve(obj);
                }
            });
        });
    }

    getFidelityCardById(id: string) {
        const q = new EntityQuery('FidelityCardPersonView').eq('fc_id', id);
        return EntityStore.fromQuery(q).single();
    }

    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('Invoice')).remove(id);
    }

    reset(): void {
        this.model.id = null;
    }

    addProduct(product: any) {
        if (product) {
            let totalQ;
            if (product.pq) {
                totalQ = product.pq / product.p_qtyPerPackage;
            }
            const obj = {
                // id: product.i_id,
                inventoryId: product.i_id,
                orderIndex: this.dsReceiptItems.length + 1,
                productId: product.i_productId,
                prescriptionItemId: product.id || null,
                name: product.p_name || product.name,
                quantity: product.i_quantity === 0 ? 0 : 1,
                fQuantity: product.i_quantity === 0 ? 1 : Math.round((totalQ % 1) * product.p_qtyPerPackage) || 0,
                discount: 0,
                pricePerPackage: product.i_pricePerPackage || 0,
                unitCost: product.i_unitCost || 0,
                qtyPerPackage: product.p_qtyPerPackage,
                // tslint:disable-next-line: max-line-length
                quantityStock: product.quantityStock || this.calculateQuantity(product.i_quantity, product.i_fQuantity, product.p_qtyPerPackage)
            };

            let ep: any;
            if (this.dsReceiptItems.length !== 0) {
                ep = this.dsReceiptItems.find((x: any) => x.inventoryId === obj.inventoryId);
            }

            if (ep) {
                const itemTotalQ = this.calculateQuantity(ep.quantity + obj.quantity, obj.fQuantity, obj.qtyPerPackage);
                if (itemTotalQ > ep.quantityStock) {
                    notify('Stoc depășit!', 'error', 3000);
                    return;
                }
                this.dsReceiptItems[ep.orderIndex - 1].quantity += obj.quantity;
                this.dsReceiptItems[ep.orderIndex - 1].fQuantity += obj.fQuantity;
            } else {
                this.dsReceiptItems.push(obj);
            }

            if (this.calculateQuantity(obj.quantity, obj.fQuantity, obj.qtyPerPackage) > obj.quantityStock) {
                obj.quantity = 1;
                obj.fQuantity = 0;
            }

            this.calculateTotalAreaValues(this.calculateQuantity(obj.quantity, obj.fQuantity, obj.qtyPerPackage), obj.pricePerPackage, 0);
        }
    }

    calculateTotalAreaValues(quantity: number, price: number, rowDiscount: number) {
        this.totalReceipt += this.roundTo(quantity * price, 2);
        const prevValue = this.model.discount !== null ? this.model.discount : 0;
        this.calculateDiscounts(this.discountType, rowDiscount, prevValue);
    }

    onSaveEv() {
        const obj: any = {};
        Object.assign(obj, this.model);
        obj.items = this.dsReceiptItems;
        if (!this.model.id) {
            this.getTodayReceiptCount().then(count => {
                obj.refNumber = count + 1;
                return EntityStore.execute('CreateOutgoing', obj);
            });
        } else {
            return EntityStore.execute('UpdateInvoice', obj);
        }
        return new Promise<any>((resolve, reject) => { resolve('OK'); });
    }

    getTodayReceiptCount() {
        const currentFacility = JSON.parse(sessionStorage.getItem('currentFacility'));
        const q = new EntityQuery('InvoicePrescriptionView')
            .eq('i_facilityId', currentFacility.id).eq('i_typeId', -3).addOrderByDesc(['i.docDate']);
        q.eq('i_docDate', EntityStore.toDateTimeFilter(this.clearDateTime(new Date())));
        return EntityStore.fromQuery(q).totalCount();
    }

    clearDateTime(date: any) {
        const d = new Date(date);
        d.setHours(0, 0, 0, 0);
        return d;
    }

    calculateDiscounts(type: number = 0, rowDiscount: number = 0, previousDiscount?) {
        this.totalDiscount -= previousDiscount ? previousDiscount : 0;
        this.calculateFormDiscount(type);
        this.totalDiscount += rowDiscount + (!this.model.discount ? 0 : this.model.discount);
        this.totalReceiptWithDiscount = this.roundTo(this.totalReceipt - this.totalDiscount, 2);
    }

    calculateFormDiscount(type: number) {
        if (this.totalReceipt && type === 2) {
            this.model.discount = this.roundTo((this.totalReceipt * this.model.percentDiscount) / 100, 2);
        }
        if (this.totalReceipt && type === 1) {
            this.model.percentDiscount = this.roundTo((this.model.discount / this.totalReceipt) * 100, 2);
        }
    }

    calculateQuantity(quantity: number, fQuantity: number, qtyPerPackage: number) {
        const fc = (!fQuantity ? 0 : fQuantity) /
            (!qtyPerPackage ? 1 : qtyPerPackage);
        return quantity + fc;
    }

    roundTo(n, digits) {
        let negative = false;
        if (digits === undefined) {
            digits = 0;
        }
        if (n < 0) {
            negative = true;
            n = n * -1;
        }
        const multiplicator = Math.pow(10, digits);
        n = parseFloat((n * multiplicator).toFixed(11));
        n = parseFloat((Math.round(n) / multiplicator).toFixed(2));
        if (negative) {
            n = (n * -1).toFixed(2);
            n = parseFloat(n);
        }
        return n;
    }

}
