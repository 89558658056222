import { Injectable } from '@angular/core';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { DataService } from '../../../services/data.service';
import { iBisEditService } from '@dohu/ibis-common';

@Injectable({
    providedIn: 'root'
})
export class EditPhysicalInventoryService extends iBisEditService {
    dsInventoryItem: any = [];

    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'inventoryValidation';
    }

    createDefault() {
        this.dsInventoryItem = [];
        const result: any = { inventoryDate: new Date(), statusId: 0 };
        if (this.ds) {
            result.facilityId = this.ds.currentFacility.id;
            result.facilityName = this.ds.currentFacility.text;
        }
        return result;
    }

    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            EntityStore.fromQuery(new EntityQuery('PhysicalInventory').eq('id', data.id)).single().then((obj) => {
                if (!obj) {
                    reject();
                } else {
                    this.populateInventoryItem(data.id);
                    obj.facilityId = this.ds.currentFacility.id;
                    obj.facilityName = this.ds.currentFacility.text;
                    resolve(obj);
                }
            });
        });
    }

    populateInventoryItem(physicalInvId: string) {
        this.dsInventoryItem = [];
        this.getInventoryItems(physicalInvId).then((result: any) => {
            if (result && result.length) {
                result.forEach(element => {
                    const item = {
                        id: element.f9,
                        orderIndex: this.dsInventoryItem.length + 1,
                        productId: element.f0,
                        pName: element.f1,
                        pCode: element.f2,
                        unitCost: element.f3,
                        actualQuantity: element.f4,
                        actualFQuantity: element.f5,
                        quantity: element.f6,
                        fQuantity: element.f7,
                        comments: element.f8,
                        inventoryId: element.f10
                    };
                    this.dsInventoryItem.push(item);
                });
                this.model.items = this.dsInventoryItem;
            }
        });
    }

    getInventoryItems(id: string) {

        const q = new EntityQuery('PhysicalInventoryItemView').eq('pii.physicalInventoryId', id);
        q.fields.push('pii.productId', 'p.name', 'p.code', 'pii.unitCost', 'pii.actualQuantity', 'pii.actualFQuantity', 'pii.quantity', 'pii.fQuantity', 'pii.comments', 'pii.id', 'i.id');
        q.distinct = true;
        return EntityStore.fromQuery(q).query();
    }

    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('PhysicalInventoryItem')).remove(id);
    }
    onSaveEv(): Promise<any> {
        const pi = EntityStore.fromQuery(new EntityQuery('PhysicalInventory'));
        const op = this.model.id ? pi.update(this.model.id, this.model) : pi.insert(this.model);
        return new Promise<any>((resolve, reject) => {
            this.dsInventoryItem.forEach(item => {
                op.then((res) => {
                    item.physicalInventoryId = res.id || this.model.id;
                    const pii = EntityStore.fromQuery(new EntityQuery('PhysicalInventoryItem'));
                    const opi = item.id ? pii.update(item.id, item) : pii.insert(item);
                    opi.then((resItem) => {
                        item.id = resItem.id || resItem.key;
                    });
                    resolve(res);
                });
            });
        });
    }

    reset(): void {
        this.model = this.createDefault();
    }
}
