import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import CustomStore from 'devextreme/data/custom_store';
import { DxDataGridComponent, DxMultiViewComponent } from 'devextreme-angular';
import { UIService, DataService } from 'src/app/services';
import { ProxyService } from 'src/app/services/proxy.service';


@Component({
    selector: 'app-product-wizard',
    templateUrl: 'product-wizard.component.html'
})
export class ProductWizardComponent {

    @Input() isWizardVisible: boolean;
    @Input() prescriptionItems: any;
    @Output() closePopup = new EventEmitter<any>();

    dsStockItems: CustomStore;

    wizardState: number;

    skipToNext: any;
    goToNext: any;
    closeButton: any;

    sufficientStock: any;

    selectedProduct: any = {};
    selectedProductKey: any = [];

    @ViewChild('gridProduct', { static: false }) gridProduct: DxDataGridComponent;
    @ViewChild('multiView', { static: false }) multiView: DxMultiViewComponent;

    constructor(public ui: UIService, public ds: DataService, public proxy: ProxyService) {
        this.goToNext = this.goToNextEv.bind(this);
        this.skipToNext = this.skipToNextEv.bind(this);
        this.sufficientStock = this.sufficientStockEv.bind(this);
        this.closeButton = this.closeWizard.bind(this);
        this.wizardState = 0;
    }

    onGridInit(ev: any, as: string) {
        const getActiveSubstance = as.split('/')[0];
        this.dsStockItems = this.ds.getInventoryByName(getActiveSubstance.toUpperCase().trim());
    }

    closeWizard() {
        this.isWizardVisible = false;
        this.wizardState = 0;
        this.closePopup.emit(this.isWizardVisible);
    }

    calculateTotalStock(row: any) {
        const fq = row.i_fQuantity / (!row.p_qtyPerPackage ? 1 : row.p_qtyPerPackage);
        return row.i_quantity + fq;
    }

    sufficientStockEv(row: any) {
        const current = this.multiView.instance.option('selectedIndex');
        const stock = row.i_fQuantity / (!row.p_qtyPerPackage ? 1 : row.p_qtyPerPackage) + row.i_quantity;
        const requestedQuantity = this.prescriptionItems[current].quantity / row.p_qtyPerPackage;
        if (requestedQuantity <= stock) {
            return true;
        }
        return false;
    }


    onSelectionChanged(ev: any) {
        if (ev.selectedRowsData.length) {
            this.selectedProduct = ev.selectedRowsData[0];
        }
    }

    checkArrayLength() {
        return 100 / this.prescriptionItems.length;
    }


    goToNextEv(ev: any) {
        ev.event.preventDefault();
        const selectedItem = this.gridProduct.instance.getSelectedRowsData()[0];
        if (selectedItem) {
            selectedItem.pq = this.prescriptionItems[this.wizardState].quantity;
            this.proxy.editReceipt.addProduct(selectedItem);
            this.changeSlide();
        }
        this.selectedProduct = {};
        this.gridProduct.instance.clearSelection();
    }

    skipToNextEv(ev: any) {
        ev.event.preventDefault();
        this.selectedProduct = {};
        this.changeSlide();
        this.gridProduct.instance.clearSelection();
    }

    changeSlide() {
        let current = this.multiView.instance.option('selectedIndex');
        current = current + 1;
        this.wizardState = current;
        this.multiView.instance.option('selectedIndex', current);

        if (current === this.prescriptionItems.length) {
            const selectedItem = this.gridProduct.instance.getSelectedRowsData()[0];
            if (selectedItem) {
                selectedItem.pq = this.prescriptionItems[current - 1].quantity;
                // selectedItem.refPrice = this.prescriptionItems[current - 1].refPrice;
                this.proxy.editReceipt.addProduct(selectedItem);
            }
            this.closeWizard();
            const obj: any = {};
            obj.docDate = new Date();
            obj.typeId = -3;
            obj.discount = 0;
            obj.percentDiscount = 0;
            obj.fidelityCard = { id: '', no: '', name: '' };
            obj.facilityId = this.ds.currentFacility.id;
            obj.prescriptionId = this.proxy.editReceipt.prescriptionId;
            obj.fromPrescription = true;
            this.ds.getTodayReceiptCount().then((count: number) => {
                obj.refNumber = count + 1;
                this.proxy.editReceipt.showPopup(obj).then(() => {
                    this.multiView.instance.option('selectedIndex', 0);
                    this.proxy.editReceipt.mainReceiptGridInstance.refresh();
                    // this.ds.redirectTo('/sales/receipt');
                }, err => {
                    console.log(err);
                });
            });
        }
    }

}
