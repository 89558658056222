import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
    breadObj: any[];
    constructor(private router: Router, private ds: DataService) {
        this.breadObj = [];
        this.setBreadcrumb(this.router.url, ds.getMenuItems(true));
        this.router.events.forEach((event) => {
            if (event instanceof (NavigationStart)) {
                this.breadObj = [];
                if (event.url) {
                    if (event.url !== '/login' && event.url !== '/') {
                        this.ds.previousUrl = event.url;
                    }
                    if (event.url === '/profile') {
                        this.breadObj = [{ icon: 'user', text: 'Profil' }];
                    }
                    if (event.url === '/company') {
                        this.breadObj = [{ icon: 'group', text: 'Date companii' }];
                    }
                    this.setBreadcrumb(event.url, ds.getMenuItems(true));
                }
            }
        });

        if (this.ds.auth && this.ds.auth.isAuth) {
            this.ds.authChanged.next(this.ds.auth);
        }
    }

    setBreadcrumb(url: string, menuItems: any[]) {
        const urlItems = url.split('/');
        const mainItem = menuItems.find(x => x.infoPath === ('/' + urlItems[1]));
        let secondItem: any;
        if (mainItem && mainItem.items) {
            secondItem = mainItem.items.find(x => x.path === url);
        }
        if (mainItem) {
            const ic = mainItem.icon.split('.svg');
            this.breadObj = [{ icon: ic[0] + '_tomato.svg', text: mainItem.text }];
        }
        if (secondItem) {
            this.breadObj.push({ text: secondItem.text });
        }
    }
}
