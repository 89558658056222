import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { DxMultiViewComponent } from 'devextreme-angular';
import { UIService, DataService } from 'src/app/services';
import { ProxyService } from 'src/app/services/proxy.service';
import CustomStore from 'devextreme/data/custom_store';

@Component({
    selector: 'app-receiving-wizard',
    templateUrl: 'receiving-wizard.component.html'
})
export class ReceivingWizardComponent {

    @Input() isWizardVisible: boolean;
    @Output() closePopup = new EventEmitter<any>();

    isLoadPanelVisible: boolean;
    isReceivingDisabled: boolean;

    wizardState: number;
    notFoundText: string;
    goToNext: any;
    closeReceivingWizard: any;
    multiViewItems: any;
    selectedSupplier: any;

    dsParty: CustomStore;

    @ViewChild('multiView', { static: false }) multiView: DxMultiViewComponent;

    constructor(public ui: UIService, public ds: DataService, public proxy: ProxyService) {
        this.goToNext = this.goToNextEv.bind(this);
        this.closeReceivingWizard = this.closeWizard.bind(this);
        this.dsParty = this.ds.getParty(1);
        this.multiViewItems = this.initMultiView();
        this.isLoadPanelVisible = false;
        this.wizardState = 0;
        this.notFoundText = '';
    }

    closeWizard() {
        this.isWizardVisible = false;
        this.wizardState = 0;
        this.closePopup.emit(this.isWizardVisible);
        this.isReceivingDisabled = false;
        this.notFoundText = '';
        this.selectedSupplier = null;
        this.multiView.instance.option('selectedIndex', 0);
    }

    goToNextEv(ev: any) {
        ev.event.preventDefault();
        this.changeSlide();
    }

    onShown() {
        setTimeout(() => {
            this.isLoadPanelVisible = false;
            this.isReceivingDisabled = true;
        }, 5000);
    }

    onHidden() {
        this.notFoundText = 'Nu au fost gasite facturi noi.';
    }

    changeSlide() {
        let current = this.multiView.instance.option('selectedIndex');
        current = current + 1;
        this.wizardState = current;
        this.multiView.instance.option('selectedIndex', current);
        this.isLoadPanelVisible = true;
    }

    initMultiView() {
        return [{
            title: 'Selectare furnizor'
        }, {
            title: 'Facturi noi',
        }, {
            title: 'Finalizare'
        }];
    }

}
