import { Injectable } from '@angular/core';
import { EntityQuery, EntityStore, SimpleFunctionType } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services';

@Injectable()
export class EditWithdrawalService extends iBisEditService {

    dsWithdrawalItems: any = [];
    selectedKeys: any = [];
    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'withdrawalValidation';
    }

    closePopup() {
        this.popup.hide();
    }

    createDefault() {
        return {};
    }

    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const q = new EntityQuery('InventoryLot').
                linkEq('productId', 'productId', 'Inventory', 'facilityId', this.ds.currentFacility.id);
                // q.distinct = true;
            const cso = new CustomStore(EntityStore.store(q, false, 'id'));
            resolve(cso);
        });
    }

    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('Inventory')).remove(id);
    }

    reset(): void {
        this.model.id = null;
    }

    onSaveEv() {
        const obj: any = {};
        Object.assign(obj, this.model);
        return new Promise<any>((resolve, reject) => { resolve('OK'); });
    }
}
