import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { iBisAuthService, iBisAuthModule } from '@dohu/ibis-auth';
import { DataService, UIService, ScreenService } from '../services';
import { AppComponent } from '../app.component';
import { ProfileComponent } from './profile/profile.component';
import { RegisterComponent } from './register/register.component';

import {
    DxFormModule, DxCheckBoxModule, DxButtonModule, DxTextBoxModule,
    DxValidationGroupModule, DxDataGridModule, DxScrollViewModule, DxPopupModule,
    DxChartModule, DxListModule, DxNumberBoxModule, DxSelectBoxModule, DxDateBoxModule, DxPopoverModule,
    DxValidatorModule, DxAccordionModule, DxTabPanelModule, DxMultiViewModule, DxLoadPanelModule, DxTagBoxModule
} from 'devextreme-angular';
import { ReceiptComponent } from './sales/receipt/receipt.component';
import { CashRegisterComponent } from './sales/cash-register/cash-register.component';
import { PromoComponent } from './sales/promo/promo.component';
import { SalesInvoiceComponent } from './sales/sales-invoice/sales-invoice.component';
import { StockComponent } from './stock/stock/stock.component';
import { TransferComponent } from './stock/transfer/transfer.component';
import { PriceChangeComponent } from './stock/price-change/price-change.component';
import { WithdrawalComponent } from './stock/withdrawal/withdrawal.component';
import { VarianceInComponent } from './stock/variance-in/variance-in.component';
import { VarianceOutComponent } from './stock/variance-out/variance-out.component';
import { PhysicalInventoryComponent } from './stock/physical-inventory/physical-inventory.component';
import { OrderComponent } from './supply/order/order.component';
import { SupplyInvoiceComponent } from './supply/supply-invoice/supply-invoice.component';
import { ReceivingComponent } from './supply/receiving/receiving.component';
import { ActiveSubstanceComponent } from './nomenclature/active-substance/active-substance.component';
import { DrugsComponent } from './nomenclature/drugs/drugs.component';
import { FidelityCardComponent } from './admin/fidelity-card/fidelity-card.component';
import { EnumGeneralComponent } from './admin/enumGeneral/enumGeneral.component';
import { CompanyComponent } from './admin/company/company.component';
import { EditOrderComponent } from './supply/order/edit-order/order-edit.component';
import { PromoEditComponent } from './sales/promo/edit-promo/promo-edit.component';
import { PromoItemComponent } from './sales/promo/edit-item/promo-item.component';
import { EditFidelityComponent } from './admin/fidelity-card/edit-fidelity/edit-fidelity.component';
import { FacilityComponent } from './admin/facility/facility.component';
import { EditReceiptComponent } from './sales/receipt/edit-receipt/edit-receipt.component';
import { GlobalSearchComponent } from '../shared/global-search/global-search.component';
import { PersonComponent } from './admin/person/person.component';
import { EditCompanyComponent } from './admin/company/edit-company/edit-company.component';
import { EditPersonComponent } from './admin/person/edit-person/edit-person.component';
import { EditTransferComponent } from './stock/transfer/edit-transfer/edit-transfer.component';
import { EditReceivingItemComponent } from './supply/receiving/edit-receiving-item/edit-receiving-item.component';
import { EditFacilityComponent } from './admin/facility/edit-facility/edit-facility.component';
import { EditEntityRoleComponent } from './admin/user/edit-entity-role/edit-entity-role.component';
import { EditInvoiceComponent } from './sales/sales-invoice/edit-invoice/edit-invoice.component';
import { MedicComponent } from './nomenclature/medic/medic.component';
import { SearchFidelityCardComponent } from './admin/fidelity-card/search-fidelity-card/search-fidelity-card.component';
import { EditFidelityPersonComponent } from '../shared/edit-fidelity-person/edit-fidelity-person.component';
import { EditPrescriptionComponent } from './sales/receipt/edit-receipt/edit-prescription.component';
import { UserComponent } from './admin/user/user.component';
import { InviteUserModule } from '../shared/invite-user/invite-user.component';
import { ProductWizardComponent } from './sales/receipt/product-wizard/product-wizard.component';
import { ReceivingWizardComponent } from './supply/receiving/receiving-wizard/receiving-wizard.component';
import { PrintReceiptComponent } from './sales/receipt/edit-receipt/print-receipt/print-receipt.component';
import { ReturnProductComponent } from './supply/return/return-product.component';
import { EditWithdrawalComponent } from './stock/withdrawal/edit-withdrawal/edit-withdrawal.component';
import { EditInventoryComponent } from './stock/physical-inventory/edit-physical-inventory.component';
import { EditPriceChangeComponent } from './stock/price-change/edit-price-change.component';
import { iBisLanguageService } from '@dohu/ibis-common';
import { InventoryHistoryComponent } from './stock/inventory-history/inventory-history.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { InitAppComponent } from '../wizard/init-app.component';
import { UnprocessedComponent } from './nomenclature/unprocessed/unprocessed.component';
import { EditProductComponent } from '../shared/edit-product/edit-product.component';
import { ParapharmaceuticalComponent } from './nomenclature/parapharmaceutical/parapharmaceutical.component';
import { CashRegisterKitComponent } from './admin/cash-register-kit/cash-register-kit.component';
import { EditCashRegisterKitComponent } from './admin/cash-register-kit/edit-kit/edit-kit.component';
import { ProductDescriptionComponent } from '../shared/product-description/product-description.component';
import { ReservationComponent } from './sales/reservation/reservation.component';
import { ExpiredProductComponent } from './report/expired-product/expired-product.component';


@NgModule({
    declarations: [
        ProfileComponent,
        RegisterComponent,
        ReceiptComponent,
        CashRegisterComponent,
        PromoComponent,
        SalesInvoiceComponent,
        StockComponent,
        TransferComponent,
        PriceChangeComponent,
        WithdrawalComponent,
        VarianceInComponent,
        VarianceOutComponent,
        PhysicalInventoryComponent,
        EditInventoryComponent,
        OrderComponent,
        SupplyInvoiceComponent,
        ReceivingComponent,
        ActiveSubstanceComponent,
        DrugsComponent,
        ParapharmaceuticalComponent,
        FidelityCardComponent,
        EnumGeneralComponent,
        CompanyComponent,
        EditInvoiceComponent,
        EditReceiptComponent,
        EditCompanyComponent,
        EditOrderComponent,
        PromoEditComponent,
        PromoItemComponent,
        EditFidelityComponent,
        FacilityComponent,
        GlobalSearchComponent,
        EditFidelityPersonComponent,
        InventoryHistoryComponent,
        PersonComponent,
        CashRegisterKitComponent,
        EditPersonComponent,
        EditTransferComponent,
        EditReceivingItemComponent,
        EditFacilityComponent,
        EditEntityRoleComponent,
        MedicComponent,
        SearchFidelityCardComponent,
        EditPrescriptionComponent,
        UserComponent,
        ProductWizardComponent,
        PrintReceiptComponent,
        ReceivingWizardComponent,
        ReturnProductComponent,
        EditWithdrawalComponent,
        EditPriceChangeComponent,
        CompanyDetailsComponent,
        InitAppComponent,
        UnprocessedComponent,
        EditProductComponent,
        EditCashRegisterKitComponent,
        ProductDescriptionComponent,
        ReservationComponent,
        ExpiredProductComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        DxCheckBoxModule,
        DxButtonModule,
        DxTextBoxModule,
        DxFormModule,
        DxValidationGroupModule,
        DxDataGridModule,
        DxScrollViewModule,
        DxPopupModule,
        DxChartModule,
        DxListModule,
        DxNumberBoxModule,
        DxSelectBoxModule,
        DxDateBoxModule,
        DxPopoverModule,
        DxValidatorModule,
        DxAccordionModule,
        DxTabPanelModule,
        DxMultiViewModule,
        DxLoadPanelModule,
        DxTagBoxModule,
        InviteUserModule,
        iBisAuthModule
    ],
    exports: [
        ProfileComponent,
        RegisterComponent,
        ReceiptComponent,
        CashRegisterComponent,
        EditFacilityComponent,
        EditReceiptComponent,
        EditInvoiceComponent,
        EditPrescriptionComponent,
        EditCompanyComponent,
        PromoComponent,
        SalesInvoiceComponent,
        StockComponent,
        InventoryHistoryComponent,
        TransferComponent,
        PriceChangeComponent,
        WithdrawalComponent,
        VarianceInComponent,
        VarianceOutComponent,
        PhysicalInventoryComponent,
        OrderComponent,
        SupplyInvoiceComponent,
        EditFidelityComponent,
        ReceivingComponent,
        FacilityComponent,
        GlobalSearchComponent,
        EditFidelityPersonComponent,
        PersonComponent,
        MedicComponent,
        EditPriceChangeComponent,
        InitAppComponent,
        UnprocessedComponent,
        EditProductComponent,
        ProductDescriptionComponent
    ],
    providers: [iBisAuthService, iBisLanguageService, DataService, UIService, ScreenService],
    bootstrap: [AppComponent]
})
export class ViewModule { }
