import { Component, OnInit } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import { EditProductService } from '../../../shared/edit-product/edit-product.service';

@Component({
    selector: 'app-parapharmaceutical',
    templateUrl: './parapharmaceutical.component.html',
    styleUrls: ['./parapharmaceutical.component.css']
})
export class ParapharmaceuticalComponent implements OnInit {
    dsParapharmaceutical: CustomStore;
    productData: any = {};
    isPrincipal = false;
    isProductDescriptionVisible = false;

    constructor(public ui: UIService, public ds: DataService, public edit: EditProductService) { }

    ngOnInit() {
        if (!this.ds.auth.isAuth) { return; }
        this.dsParapharmaceutical = this.ds.getParapharmaceuticals();
        this.ds.isPricipal().then((response: any) => {
            this.isPrincipal = response === 'YES';
        });
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);
    }


    editProduct = (ev: any) => {
        const obj = JSON.parse(JSON.stringify(ev.row.data));
        this.edit.showPopup(obj, true).then((res: any) => {
            if (res && res.isSaving) {
                ev.component.refresh();
            }

        });
    }

    openProductDescription = (ev: any) => {
        this.productData = ev.row.data;
        this.isProductDescriptionVisible = true;
    }
}
