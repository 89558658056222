import { Component, OnInit, Input, Output, EventEmitter, ViewChild, NgModule } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { DxFormComponent, DxTextBoxModule, DxTextAreaModule, DxFormModule, DxPopupModule, DxButtonModule } from 'devextreme-angular';
import { DataService } from 'src/app/services';
import { ProxyService } from 'src/app/services/proxy.service';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html'
})
export class InviteUserComponent implements OnInit {
  @Input() popupVisible: any;
  @Output() onClosePopup = new EventEmitter<any>();
  onSendInvite: any;
  inviteDetails: any;

  dsRoles: CustomStore;

  @ViewChild('formInvite', { static: false }) formInvite: DxFormComponent;
  constructor(public ds: DataService, public proxy: ProxyService) { }

  ngOnInit() {
    this.onSendInvite = this.onSendInviteEv.bind(this);
    this.dsRoles = this.ds.getUserLoginRole();
  }

  onSendInviteEv(e: any) {
    this.ds.isLoadPanelVisible = true;
    this.ds.inviteUser(this.inviteDetails).then(data => {
      this.ds.isLoadPanelVisible = false;
      if (data === 'OK') {
        notify('Invitatie trimisa cu succes.', 'success', 3000);
        this.inviteDetails = {};
        this.formInvite.instance.resetValues();
        this.onClosePopup.emit(true);
      } else {
        notify('Datele introduse nu au putut fi procesate', 'error', 3000);
      }
    }, err => {
      this.ds.isLoadPanelVisible = true;
      this.ds.serverError(err);
    });
  }

  onHiddenPopup(e: any) {
    this.inviteDetails = {};
    this.formInvite.instance.resetValues();
    this.onClosePopup.emit(true);
  }
}

@NgModule({
  imports: [DxPopupModule, DxFormModule, DxTextBoxModule, DxTextAreaModule, DxButtonModule],
  exports: [InviteUserComponent],
  declarations: [InviteUserComponent]
})
export class InviteUserModule { }
