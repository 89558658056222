import { Injectable } from '@angular/core';
import { EntityQuery, EntityStore } from '@dohu/ibis-entity';
import { DataService } from 'src/app/services';
import { iBisEditService } from '@dohu/ibis-common';


@Injectable({
    providedIn: 'root'
})
export class EditTransferService extends iBisEditService {

    dsTransferItem: any = [];
    validation: string;
    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'transferValidation';
    }


    createDefault() {
        this.dsTransferItem = [];
        const result: any = { sendDate: new Date() };
        if (this.ds) {
            result.fromFacilityId = this.ds.currentFacility.id;
            result.fromFacilityName = this.ds.currentFacility.text;
        }
        return result;
    }
    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (!data.id) { reject(); }
            EntityStore.fromQuery(new EntityQuery('InventoryTransferGroup').eq('id', data.id)).single().then((obj) => {
                if (!obj) {
                    reject();
                } else {
                    resolve(obj);
                }
            });
        });
    }

    onRemove(id: string): Promise<void> {
        // TODO: @Luca research on how to delete this
        return EntityStore.fromQuery(new EntityQuery('InventoryTransferGroup')).remove(id);
    }

    onSaveEv(): Promise<any> {
        for (let i = 0; i < this.dsTransferItem.length; i++) {
            this.dsTransferItem[i].orderIndex = i + 1;
        }
        this.model.items = this.dsTransferItem;
        return EntityStore.execute('AddTransfer', this.model);
    }

    reset(): void { }

    calculateQuantity(quantity: number, fQuantity: number, qtyPerPackage: number) {
        const fc = (!fQuantity ? 0 : fQuantity) /
            (!qtyPerPackage ? 1 : qtyPerPackage);
        return quantity + fc;
    }
}
