import { Component, OnInit } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import { iBisAuthService } from '@dohu/ibis-auth';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  user: any;
  data: any;
  colCountByScreen: Object;

  constructor(public ds: DataService, public auth: iBisAuthService, public ui: UIService) { }
  ngOnInit() {
    this.ds.getUserInfo(this.auth.user.id).then(data => {
      this.user = {
        nume: data.fullName,
        email: data.emailAddress,
        telefon: data.phone
      };
    });
    setTimeout(() => {
      const div = document.getElementById('container');
      div.style.height = this.ui.fullHeight;
    }, 0);
  }

  searchRomsoft(input: any) {
    this.ds.searchRomsoft(input.value).then((res: any) => {
      console.log(res);
    });
  }

  click(ev: any) {
    this.ds.updateProfile(this.user);
  }
}
