import { Component, OnInit } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';

@Component({
  selector: 'app-cash-register',
  templateUrl: './cash-register.component.html',
  styleUrls: ['./cash-register.component.css']
})
export class CashRegisterComponent implements OnInit {

  dsCashRegister: CustomStore;
  obj: {};

  constructor(public ds: DataService, public ui: UIService) { }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.dsCashRegister = this.ds.getPayment('Cash');

    // acest obiect este folosit pentru a face un insert pentru teste; apelul se face din toolbar
    this.obj = { typeId: 'c6e1434a-ece2-41f8-8a9a-30951af49e69', methodId: 'abf63340-dc49-494a-beaa-8c57bb611ba2',
                  partyFromId: '8a944a2a-5234-11e9-8647-d663bd873d93', partyToId: '8a944a2a-5234-11e9-8647-d663bd873d93',
                  effectiveDate: new Date(), refNumber: '123', amount: 1,
                  exchangeRate: 1, currencyId: '08afe913-5328-4a78-9b06-87eb4161ff34',
                  finAccId: '3e24eee7-23b1-44d9-b86d-8ce5285af422', comments: 'test'};
  }

  toolbarPreparing(e: any) {
    this.ui.prepareToolbar(e);

    e.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        hint: 'Insert in Payment',
        icon: 'plus',
        onClick: () => EntityStore.fromQuery(new EntityQuery('Payment')).insert(this.obj)
      },
      location: 'after'
    });
  }

}
