import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import { EditTransferService } from './edit-transfer/edit-transfer.service';
import { EntityStoreOptions } from '@dohu/ibis-entity';
import { ProxyService } from 'src/app/services/proxy.service';
import notify from 'devextreme/ui/notify';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html'
})
export class TransferComponent implements OnInit {

  dsInventoryTransferGroup: DataSource;
  dsFacility: CustomStore;
  dsInventoryTransferItems: CustomStore;
  transferId: string;

  constructor(public ds: DataService, public ui: UIService, public edit: EditTransferService, public proxy: ProxyService) { }

  ngOnInit() {
    this.initTransferGrid();
    this.dsFacility = this.ds.getFacility();
  }

  initTransferGrid() {
    this.ds.getInventoryTransferGroup(this.ds.currentFacility.id).then((res: any) => {
      res.data.forEach((element: any) => {
        if (element.toFacilityId === this.ds.currentFacility.id) {
          element.statusId = 3;
        } else {
          element.statusId = element.receiveDate === null ? 1 : 2;
        }
      });
      this.dsInventoryTransferGroup = new DataSource({
        store: res.data,
        paginate: true,
        pageSize: 30
      });
    });
  }

  positiveQuantity(ev: any) {
    return ev ? ev.vOut_quantity * (-1) : null;
  }

  positiveFquantity(ev: any) {
    return ev ? ev.vOut_fQuantity * (-1) : null;
  }

  toolbarPreparing(event: any) {
    this.ui.prepareToolbar(event, true, true);
    event.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        hint: 'Reîncarcă datele',
        icon: 'refresh',
        onClick: (e: any) => { this.initTransferGrid(); }
      },
      location: 'after'
    });
    event.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        hint: 'Adaugă',
        text: 'Adaugă transfer',
        icon: 'plus',
        onClick: (e: any) => {
          this.edit.showPopup(null).then(() => {
            this.initTransferGrid();
            this.edit.createDefault();
          });
        }
      },
      location: 'after'
    });
  }

  onDeleteTransferClick(row: any) {
    this.proxy.editTransfer.remove(row.data.id).then(() => {
      notify('Success', 'success', 2000);
      this.initTransferGrid();
    });
  }

  onTransferExpandEv(event: any) {
    event.component.collapseAll(-1);
    this.transferId = event.key.id;
    this.dsInventoryTransferItems = this.ds.getInventoryTransferView(this.transferId);
  }

  prepareDetailToolbar(event: any) {
    this.ui.prepareToolbar(event, true);
    event.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        hint: 'Descarcă aviz de transport',
        icon: 'doc',
        text: 'Descarcă aviz',
        onClick: (e: any) => this.onDownloadRaportClick()
      },
      location: 'after'
    });
  }

  onDownloadRaportClick() {
    this.ds.getFile(EntityStoreOptions.DefaultServer + 'DownloadTransferReport' + '/' + this.transferId);
  }
}
