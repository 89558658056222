import { Component, OnInit, Input, Output, EventEmitter, ViewChild, NgModule } from '@angular/core';
import { iBisAuthService } from '@dohu/ibis-auth';
import { environment } from 'src/environments/environment';
import { alert } from 'devextreme/ui/dialog';
import { DxFormComponent, DxPopupModule, DxFormModule, DxButtonModule, DxTextBoxModule } from 'devextreme-angular';
import { DataService } from 'src/app/services';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html'
})
export class AddCompanyComponent implements OnInit {

  @Input() popupVisible: any;
  @Output() onClosePopup = new EventEmitter<any>();

  @ViewChild('addCompanyForm', { static: false }) addCompanyForm: DxFormComponent;
  onSaveCompany: any;
  newCompanyData: any;
  constructor(private auth: iBisAuthService, public ds: DataService) { }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.onSaveCompany = this.onSaveCompanyEv.bind(this);
    this.newCompanyData = {};
  }

  onAddCompanyHidden(e: any) {
    this.newCompanyData = {};
    this.addCompanyForm.instance.resetValues();
    this.onClosePopup.emit(true);
  }

  onSaveCompanyEv(e: any) {
    this.ds.isLoadPanelVisible = true;
    this.auth.getFullUser().then(result => {
      const fields = ['_Companie_', '_CIF_', '_Telefon_'];
      const values = [this.newCompanyData.name, this.newCompanyData.cif, null];
      const obj = {
        registerType: 'EMIM',
        fullName: result.fullName,
        emailAddress: result.emailAddress,
        deployId: environment.deployId,
        fields: fields,
        values: values
      };
      this.auth.register(obj).then(() => {
        alert('Cererea a fost trimisă și urmează a fi aprobată. Îți mulțumim.', 'Alertă');
        this.newCompanyData = {};
        this.addCompanyForm.instance.resetValues();
        this.onClosePopup.emit(true);
        this.ds.isLoadPanelVisible = false;
      });
    }, err => {
      this.ds.serverError(err);
      this.ds.isLoadPanelVisible = false;
    });
  }
}


@NgModule({
  imports: [DxPopupModule, DxFormModule, DxTextBoxModule, DxButtonModule],
  exports: [AddCompanyComponent],
  declarations: [AddCompanyComponent]
})
export class AddCompanyModule { }
