import { Component, OnInit } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import { EditCashRegisterKitService } from './edit.kit.service';

@Component({
    selector: 'app-edit-kit',
    templateUrl: './edit-kit.component.html'
})
export class EditCashRegisterKitComponent implements OnInit {

    addManualOptions = {
        icon: 'doc',
        onClick: (e: any) => {
            this.createUploadEvent(true);
        },
        text: 'Adaugă manual',
        elementAttr: { class: 'custom-button' }
    };

    addKitOptions = {
        icon: 'file',
        onClick: (e: any) => {
            this.createUploadEvent(false);
        },
        text: 'Adaugă kit',
        elementAttr: { class: 'custom-button' }
    };

    constructor(public ds: DataService, public edit: EditCashRegisterKitService, public ui: UIService) { }

    ngOnInit() {
    }

    manualTagBoxInit(event: any) {
        this.edit.manualTagBoxInstance = event.component;
    }

    kitTagBoxInit(event: any) {
        this.edit.kitTagBoxInstance = event.component;
    }

    onFileDelete(event: any, isManual?: boolean) {
        if (event.previousValue && event.value) {
            if (event.previousValue.length > event.value.length) {
                isManual ? this.edit.model.manual = null : this.edit.model.kit = null;
            }
        }
    }


    createUploadEvent(isManual?: boolean) {
        const input: any = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', isManual ? '.pdf' : '.*');
        input.addEventListener('change', (e: any) => {
            input.files[0].id = null;
            if (isManual) {
                this.edit.model.manual = [];
                this.edit.model.manual.push(input.files[0]);
                this.edit.manualTagBoxInstance.option('value', this.edit.model.manual);
                this.edit.manualTagBoxInstance.repaint();
            } else {
                this.edit.model.kit = [];
                this.edit.model.kit.push(input.files[0]);
                this.edit.kitTagBoxInstance.option('value', this.edit.model.kit);
                this.edit.kitTagBoxInstance.repaint();
            }
        }, false);
        input.click();
    }
}
