import { Injectable } from '@angular/core';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from 'src/app/services';
import { iBisEditService } from '@dohu/ibis-common';

@Injectable()
export class SearchFidelityService extends iBisEditService {

    constructor(public ds: DataService) {
        super(ds.lg);
    }

    createDefault() {
        return {};
    }

    getById(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            resolve(this.ds.getFidelityCardPersonView(true));
        });
    }

    onRemove(id: string): Promise<void> {
        return new Promise((resolve) => { });
    }

    onSaveEv(): Promise<any> {
        return new Promise((resolve) => {
            this.model.isSaving = true;
            resolve(this.model);
        });
    }

    reset(): void {
        this.model = {};
    }
}