import { Component, OnInit } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import { PromoItemService } from './promo-item.service';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'app-promo-item',
  templateUrl: './promo-item.component.html'
})
export class PromoItemComponent implements OnInit {
  dsProduct: DataSource;
  dsCurrency: any;

  constructor(public ds: DataService, public ui: UIService, public edit: PromoItemService) { }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.dsProduct = new DataSource({
      store: this.ds.getInventory(),
      paginate: true,
      pageSize: 10
    });
    this.dsCurrency = this.ds.getEnumValue('CURRENCY');
  }

}
