import { Component, OnInit } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import { EditProductService } from 'src/app/shared/edit-product/edit-product.service';

@Component({
  selector: 'app-drugs',
  templateUrl: './drugs.component.html',
  styleUrls: ['./drugs.component.css']
})
export class DrugsComponent implements OnInit {
  dsProducts: CustomStore;
  isPrincipal = false;
  isProductDescriptionVisible = false;
  productData: any = {};

  constructor(public ui: UIService, public ds: DataService, public edit: EditProductService) { }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.ds.isPricipal().then((response: any) => {
      this.isPrincipal = response === 'YES';
    });
    this.dsProducts = this.ds.getProduct();
  }

  toolbarPreparing(event: any) {
    this.ui.prepareToolbar(event, true);
  }

  editProduct = (ev: any) => {
    const obj = JSON.parse(JSON.stringify(ev.row.data));
    this.edit.showPopup(obj, true).then((res: any) => {
      if (res && res.isSaving) {
        ev.component.refresh();
      }

    });
  }

  openProductDescription = (ev: any) => {
    this.productData = ev.row.data;
    this.isProductDescriptionVisible = true;
  }
}
