import { Injectable } from '@angular/core';
import { EntityQuery, EntityStore } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services';
import DataSource from 'devextreme/data/data_source';

@Injectable()
export class EditInvoiceService extends iBisEditService {
    dsInvoiceItems: any = [];
    dsParty: any;

    discountType: number;
    totalInvoice: number;
    totalInvoiceWithDiscount: number;
    totalDiscount: number;

    disableGlobalDiscount: boolean;

    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'salesInvoiceValidation';
        this.customValidation = 'customInvoiceValidation';
    }

    createDefault() {
        this.initTotal();
        this.disableGlobalDiscount = false;
        this.dsInvoiceItems = [];
        this.discountType = 0;
        return { date: new Date() };
    }

    initTotal() {
        this.totalInvoice = 0;
        this.totalInvoiceWithDiscount = 0;
        this.totalDiscount = 0;
    }

    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (!data.i_id) { reject(); }
            EntityStore.fromQuery(new EntityQuery('Invoice').eq('id', data.i_id)).single().then((obj) => {
                if (!obj) {
                    reject();
                } else {
                    this.initTotal();
                    this.initPartyDs();
                    // init invoice items;
                    this.dsInvoiceItems = [];
                    EntityStore.fromQuery(new EntityQuery('InvoiceItemView').eq('ii_invoiceId', data.i_id)).load().then((res: any) => {
                        if (res && res.data && res.data.length > 0) {
                            const arr = res.data;
                            for (let i = 0; i < arr.length; i++) {
                                this.dsInvoiceItems.push({
                                    ModelName: 'InvoiceItems',
                                    orderIndex: arr[i].ii_orderIndex,
                                    productId: arr[i].p_id,
                                    name: arr[i].p_name,
                                    quantity: arr[i].ii_quantity,
                                    fQuantity: arr[i].ii_fQuantity || 0,
                                    discount: arr[i].ii_discount || 0,
                                    pricePerPackage: arr[i].ii_pricePerPackage || 0,
                                    qtyPerPackage: arr[i].i_qtyPerPackage
                                });
                                const quantity = this.calculateQuantity(arr[i].ii_quantity, arr[i].ii_fQuantity, arr[i].p_qtyPerPackage);
                                this.totalInvoice += this.roundTo(quantity * arr[i].ii_pricePerPackage, 2);
                                this.totalDiscount += arr[i].ii_discount;
                            }
                            this.totalDiscount += obj.discount;
                            this.totalInvoiceWithDiscount = this.roundTo(this.totalInvoice - this.totalDiscount, 2);
                            this.calculateFormDiscount(1);

                            const diffTime = Math.abs(new Date(obj.dueDate).getTime() - new Date(obj.date).getTime());
                            obj.dueDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        }
                    });
                    resolve(obj);
                }
            });
        });
    }

    initPartyDs(partyType?: number) {
        this.dsParty = new DataSource({
            store: this.ds.getParty(partyType),
            paginate: true,
            pageSize: 10
        });
    }

    addProduct(product: any, view: boolean) {
        if (product) {
            const obj = {
                orderIndex: this.dsInvoiceItems.length + 1,
                id: product.i_id,
                inventoryId: product.i_id,
                productId: product.i_productId,
                name: product.p_name,
                quantity: product.i_quantity === 0 ? 0 : 1,
                fQuantity: product.i_quantity === 0 ? 1 : 0,
                discount: product.discount || 0,
                pricePerPackage: product.i_pricePerPackage || 0,
                unitCost: product.i_unitCost || 0,
                qtyPerPackage: product.p_qtyPerPackage,
                quantityStock: this.calculateQuantity(product.i_quantity, product.i_fQuantity, product.p_qtyPerPackage)
            };

            if (!view) {
                obj.productId = product.productId;
                obj.quantity = product.quantity;
                obj.fQuantity = product.fQuantity;
                obj.name = product.name;
                obj.pricePerPackage = product.pricePerPackage;
                obj.unitCost = product.unitCost || 0;
                obj.qtyPerPackage = product.qtyPerPackage;
                obj.quantityStock = this.calculateQuantity(product.quantity, product.fQuantity, product.qtyPerPackage);
            }

            let ep: any;
            if (this.dsInvoiceItems.length !== 0) {
                ep = this.dsInvoiceItems.find((x: any) => x.id && x.id === obj.id);
            }
            if (ep && ep.id && (ep.quantity + 1 > ep.quantityStock)) {
                notify('Stoc depășit!', 'error', 3000);
                return;
            }
            const quantity = this.calculateQuantity(obj.quantity, obj.fQuantity, obj.qtyPerPackage);
            ep ? this.dsInvoiceItems[ep.orderIndex - 1].quantity += obj.quantity : this.dsInvoiceItems.push(obj);
            this.calculateTotalAreaValues(quantity, obj.pricePerPackage, obj.discount);
        }
    }

    calculateQuantity(quantity: number, fQuantity: number, qtyPerPackage: number) {
        const fc = (!fQuantity ? 0 : fQuantity) /
            (!qtyPerPackage ? 1 : qtyPerPackage);
        return quantity + fc;
    }

    getInvoiceItems(invoiceId?: string): CustomStore {
        const cso = EntityStore.store(new EntityQuery('InvoiceItemView').eq('ii_invoiceId', invoiceId)
            .addOrderBy(['ii.orderIndex']), false, 'ii_id');
        return new CustomStore(cso);
    }

    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('Invoice')).remove(id);
    }

    closePopup() {
        this.popup.hide();
    }


    onSaveEv() {
        const obj: any = {};
        Object.assign(obj, this.model);
        obj.items = this.dsInvoiceItems;
        if (!this.model.fromReceipt) {
            return EntityStore.execute('CreateOutgoing', obj);
        } else {
            const s = EntityStore.fromQuery(new EntityQuery('Invoice')).insert(obj);
            s.then((res: any) => {
                this.dsInvoiceItems.forEach(element => {
                    element.invoiceId = res.id;
                    EntityStore.fromQuery(new EntityQuery('InvoiceItem')).insert(element);
                });
            });
            this.closePopup();
        }
    }

    reset(): void {
        this.model.id = null;
        this.model.quantity = 0;
    }

    calculateTotalAreaValues(quantity: number, price: number, rowDiscount: number) {
        this.totalInvoice += this.roundTo(quantity * price, 2);
        const prevValue = this.model.discount !== null ? this.model.discount : 0;
        this.calculateDiscounts(this.discountType, rowDiscount, prevValue);
    }

    calculateDiscounts(type: number = 0, rowDiscount: number = 0, previousDiscount?) {
        this.totalDiscount -= previousDiscount && this.totalDiscount !== 0 ? previousDiscount : 0;
        this.calculateFormDiscount(type);
        this.totalDiscount += rowDiscount + (!this.model.discount ? 0 : this.model.discount);
        this.totalInvoiceWithDiscount = this.roundTo(this.totalInvoice - this.totalDiscount, 2);
    }

    calculateFormDiscount(type: number) {
        if (this.totalInvoice && type === 2) {
            this.model.discount = this.roundTo((this.totalInvoice * this.model.percentDiscount) / 100, 2);
        }
        if (this.totalInvoice && type === 1) {
            this.model.percentDiscount = this.roundTo((this.model.discount / this.totalInvoice) * 100, 2);
        }
    }

    roundTo(n, digits) {
        let negative = false;
        if (digits === undefined) {
            digits = 0;
        }
        if (n < 0) {
            negative = true;
            n = n * -1;
        }
        const multiplicator = Math.pow(10, digits);
        n = parseFloat((n * multiplicator).toFixed(11));
        n = parseFloat((Math.round(n) / multiplicator).toFixed(2));
        if (negative) {
            n = (n * -1).toFixed(2);
            n = parseFloat(n);
        }
        return n;
    }
}
