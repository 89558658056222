import { Component, OnInit, ViewChild } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import { DxDataGridComponent } from 'devextreme-angular';
import { SearchFidelityService } from './search-fidelity-card.service';
import { EditFidelityService } from '../edit-fidelity/edit-fidelity.service';
import { EditFidelityPersonService } from 'src/app/shared/edit-fidelity-person/edit-fidelity-person.service';

@Component({
  selector: 'app-search-fidelity-card',
  templateUrl: './search-fidelity-card.component.html'
})
export class SearchFidelityCardComponent implements OnInit {
  dsFidelityCard: any;
  gridInstance: any;
  timeoutSearch: any;
  searchValue: string;
  addNewFidelityCard: any;
  plusButton: any;

  @ViewChild('gridSearchFidelity', { static: false }) gridSearchFidelity: DxDataGridComponent;
  constructor(public ui: UIService, public ds: DataService, public search: SearchFidelityService,
    public edit: EditFidelityPersonService) { }

  ngOnInit() {
    this.addNewFidelityCard = this.addNewFidelityCardEv.bind(this);
    this.plusButton = {
      icon: 'plus',
      type: 'default',
      onClick: () => {
        this.addNewFidelityCardEv();
      }
    };
  }

  addNewFidelityCardEv() {
    this.edit.showPopup(null).then(() => {
      this.gridSearchFidelity.instance.refresh();
    });
  }

  onKeyDown(ev: any) {
    if ((ev.keyCode === 17 || ev.keyCode === 74) && ev.ctrlKey === true) {
      ev.preventDefault();
    }
  }

  onEnterKey(event: any) {
    this.gridSearchFidelity.instance.searchByText(this.searchValue);
  }

  onInput(input) {
    this.gridSearchFidelity.instance.option('focusedRowIndex', -1);
    clearTimeout(this.timeoutSearch);
    this.timeoutSearch = setTimeout(() => {
      this.gridSearchFidelity.instance.searchByText(input.text);
    }, 1000);
  }

}
