import { Component, OnInit } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import { EditEntityRoleService } from './edit-entity-role.service';

@Component({
  selector: 'app-edit-entity-role',
  templateUrl: './edit-entity-role.component.html'
})
export class EditEntityRoleComponent implements OnInit {
  dsUserLogin: any;
  dsFacility: any;
  dsRole: any;

  constructor(public ui: UIService, public ds: DataService, public edit: EditEntityRoleService) { }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.dsRole = this.ds.getUserLoginRole();
  }

}
