import { Component, OnInit } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'app-active-substance',
  templateUrl: './active-substance.component.html',
  styleUrls: ['./active-substance.component.css']
})
export class ActiveSubstanceComponent implements OnInit {
  dsActiveSubstance: CustomStore;

  constructor(public ui: UIService, public ds: DataService) { }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.dsActiveSubstance = this.ds.getActiveSubstance();
  }

  toolbarPreparing(event: any) {
    this.ui.prepareToolbar(event, true);
  }
}
