import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import { ProxyService } from 'src/app/services/proxy.service';
import { EditWithdrawalService } from './edit-withdrawal.service';
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';

@Component({
    selector: 'app-edit-withdrawal',
    templateUrl: './edit-withdrawal.component.html'
})
export class EditWithdrawalComponent implements OnInit {

    @ViewChild('gridProduct', { static: false }) gridProduct: DxDataGridComponent;

    constructor(public ds: DataService, public ui: UIService, public proxy: ProxyService, public edit: EditWithdrawalService) { }

    ngOnInit() { }

    toolbarPreparing(e: any) {
        this.ui.prepareToolbar(e, true);
    }

    withdrawProducts(ev: any) {
        const selectedProducts = this.gridProduct.instance.getSelectedRowsData();
        if (!selectedProducts.length) {
            return;
        }
        const arrayToSend = [];
        selectedProducts.forEach(element => {
            arrayToSend.push({ lotId: element.lotId, productId: element.productId });
        });

        this.ds.updateRetiredProduct(arrayToSend).then((data) => {
            if (data === 'OK') {
                this.edit.closePopup();
                notify('Succes.', 'sucess', 3000);
            }
        });
    }
}
