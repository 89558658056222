import { Injectable } from '@angular/core';
import { EntityQuery, EntityStore } from '@dohu/ibis-entity';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';

@Injectable()
export class EditEntityRoleService extends iBisEditService {
    dsEntitiesExcept: CustomStore;
    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'entityRoleValidation';
    }
    createDefault() {
        return {};
    }
    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (!data.id) { reject(); }
            EntityStore.fromQuery(new EntityQuery('EntityRole').eq('id', data.id)).single().then((obj) => {
                if (!obj) {
                    reject();
                } else {
                    resolve(obj);
                }
            });
        });
    }
    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('EntityRole')).remove(id);
    }
    onSaveEv(): Promise<any> {
        const obj = this.model;
        const s = EntityStore.fromQuery(new EntityQuery('EntityRole'));
        return (this.model.id ? s.update(this.model.id, obj) : s.insert(obj));
    }
    reset(): void { }
}
