import { Component, OnInit } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'app-variance-out',
  templateUrl: './variance-out.component.html',
  styleUrls: ['./variance-out.component.css']
})
export class VarianceOutComponent implements OnInit {

  dsVarianceOut: CustomStore;

  constructor(public ds: DataService, public ui: UIService) { }

  ngOnInit() {
  }

  toolbarPreparing(e: any) {
    this.ui.prepareToolbar(e, true);
  }

}
