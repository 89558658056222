import { Component, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import { DxDataGridComponent } from 'devextreme-angular';
import { ProxyService } from 'src/app/services/proxy.service';
import { GlobalSearchService } from './global-search.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-global-search',
    templateUrl: './global-search.component.html',
    styleUrls: ['global-search.component.css']
})
export class GlobalSearchComponent implements OnInit, OnDestroy {

    timeoutSearch: any;
    editModelSubscription: Subscription;

    @ViewChild('gridProduct', { static: false }) gridProduct: DxDataGridComponent;

    constructor(public ds: DataService, public ui: UIService, public edit: GlobalSearchService,
        public proxy: ProxyService, public router: Router) {
        this.editModelSubscription = this.edit.modelChange.subscribe(
            (modelChanged: boolean) => {
                const isModelObject = this.edit.model.hasOwnProperty('ModelName');
                if (modelChanged && !isModelObject) {
                    // this.edit.dsItems = this.edit.model;
                }
            });
    }

    ngOnInit() {
        if (!this.ds.auth.isAuth) { return; }
    }

    ngOnDestroy() {
        if (this.editModelSubscription) {
            this.editModelSubscription.unsubscribe();
        }
    }

    onGridInit(ev: any) {
        this.edit.gridInstance = ev.component;
    }

    onSearchInit(ev: any) {
        this.edit.searchInstance = ev.component;
        this.edit.autoFocusSearch();
    }

    onGridDataSourceClear(ev: any) {
        if (ev.name === 'dataSource' && ev.value) {
            const isModelObject = ev.value.hasOwnProperty('ModelName');
            if (isModelObject) {
                // this.edit.gridInstance.option('dataSource', this.ds.onGlobalSearchClose());
            }
        }
    }

    onSingleProductSearchResult(ev: any) {
        // const gridItems = ev.component.getDataSource().items();
        // if (gridItems && gridItems.length === 1) {
        //     this.edit.productInfo = gridItems[0]; // pt cand produsul e retras
        //     if (gridItems[0].i_isRetired !== true) {
        //         this.edit.onSaveClose({ row: { data: gridItems[0] } }); // pentru edit service - linia 119
        //         this.edit.gridInstance.searchByText('');
        //         this.edit.gridInstance.option('focusedRowIndex', -1);
        //     }
        // }
    }

    onProductSelect(cell: any) {
        // if (this.edit.model.type === 'products') {
        //     // this.ds.getInventoryById(cell.data.id).then((res) => {
        //     // console.log(res);
        //     // this.edit.onSaveClose({ row: { data: res } });
        //     // });
        // } else {
        //     this.edit.onSaveClose(cell);
        // }
        this.edit.onSaveClose(cell);
    }

    getProductTaxPercent(id: string) {
        return this.edit.dsTax.find((x: any) => x.id === id).percent;
    }

    isProductModel(field: string) {
        return this.edit.model.type === 'products' ? field.split('_')[1] : field;
    }

    isStockCondition(ev: any) {
        return this.edit.model.type === 'stock' || this.edit.model.type === 'header-search';
    }

    showAddButtonCond(cell: any) {
        let show = false;
        show = cell.data.i_isRetired !== true || this.edit.model.type === 'return' || this.edit.model.type === 'products';
        if (this.edit.model.type === 'header-search') {
            show = cell.data.i_facilityId === this.ds.currentFacility.id;
        }
        return show;
    }

    onRowPrepared(rowInfo: any) {
        if (rowInfo.rowType === 'data' && rowInfo.data && rowInfo.data.i_isRetired && this.edit.model.type !== 'products') {
            rowInfo.rowElement.style.backgroundColor = '#eceff1';
        }

        if (rowInfo.rowType === 'data' && rowInfo.data && this.checkIfExpired(rowInfo.data)) {
            rowInfo.rowElement.style.color = 'red';
        }
    }

    checkIfExpired(data: any) {
        if (data && data.i_expireDate) {
            const now = new Date();
            const expireDate = new Date(data.i_expireDate);
            return now.getTime() >= expireDate.getTime();
        }
        return false;
    }

    onKeyDown(ev: any) {
        if ((ev.keyCode === 17 || ev.keyCode === 74) && ev.ctrlKey === true) {
            ev.preventDefault();
        }
    }

    onEnterKey(event: any) {
        this.edit.gridInstance.searchByText(this.edit.searchValue);
    }

    onInput(input: any) {
        this.edit.gridInstance.option('focusedRowIndex', -1);
        clearTimeout(this.timeoutSearch);
        this.timeoutSearch = setTimeout(() => {
            this.edit.gridInstance.searchByText(input.text);
        }, 1000);
    }

    onFocusedRowChanged(ev: any) {
        this.edit.productInfo = ev.row && ev.row.data ? ev.row.data : {};
        if (ev.row && ev.row.data && this.edit.model.type === 'products') {
            this.edit.productInfo.tax_percent = this.getProductTaxPercent(ev.row.data.taxId);
        }
    }
}

