import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { iBisAuthService } from '@dohu/ibis-auth';
import { environment } from '../environments/environment';
import { EntityStoreOptions } from '@dohu/ibis-entity';
import { iBisGridConfigService, iBisLanguageService } from '@dohu/ibis-common';
import { DataService, UIService } from './services';
declare var require: any;
import * as Bowser from 'bowser';
import notify from 'devextreme/ui/notify';
import { ProxyService } from './services/proxy.service';
import { Subscription } from 'rxjs';
import * as loc from '../assets/localization.json';
import * as devExtremeMessages from '../assets/devExtreme.json';
import { locale, loadMessages } from 'devextreme/localization';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'emim-web';
  appVersion: any;

  authChangeSubscription: Subscription;

  isInitAppVisible = false;
  initOptions: any;

  constructor(public ds: DataService, public auth: iBisAuthService, public ui: UIService,
    http: HttpClient, router: Router, private proxy: ProxyService, public localization: iBisLanguageService) {

    EntityStoreOptions.DefaultServer = environment.defaultUrlServer;
    EntityStoreOptions.Http = http;
    auth.deployId = environment.deployId;
    auth.authServer = environment.authServer;
    auth.saasServer = environment.saasServer;
    auth.appName = 'Emim';

    const { version: appVersion } = require('package.json');
    this.appVersion = appVersion;

    const bowser = Bowser.getParser(window.navigator.userAgent);
    const os = Bowser ? bowser.getOS() || {} : {};
    ui.isWindows = os.name === 'Windows';

    localization.server = environment.saasServer;
    locale('ro');
    loadMessages(loc);
    loadMessages(devExtremeMessages);

    this.authChangeSubscription = this.ds.authChanged.subscribe(
      (changed: any) => {
        if (changed && changed.isAuth) {
          this.ds.checkInitApp().then(data => {
            if (data === 'OK') {
              return;
            } else if (data === 'FIRST_CONFIGURATION') {
              this.initOptions = { hasCompany: false };
            } else if (data === 'NO_FACILITY') {
              this.initOptions = { hasCompany: true };
            }
            this.isInitAppVisible = true;
          }, err => {
            notify('Aplicația nu a putut fi inițializată', 'error', 3000);
          });
        }
      });
  }
}
