import { Injectable } from '@angular/core';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services';

@Injectable()
export class EditFidelityPersonService extends iBisEditService {
    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'fidelityPersonValidation';
    }

    createDefault() {
        return { openDate: new Date(), maritalStatus: false };
    }

    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            EntityStore.fromQuery(new EntityQuery('FidelityCardPersonView')
                .eq('p.id', data.personId).eq('fc.id', data.fidelityCardId)).single().then(data => {
                    const obj = {
                        id: data.p_id,
                        fullName: data.p_fullName,
                        gender: data.p_gender,
                        birthday: data.p_birthday,
                        maritalStatus: data.p_maritalStatus,
                        comments: data.p_comments,
                        pin: data.p_pin,
                        idValue: data.p_idValue,
                        email: data.p_email,
                        phone: data.p_phone,
                        no: data.fc_no,
                        typeId: data.fc_typeId,
                        openDate: data.fc_openDate,
                        closeDate: data.fc_closeDate,
                        description: data.fc_description
                    };
                    resolve(obj);
                });
        });
    }

    onRemove(id: string): Promise<void> {
        return new Promise((resolve) => { });
    }

    onSaveEv(): Promise<any> {
        return new Promise((resolve) => {
            const p = EntityStore.fromQuery(new EntityQuery('Person'));
            const r = this.model.id ? p.update(this.model.id, this.model) : p.insert(this.model);
            r.then(d => {
                this.model.id = d.id;
                EntityStore.fromQuery(new EntityQuery('FidelityCard')).insert(this.model).then(dd => {
                    resolve(dd);
                });
            });
        });
    }

    reset(): void {
        this.model = {};
    }
}

