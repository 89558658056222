import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import { ProxyService } from 'src/app/services/proxy.service';
import CustomStore from 'devextreme/data/custom_store';
import { ReturnProductService } from './return-product.service';
import notify from 'devextreme/ui/notify';

@Component({
    selector: 'app-return-product',
    templateUrl: './return-product.component.html'
})
export class ReturnProductComponent implements OnInit {

    dsParty: CustomStore;
    dsFacility: CustomStore;
    currentDate: any;

    constructor(public ds: DataService, public ui: UIService, public proxy: ProxyService, public returnProduct: ReturnProductService) { }

    ngOnInit() {
        this.dsParty = this.ds.getParty(1);
        this.dsFacility = this.ds.getFacility();
        this.returnProduct.model = this.returnProduct.createDefault();
        this.currentDate = new Date();
    }

    onDocDateChange = (ev: any) => {
        console.log(ev);
    }

    returnProductClick(ev: any) {
        const validation = ev.validationGroup.validate();
        if (!validation.isValid) {
            notify('Trebuie să completați toate câmpurile obligatorii.', 'error', 3000);
            return;
        }
        const obj: any = {};
        Object.assign(obj, this.returnProduct.model);
        const arr = [];
        this.returnProduct.dsReturnItems.forEach((element: any) => {
            const itemObj = {
                inventoryId: element.i_id,
                orderIndex: element.orderIndex,
                productId: element.i_productId,
                quantity: element.returnQuantity,
                fQuantity: element.returnFquantity,
                qtyPerPackage: element.qtyPerPackage,
                discount: element.discount,
                unitCost: element.i_unitCost,
                pricePerPackage: element.i_pricePerPackage
            };
            arr.push(itemObj);
        });
        obj.items = arr;
        this.ds.returnProduct(obj).then((data) => {
            if (data === 'OK') {
                notify('Succes', 'success', 3000);
                this.returnProduct.model = this.returnProduct.createDefault();
                this.returnProduct.dsReturnItems = [];
            }
        });
    }

    toolbarPreparing(e: any) {
        this.ui.prepareToolbar(e, true);

        e.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                text: 'Adaugă articol',
                icon: 'plus',
                onClick: (ev: any) => {
                    if (!this.returnProduct.model.partyId) {
                        notify('Trebuie să selectezi un furnizor pentru a adauga articole', 'warning', 3000);
                        return;
                    }
                    if (this.proxy.globalSearch.gridInstance) {
                        this.proxy.globalSearch.initGlobalSearch();
                    }
                    this.proxy.globalSearch.showPopup({
                        type: 'return',
                        partyId: this.returnProduct.model.partyId
                    }, true).then((data) => {
                        if (data) {
                            data.qtyPerPackage = data.p_qtyPerPackage;
                            data.orderIndex = this.returnProduct.dsReturnItems.length + 1;
                            data.returnQuantity = data.i_quantity || 0;
                            data.returnFquantity = data.i_fQuantity || 0;
                            this.returnProduct.dsReturnItems.push(data);
                        }
                    });
                }
            },
            location: 'after'
        });
    }
}
