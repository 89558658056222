import { Injectable } from '@angular/core';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services';
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs';

@Injectable()
export class EditOrderService extends iBisEditService {

  dsOrderItems: any = [];
  dsInitialOrderItems: any = [];
  deleteReady: Subject<any> = new Subject<any>();
  deleteSubscription: Subscription;

  constructor(public ds: DataService) {
    super(ds.lg);
    this.validation = 'orderValidation';
  }

  getViewById(id: string) {
    return EntityStore.fromQuery(new EntityQuery('OrderHeader').eq('id', id)).single();
  }

  createDefault() {
    return { date: new Date() };
  }

  getById(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.model = this.createDefault();
      this.dsOrderItems = [];
      this.dsInitialOrderItems = [];
      this.getViewById(id).then((obj) => {
        if (obj === null) {
          reject();
        } else {
          this.ds.loadOrderItems(id).then((res: any) => {
            if (res && res.data.length) {
              res.data.forEach((element: any) => {
                const item = {
                  ModelName: 'OrderItems', // este irelevant
                  id: element.oi_id,
                  orderIndex: element.oi_orderIndex,
                  requiredDate: element.oi_requiredDate,
                  quantity: element.oi_quantity,
                  productId: element.p_id,
                  name: element.p_name,
                  code: element.p_code,
                  qtyPerPackage: element.p_qtyPerPackage,
                  taxPercent: element.tax_percent,
                  facilityId: obj.facilityId
                };
                this.dsOrderItems.push(item);
                this.dsInitialOrderItems.push(item);
              });
            }
          });
          resolve(obj);
        }
      }, (e) => reject(e));
    });
  }

  async onRemove(id: string): Promise<void> {
    this.deleteSubscription = this.deleteReady.subscribe(
      (changed: any) => {
        if (changed) {
          EntityStore.fromQuery(new EntityQuery('OrderHeader')).remove(id);
        }
      });
    const q = new EntityQuery('OrderItem').eq('orderId', id);
    const res = await EntityStore.fromQuery(q).load();
    if (res.data && res.data.length) {
      res.data.forEach((element: any, index: number) => {
        EntityStore.fromQuery(new EntityQuery('OrderItem')).remove(element.id).then(() => {
          if (index + 1 === res.data.length) {
            this.deleteReady.next(true);
            return;
          }
        });
      });
    }
    this.deleteReady.next(true);
  }

  onSaveEv(): Promise<any> {
    const obj: any = {};
    Object.assign(obj, this.model);
    this.model.statusId = 1;
    return new Promise<any>((resolve, reject) => {
      const s = EntityStore.fromQuery(new EntityQuery('OrderHeader'));
      const op = this.model.id ? s.update(this.model.id, this.model) : s.insert(this.model);
      op.then((res) => {
        const checkForRemove = this.differenceInFirstArray(this.dsInitialOrderItems, this.dsOrderItems);
        if (checkForRemove && checkForRemove.length) {
          checkForRemove.forEach((ri: any, i: number) => {
            EntityStore.fromQuery(new EntityQuery('OrderItem')).remove(ri.id).then(() => {
              if (i + 1 === checkForRemove.length) {
                this.editInsertOrderItems(this.dsOrderItems, res.id || this.model.id).then((r: any) => {
                  this.dsInitialOrderItems = r.slice(0);
                  this.dsOrderItems = r.slice(0);
                  resolve(res);
                });
              }
            });
          });
        } else {
          this.editInsertOrderItems(this.dsOrderItems, res.id || this.model.id).then((r: any) => {
            this.dsInitialOrderItems = r.slice(0);
            this.dsOrderItems = r.slice(0);
            resolve(res);
          });
        }
      });
    });
  }

  editInsertOrderItems(items: any, orderId: string) {
    return new Promise((resolve, reject) => {
      items.forEach((element: any, index: number) => {
        element.orderId = orderId;
        const si = EntityStore.fromQuery(new EntityQuery('OrderItem'));
        const opi = element.id ? si.update(element.id, element) : si.insert(element);
        opi.then((resItem: any) => {
          element.id = resItem.id || resItem.key;
          if (index + 1 === items.length) {
            resolve(items);
          }
        });
      });
    });
  }

  differenceInFirstArray(array1: any, array2: any) {
    return array1.filter((current: any) => {
      return array2.filter((current_b: any) => {
        return current_b === current;
      }).length === 0;
    });
  }


  reset(): void {
    this.model = {};
  }
}
