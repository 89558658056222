import { Component, NgModule, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { iBisAuthService } from '@dohu/ibis-auth';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxPopoverModule, DxListModule, DxFormModule, DxPopupModule } from 'devextreme-angular';
import { Router } from '@angular/router';
import { InviteUserModule } from '../invite-user/invite-user.component';
import { AddCompanyModule } from '../add-company/add-company.component';
import { ProxyService } from 'src/app/services/proxy.service';
import { DataService } from 'src/app/services';
import { EditFacilityComponent } from 'src/app/view/admin/facility/edit-facility/edit-facility.component';
import { ViewModule } from 'src/app/view/view.module';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
  @Output() menuToggle = new EventEmitter<boolean>();

  @Input() menuToggleEnabled = false;
  @Input() companyName: string;

  currentTarget: string;
  menuItems: any[];
  isSubMenuVisible: boolean;
  onShowMenu: any;
  openPayment: any;
  openGlobalSearch: any;

  isAddCompanyVisible: boolean;
  isInviteUserVisible: boolean;

  constructor(private auth: iBisAuthService, private router: Router, public proxy: ProxyService, public ds: DataService) {
    this.isSubMenuVisible = false;
    this.isAddCompanyVisible = false;
    this.isInviteUserVisible = false;
    this.onShowMenu = this.onShowMenuEv.bind(this);
    this.openPayment = this.openPaymentEv.bind(this);
    this.openGlobalSearch = this.openGlobalSearchEv.bind(this);
    this.ds.initUserFacility();
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  }

  openPaymentEv(event) {
    this.router.navigateByUrl('/sales/cashRegister');
  }

  openGlobalSearchEv(e: any) {
    if (this.proxy.globalSearch.gridInstance) {
      this.proxy.globalSearch.initGlobalSearch();
    }
    this.proxy.globalSearch.showPopup({ type: 'header-search' }, true).then((data) => {
      if (data) {
        this.proxy.editReceipt.dsReceiptItems = [];
        this.proxy.editReceipt.initTotal();
        this.proxy.editReceipt.addProduct(data);
        this.ds.getTodayReceiptCount().then((count: number) => {
          this.proxy.editReceipt.showPopup({ refNumber: count + 1, docDate: new Date(), typeId: -3, discount: 0 });
        });
      }
    });
  }

  onShowMenuEv(target: string, e: any) {
    if (e) {
      e.event.preventDefault();
    }
    this.currentTarget = target;
    switch (target) {
      case '#help-options':
        this.menuItems = [{ icon: 'download', text: 'Ghid de utilizare', route: '/download' }];
        break;
      case '#user-options':
        this.menuItems = [
          { icon: 'user', text: 'Profil', route: '/profile' },
          // { icon: 'message', text: 'Invită utilizator', route: '/invite' },
          { icon: 'runner', text: 'Deconectare', route: '/login' }
        ];
        if (this.ds.isAdmin()) {
          this.menuItems.splice(1, 0, { icon: 'group', text: 'Date companie', route: '/company' });
        }
        break;
      case '#company-options':
        this.menuItems = JSON.parse(JSON.stringify(this.auth.dsCompanies));
        const hasPlus = this.menuItems.find(x => x.icon === 'plus');
        if (hasPlus) { this.menuItems.pop(); }
        if (!this.menuItems.length) { return; } // sa nu deschida popover-ul daca nu are alte companii
        break;
      case '#wc-options':
        this.menuItems = JSON.parse(JSON.stringify(this.ds.dsFacility));
        const hasWcPlus = this.menuItems.find(x => x.icon === 'plus');
        if (hasWcPlus) { this.menuItems.pop(); }
        break;
    }
    this.isSubMenuVisible = true;
  }

  onClosePopup(e: any) {
    this.isInviteUserVisible = false;
  }

  onMenuLeave() {
    this.isSubMenuVisible = false;
  }

  onIconMenuClick(e: any) {
    switch (this.currentTarget) {
      case '#help-options':
        if (e.itemData.route === '/download') {
          const fileLink = document.createElement('a');
          fileLink.href = '../../assets/EMIM_Guide.pdf';
          fileLink.download = 'EMIM guide short version';
          fileLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window })); // for mozilla
        }
        break;
      case '#user-options':
        if (e.itemData.route === '/login') {
          this.auth.logout().then(() => {
            sessionStorage.removeItem('navMenuItem');
            sessionStorage.removeItem('currentFacility');
            this.ds.currentFacility = null;
            this.router.navigateByUrl('/login');
          });
        } else if (e.itemData.route) {
          this.router.navigateByUrl(e.itemData.route);
        }
        break;

      case '#company-options':
        if (e.itemData.id && e.itemData.text) {
          const temporarySave = {};
          Object.assign(temporarySave, this.ds.currentFacility);
          sessionStorage.removeItem('currentFacility');
          this.auth.changeCompany(e.itemData).then(result => {
          }, err => {
            sessionStorage.setItem('currentFacility', JSON.stringify(temporarySave));
            if (typeof err === 'string') {
              this.ds.serverError();
            } else {
              notify('Cererea nu a putut fi procesată.', 'error', 3000);
            }
          });
        }
        break;
      case '#wc-options':
        if (e.itemData.type === 'add') {
          this.proxy.editFacility.showPopup({}).then(() => {
            this.ds.initUserFacility();
            this.ds.redirectTo(this.router.url);
          });
        } else if (e.itemData.id && e.itemData.text && e.itemData.id !== this.ds.currentFacility.id) {
          this.ds.currentFacility = e.itemData;
          this.ds.initUserFacility();
          sessionStorage.setItem('currentFacility', JSON.stringify(e.itemData));
          this.ds.redirectTo(this.router.url);
        }
        break;
    }
    this.onMenuLeave();
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    DxToolbarModule,
    DxPopoverModule,
    DxListModule,
    DxPopupModule,
    DxFormModule,
    AddCompanyModule,
    InviteUserModule,
    ViewModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class HeaderModule { }
