import { Component, OnInit, ViewChild } from '@angular/core';
import { EditTransferService } from './edit-transfer.service';
import { ProxyService } from 'src/app/services/proxy.service';
import { UIService, DataService } from 'src/app/services';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { EntityStore } from '@dohu/ibis-entity';

@Component({
    selector: 'app-edit-transfer',
    templateUrl: './edit-transfer.component.html'
})
export class EditTransferComponent implements OnInit {

    dsFacility: CustomStore;
    quantityEditorOptions: any;
    dsInventory: Promise<any> & JQueryPromise<any>;

    @ViewChild('gridTransferItem', { static: false }) gridTransferItem: DxDataGridComponent;

    constructor(public ui: UIService, public ds: DataService, public edit: EditTransferService, public proxy: ProxyService) { }

    ngOnInit() {
        this.dsFacility = this.ds.getTransferFacility();
        this.quantityEditorOptions = {
            format: 'fixedPoint',
            precision: 0
        };
    }

    onEditorPreparing(ev: any) {
        if (ev.parentType === 'dataRow' && ev.dataField === 'fQuantity') {
            if (ev.row.data.quantityPerPackage === 1) {
                ev.editorOptions.readOnly = true;
            }
        }
    }

    fractionCustomValidation = (options: any) => {
        if (options.data.fQuantity >= options.data.quantityPerPackage) {
            options.rule.message = 'Cantitatea fracționară depășește numărul de comprimate / FA (' + options.data.quantityPerPackage + ').';
            return false;
        }
        if (options.value < 0) {
            options.rule.message = 'Fracția nu poate fi negativă.';
            return false;
        }
        return true;
    }

    checkTotalQuantityValidation = (options: any) => {
        if (!options.data.fQuantity && !options.data.quantity) {
            options.rule.message = 'Nu poți transfera cu ambele cantități 0!';
            return false;
        }
        return true;
    }


    quantityCustomValidation = (options: any) => {
        const min = options.data.quantityPerPackage === 1 ? 1 : 0;
        if (options.value < min || (!options.value && min === 1)) {
            options.rule.message = 'Cantitatea nu poate fi mai mică de ' + min + '.';
            return false;
        }

        return true;
    }

    stockQuantityValidation = (options: any) => {
        const sQuantity = this.edit.calculateQuantity(options.data.quantity, options.data.fQuantity, options.data.quantityPerPackage);
        if (options.data.quantityStock < sQuantity) {
            options.rule.message = 'Cantitate stoc depășită, cantitate maximă permisă: ' + options.data.quantityStock;
            return false;
        }
        return true;
    }

    checkFQuantityValidation = (ev: any) => {
        // this.validationMessage = 'Cantitatea fracționară depășește nr. de comprimate pe forma de ambalare!';
        return (ev.data.fQuantity < ev.data.quantityPerPackage);
    }

    checkIfQuantitiesNull = (ev: any) => {
        // this.validationMessage = 'Nu poți transfera cu ambele cantități 0.!';
        return ev.data.fQuantity !== 0 || ev.data.quantity !== 0;
    }

    toolbarPreparing(ev: any) {
        this.ui.prepareToolbar(ev, true);
        ev.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: 'Adaugă',
                text: 'Adaugă produs',
                icon: 'plus',
                onClick: (e: any) => {
                    if (this.proxy.globalSearch.gridInstance) {
                        this.proxy.globalSearch.initGlobalSearch();
                    }
                    this.proxy.globalSearch.showPopup({ type: 'stock' }, true).then((data) => {
                        if (data) {
                            const obj = {
                                orderIndex: this.edit.dsTransferItem.length + 1,
                                productId: data.p_id,
                                inventoryId: data.i_id,
                                pName: data.p_name,
                                pCode: data.p_code,
                                uom: data.uom_code,
                                quantity: data.i_quantity || 0,
                                fQuantity: data.i_fQuantity || 0,
                                quantityPerPackage: data.p_qtyPerPackage,
                                quantityStock: this.edit.calculateQuantity(data.i_quantity, data.i_fQuantity, data.p_qtyPerPackage)
                            };
                            this.edit.dsTransferItem.push(obj);
                            this.gridTransferItem.instance.refresh();
                        }
                    });
                }
            },
            location: 'after'
        });
    }
}
