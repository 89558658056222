import { Component, OnInit } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import { EditPersonService } from './edit-person.service';

@Component({
  selector: 'app-edit-person',
  templateUrl: './edit-person.component.html'
})
export class EditPersonComponent implements OnInit {
  minDate: Date;
  maxDate: Date;

  constructor(public ui: UIService, public ds: DataService, public edit: EditPersonService) { }

  ngOnInit() {
    this.minDate = new Date(new Date().getFullYear() - 90, new Date().getMonth(), new Date().getDate());
    this.maxDate = new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate());
  }

}
