import { Component, NgModule } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { Router } from '@angular/router';
import { routes } from '../../app-routing.module';

@Component({
  selector: 'app-single-card',
  templateUrl: './single-card.component.html',
  styleUrls: ['./single-card.component.scss']
})
export class SingleCardComponent {
  constructor(public router: Router, public location: Location) {

    // router.events.subscribe((url: any) => {
    if (this.location.path() === '') {
      this.router.navigateByUrl('/login');
    }
    if (!this.checkUrlForDxCard()) {
      this.router.navigateByUrl('/login');
    }
    // });
  }

  initUnauthorizedRoutes() {
    const arr = [];
    for (const r of routes) {
      if (!r.canActivate && !r.redirectTo && !r.children) {
        arr.push('/' + r.path);
      }
    }
    return arr;
  }

  checkUrlForDxCard() {
    const arr = this.initUnauthorizedRoutes();
    let result = false;
    for (const link of arr) {
      result = this.location.path().indexOf(link) > -1;
      if (result) { return result; }
    }
    return result;
  }
}

@NgModule({
  imports: [CommonModule, DxScrollViewModule],
  exports: [SingleCardComponent],
  declarations: [SingleCardComponent]
})
export class SingleCardModule { }
