import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import { EditPrescriptionService } from './edit-prescription.service';
import { ProxyService } from 'src/app/services/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { EntityQuery, EntityStore } from '@dohu/ibis-entity';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-edit-prescription',
    templateUrl: './edit-prescription.component.html'
})
export class EditPrescriptionComponent implements OnInit, OnDestroy {

    onCloseScanClick: any;
    onMedicChanged: any;
    onStencilChanged: any;
    onCuiChanged: any;

    dsMedics: CustomStore;

    scanValue: any = '';
    myClickEvent: any;
    addBtInst: any;
    editModelSubscription: Subscription;
    readOnlyCondition: boolean;

    isLoadPanelVisible = false;
    changeBorder: any;

    @ViewChild('gridProduct', { static: false }) gridProduct: DxDataGridComponent;

    constructor(public ds: DataService, public ui: UIService, public edit: EditPrescriptionService, public proxy: ProxyService) { }

    ngOnInit() {
        this.editModelSubscription = this.edit.modelChange.subscribe(
            (modelChanged: boolean) => {
                if (modelChanged) {
                    this.readOnlyCondition = this.edit.model.id || this.edit.model.scannedPrescription;
                    if (this.addBtInst) {
                        this.addBtInst.option('visible', !this.edit.model.scannedPrescription && !this.edit.model.id);
                    }
                }
            });
        this.onCloseScanClick = this.onCloseScanClickEv.bind(this);
        this.onMedicChanged = this.onMedicChangedEv.bind(this);
        this.onStencilChanged = this.onStencilChangedEv.bind(this);
        this.onCuiChanged = this.onCuiChangedEv.bind(this);

        this.myClickEvent = (event: any) => this.focusTextbox();

        if (!this.ds.auth.isAuth) { return; }
        this.dsMedics = this.ds.getMedics();
    }

    ngOnDestroy() {
        if (this.editModelSubscription) {
            this.editModelSubscription.unsubscribe();
        }
    }


    onKeyDown(ev: any) {
        if ((ev.keyCode === 17 || ev.keyCode === 74) && ev.ctrlKey === true) {
            ev.preventDefault();
        }

        if (ev.keyCode === 13) { // enter
            this.ds.scanReceipt(this.scanValue).then((data: any) => {
                if (!data) {
                    // mesaj de eroare;
                    this.isLoadPanelVisible = false;
                }
                const getPatientProp = this.getPropertyInString(data, 'PT=');
                const getUnitProp = this.getPropertyInString(data, ' S=');
                const category = this.ds.static.patientType.find((x: any) => getPatientProp.indexOf(x.value) > -1).id;
                const prescriptionModel = this.ds.static.medicalUnitType.find((x: any) => getUnitProp.indexOf(x.value) > -1).id;
                const obj: any = {
                    serial: this.getPropertyInString(data, 'SC='),
                    serialNo: this.getPropertyInString(data, 'SN='),
                    medicalUnitId: this.getPropertyInString(data, 'CC='),
                    contractNo: this.getPropertyInString(data, 'OU=') + '/' + this.getPropertyInString(data, 'CN='),
                    prescriptionDate: this.parseToDate(this.getPropertyInString(data, 'ID=')),
                    physicianStenceil: this.getPropertyInString(data, 'PS='),
                    physicianPhone: this.getPropertyInString(data, 'PP='),
                    physicianEmail: this.getPropertyInString(data, 'PE='),
                    medicalUnitAddress: this.getPropertyInString(data, 'PA='),
                    pin: this.getPropertyInString(data, 'CD='),
                    firstName: this.getPropertyInString(data, 'FN='),
                    surName: this.getPropertyInString(data, 'LN='),
                    birthDate: this.parseToDate(this.getPropertyInString(data, 'BD=')),
                    selectedPatientType: this.edit.setTypeArrDataSource(category, true),
                    selectedMedicalUType: this.edit.setTypeArrDataSource(prescriptionModel),
                    forc: this.getPropertyInString(data, 'MR='),
                    gender: this.getPropertyInString(data, 'SX='),
                    country: this.getPropertyInString(data, 'CT=', true),
                    scannedPrescription: true,
                    isApproved: false
                };
                this.ds.getMedicByStencil(obj.physicianStenceil).then((doctor) => {
                    if (doctor) {
                        obj.physicianName = doctor.fullName;
                    }
                });

                this.ds.getCompanyByCUI(obj.medicalUnitId).then((company) => {
                    if (company) {
                        obj.medicalUnit = company.legalName;
                    }
                });

                this.createItemsArray(this.getItemsFromString(data));

                this.edit.isScanPrescriptionVisible = false;
                this.proxy.editPrescription.showPopup(obj).then(() => {
                });

                this.isLoadPanelVisible = false;
                this.focusTextbox();
            }, err => {
                this.isLoadPanelVisible = false;
            });
        }
    }

    parseToDate(txt: string) {
        const date = txt.split('-');
        return new Date(Number(date[0]), Number(date[1]) - 1, Number(date[2]));
    }

    getPropertyInString(scanResult: string, byProperty: string, lastIndex = false) {
        let propertyIndex: any;
        if (lastIndex) {
            propertyIndex = scanResult.lastIndexOf(byProperty);
        } else {
            propertyIndex = scanResult.indexOf(byProperty);
        }
        const text = scanResult.substr(propertyIndex, scanResult.length);
        const firstMark = text.indexOf('"');
        const secondMark = text.indexOf('"', firstMark + 1);
        const property = text.substr(firstMark, secondMark).split('"')[1];
        return property;
    }

    getItemsFromString(scanResult: string) {
        const array = [];
        for (let i = 0; i < scanResult.length; i++) {
            if (scanResult[i] === '<' && scanResult[i + 1] === 'D') {
                const firstIndex = scanResult.indexOf('<D', i) + 1;
                const nextIndex = scanResult.indexOf('/>', firstIndex);
                const item = scanResult.substring(firstIndex, nextIndex);
                array.push(item);
            }
        }
        return array;
    }

    createItemsArray(data: any) {
        this.edit.dsPrescriptionItems = [];
        data.forEach(element => {
            const name = this.getPropertyInString(element, 'AS=') + ' /' + this.getPropertyInString(element, 'PF=') + ' /' +
                this.getPropertyInString(element, ' C=');
            const obj: any = {
                orderIndex: this.getPropertyInString(element, 'CN='),
                diagCode: Number(this.getPropertyInString(element, 'DC=')),
                diagType: Number(this.getPropertyInString(element, 'DT=')),
                name: name,
                days: Number(this.getPropertyInString(element, 'TD=')),
                refPrice: this.getPropertyInString(element, 'LP='),
                quantity: Number(this.getPropertyInString(element, 'Q=')),
                listDate: this.getPropertyInString(element, 'LT=')
            };
            obj.ds = (obj.quantity / obj.days) + '/zi';
            this.edit.dsPrescriptionItems.push(obj);
        });
    }

    addClickEvent() {
        this.focusTextbox();
        const body = document.getElementsByTagName('BODY')[0];
        if (body.addEventListener) {
            body.addEventListener('click', this.myClickEvent);
        }
    }

    removeClickEvent() {
        const body = document.getElementsByTagName('BODY')[0];
        if (body.removeEventListener) {
            body.removeEventListener('click', this.myClickEvent);
        }
    }

    focusTextbox() {
        this.scanValue = '';
        const scanText = document.getElementById('scan-text-box');
        const isFocused = document.activeElement === scanText;
        if (!isFocused) {
            scanText.focus();
            scanText.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window })); // for mozilla
        }
    }

    onReceiptWithPrescriptionOpen(ev: any) {
        if (ev.validationGroup) {
            const validators = ev.validationGroup.validators.filter((x: any) => x._isHidden === false);
            const validation = ev.validationGroup;
            validation.validators = validators;
            if (!validation.validate().isValid) {
                notify('Trebuie să completați toate câmpurile obligatorii.', 'error', 3000);
                return;
            }

            const precObj: any = {};
            Object.assign(precObj, this.edit.model);
            const currentFacility = JSON.parse(sessionStorage.getItem('currentFacility'));
            precObj.facilityId = currentFacility.id;
            precObj.category = this.edit.getTypeArr(precObj.selectedPatientType);
            precObj.prescriptionModel = this.edit.getTypeArr(precObj.selectedMedicalUType);
            const s = EntityStore.fromQuery(new EntityQuery('Prescription'));
            const op = precObj.id ? s.update(precObj.id, precObj) : s.insert(precObj);
            op.then((res: any) => {
                this.edit.dsPrescriptionItems.forEach(element => {
                    element.prescriptionId = res.id || precObj.id;
                    const si = EntityStore.fromQuery(new EntityQuery('PrescriptionItem'));
                    element.id ? si.update(element.id, element) : si.insert(element);
                });

                this.edit.closePopup();
                this.proxy.editReceipt.isWizardVisible = true;
                this.proxy.editReceipt.prescriptionId = res.id || precObj.id;
                this.proxy.editReceipt.wizardItems = [];
                this.proxy.editReceipt.createDefault();
                this.edit.dsPrescriptionItems.forEach(element => {
                    this.proxy.editReceipt.wizardItems.push(element);
                });
            });
        }
    }

    onPatientTypeChanged(ev: any) {
        if (ev.removedItems.length && (ev.removedItems[0].id === 2048 || ev.removedItems[0].id === 131072)) {
            document.getElementById(ev.removedItems[0].id).blur();
            ev.removedItems[0].id === 2048 ? this.edit.model.pns = null : this.edit.model.other = null;
        }
        if (ev.addedItems.length && (ev.addedItems[0].id === 2048 || ev.addedItems[0].id === 131072)) {
            document.getElementById(ev.addedItems[0].id).focus();
        }
    }

    onMedicChangedEv(ev: any) {
        if (ev.selectedItem && typeof ev.selectedItem === 'object') {
            this.edit.model.physicianStenceil = ev.selectedItem.stencil;
        }
        if (ev.selectedItem === null) {
            this.edit.model.physicianStenceil = null;
        }
    }

    onStencilChangedEv(ev: any) {
        if (ev.value) {
            this.ds.getMedicByStencil(ev.value).then((doctor) => {
                if (doctor) {
                    this.edit.model.physicianName = doctor.fullName;
                }
            });
        }
    }

    onCuiChangedEv(ev: any) {
        if (ev.value) {
            this.ds.getCompanyByCUI(ev.value).then((company) => {
                if (company) {
                    this.edit.model.medicalUnit = company.legalName;
                }
            });
        }
    }

    onMedicalUnitTypeChanged(ev: any) {
        if (ev.removedItems.length && ev.removedItems[0].id === 8) {
            document.getElementById(ev.removedItems[0].id).blur();
            this.edit.model.prescriptionOther = null;
        }
        if (ev.addedItems.length && (ev.addedItems[0].id === 8)) {
            document.getElementById(ev.addedItems[0].id).focus();
        }
    }

    onInitNewRow(ev: any) {
        ev.data.orderIndex = this.edit.dsPrescriptionItems.length + 1;
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                visible: !this.edit.model.id && !this.edit.model.scannedPrescription,
                hint: 'Adaugă articol', icon: 'plus', onClick: (e: any) => {
                    event.component.addRow();
                },
                onInitialized: (ev: any) => { this.addBtInst = ev.component; }
            },
            location: 'after'
        });
    }

    onCloseScanClickEv() {
        this.edit.isScanPrescriptionVisible = false;
        this.edit.createDefault();
        this.edit.showPopup({
            selectedMedicalUType: [], selectedPatientType: [],
            scannedPrescription: false, isApproved: false
        }).then(() => {
            this.proxy.editReceipt.mainReceiptGridInstance.refresh();
            this.gridProduct.instance.cancelEditData();
        }, err => {
            console.log(err);
        });
    }
}
