import { Component, OnInit } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import { PromoEditService } from './promo-edit.service';

@Component({
  selector: 'app-promo-edit',
  templateUrl: './promo-edit.component.html'
})
export class PromoEditComponent implements OnInit {
  dsPromoType: any;

  constructor(public ds: DataService, public ui: UIService, public edit: PromoEditService) { }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.dsPromoType = this.ds.getEnumValue('PROMOTYPE');
  }

}
