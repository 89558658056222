import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DataService, UIService } from 'src/app/services';

@Component({
    selector: 'app-product-description',
    templateUrl: 'product-description.component.html'
})
export class ProductDescriptionComponent {

    @Input() isDescriptionVisibile: boolean;
    @Input() productData: any = {};
    @Output() closePopup = new EventEmitter<any>();

    constructor(public ds: DataService, public ui: UIService) {
        if (!this.ds.auth.isAuth) { return; }
    }


    onCloseProductDescription = (ev: any) => {
        this.isDescriptionVisibile = false;
        this.closePopup.emit(this.isDescriptionVisibile);
        this.productData = {};
    }
}

