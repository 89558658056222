import { Component, OnInit } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import { EditFacilityService } from './edit-facility.service';

@Component({
  selector: 'app-edit-facility',
  templateUrl: './edit-facility.component.html'
})
export class EditFacilityComponent implements OnInit {
  dsFacilityType: any;
  minDate: any;

  constructor(public ui: UIService, public ds: DataService, public edit: EditFacilityService) { }

  ngOnInit() {
    this.minDate = new Date();
    if (!this.ds.auth.isAuth) { return; }
    this.dsFacilityType = this.ds.getEnumValue('FACILITY');
  }

}
