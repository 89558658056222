import { Injectable, ViewChild } from '@angular/core';
import { EntityQuery, EntityStore } from '@dohu/ibis-entity';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services';

@Injectable()
export class ReturnProductService extends iBisEditService {

    dsReturnItems: any = [];
    model: any;
    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'returnValidation';
    }

    closePopup() {
        this.popup.hide();
    }

    createDefault() {
        const currentFacility = JSON.parse(sessionStorage.getItem('currentFacility'));
        return { typeId: 3, facilityId: currentFacility ? currentFacility.id : null };
    }

    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (!data.i_id) { reject(); }
            EntityStore.fromQuery(new EntityQuery('Invoice').eq('id', data.i_id)).single().then((obj) => {
                if (!obj) {
                    reject();
                } else {
                    resolve(obj);
                }
            });
        });
    }

    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('Invoice')).remove(id);
    }

    reset(): void {
        this.model.id = null;
    }

    onSaveEv() {
        const obj: any = {};
        Object.assign(obj, this.model);
        return new Promise<any>((resolve, reject) => { resolve('OK'); });
    }
}
