import { Component, OnInit } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html'
})
export class StockComponent implements OnInit {

  dsStock: CustomStore;

  constructor(public ds: DataService, public ui: UIService) { }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    if (!this.ds.currentFacility) {
      this.ds.isFacilityLoaded.subscribe(() => {
        this.dsStock = this.ds.getInventory();
      });
    } else {
      this.dsStock = this.ds.getInventory();
    }
  }

  calculateValIntrare(row: any) {
    return row ? (Math.round(row.i_pricePerPackage * row.i_quantity * 100) / 100) : null;
  }
  calculateUnits(row: any) {
    return row ? (Math.round(row.p_qtyPerPackage * row.i_quantity * 100) / 100) + row.i_fQuantity : null;
  }
  calculateStockDays(row: any) {
    return row && row.i_receivedDate ? Math.floor((new Date().getTime() -
      new Date(row.i_receivedDate).getTime()) / 1000 / 60 / 60 / 24) : null;
  }

}
