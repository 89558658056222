import { Component, OnInit, ViewChild } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent } from 'devextreme-angular';
import { ProxyService } from 'src/app/services/proxy.service';
import DataSource from 'devextreme/data/data_source';
import { EntityStoreOptions } from '@dohu/ibis-entity';

@Component({
  selector: 'app-supply-invoice',
  templateUrl: './supply-invoice.component.html'
})
export class SupplyInvoiceComponent implements OnInit {

  @ViewChild('gridSupply', { static: false }) gridSupply: DxDataGridComponent;

  dsInvoiceView: CustomStore;
  dsInvoiceItems: CustomStore;
  onDownloadInvoiceClick: any;
  dsTax: CustomStore;

  onEditInvoiceClick: any;
  onDeleteInvoiceClick: any;

  constructor(public ui: UIService, public ds: DataService, public proxy: ProxyService) { }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    if (!this.ds.currentFacility) {
      this.ds.isFacilityLoaded.subscribe(() => {
        this.dsInvoiceView = this.ds.getInvoiceView(true);
      });
    } else {
      this.dsInvoiceView = this.ds.getInvoiceView(true);
    }
    this.onDownloadInvoiceClick = this.onDownloadInvoiceClickEv.bind(this);
    this.dsTax = this.ds.getEnumValue('TAXID');
  }

  onGridInitialized(row: any) {
    this.dsInvoiceItems = this.ds.getInvoiceItems(row.data.i_id);
  }

  onInvoiceExpand(event) {
    event.component.collapseAll(-1);
  }

  onDownloadInvoiceClickEv(ev: any) {
    this.ds.getFile(EntityStoreOptions.DefaultServer + 'DownloadNIR' + '/' + ev.row.key);
  }

}
