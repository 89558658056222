import { Component, OnInit, ViewChild } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import { EditCompanyService } from 'src/app/view/admin/company/edit-company/edit-company.service';
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';

@Component({
    selector: 'app-company',
    templateUrl: './company.component.html'
})
export class CompanyComponent implements OnInit {

    dsCompany: CustomStore;

    onEditCompanyClick: any;
    onDeleteCompanyClick: any;

    @ViewChild('gridCompany', { static: false }) gridCompany: DxDataGridComponent;

    constructor(public ui: UIService, public ds: DataService, public edit: EditCompanyService, private router: Router) {
        this.onEditCompanyClick = this.onEditCompanyClickEv.bind(this);
        this.onDeleteCompanyClick = this.onDeleteCompanyClickEv.bind(this);
        if (!this.ds.isAdmin()) {
            this.router.navigateByUrl('/supply/receiving');
        }
    }

    ngOnInit() {
        if (!this.ds.auth.isAuth) { return; }
        this.dsCompany = this.ds.getCompany();
    }

    onEditCompanyClickEv(e: any) {
        e.event.preventDefault();
        this.edit.showPopup(e.row.data, true).then(
            () => this.gridCompany.instance.refresh(),
            (error) => this.ds.serverError(error)
        );
    }

    onDeleteCompanyClickEv(e: any) {
        e.event.preventDefault();
        this.edit.remove(e.row.data.id).then(() => {
            notify('Success', 'success', 2000);
            this.gridCompany.instance.refresh();
        });
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: 'Adaugă companie', icon: 'plus', onClick: () => this.edit.showPopup({}).then(() => {
                    this.gridCompany.instance.refresh();
                    this.edit.createDefault();
                })
            },
            location: 'after'
        });
    }

}
