import { Injectable } from '@angular/core';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services';

@Injectable()
export class PromoItemService extends iBisEditService {

  constructor(public ds: DataService) {
    super(ds.lg);
    this.validation = 'itemValidation';
  }

  getViewById(id: string) {
    return EntityStore.fromQuery(new EntityQuery('CatalogProduct').eq('id', id)).single();
  }

  createDefault() {
    return {};
  }

  getById(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getViewById(id).then((obj) => {
        if (obj === null) {
          reject();
        } else {
          resolve(obj);
        }
      }, (e) => reject(e));
    });
  }

  onRemove(id: string): Promise<void> {
    return EntityStore.fromQuery(new EntityQuery('CatalogProduct')).remove(id);
  }

  onSaveEv(): Promise<any> {
    return new Promise((resolve) => {
      const s = EntityStore.fromQuery(new EntityQuery('CatalogProduct'));
      const op = this.model.id ? s.update(this.model.id, this.model) : s.insert(this.model);
      op.then(() => {
        this.model.isSaving = true;
        resolve(this.model);
      });
    });
  }

  reset(): void {
    this.model = {};
  }
}
