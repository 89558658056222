import { Injectable, ViewChild } from '@angular/core';
import { EntityQuery, EntityStore } from '@dohu/ibis-entity';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services';

@Injectable()
export class EditPrescriptionService extends iBisEditService {

    isScanPrescriptionVisible: boolean;
    dsPrescriptionItems: any = [];
    changeColor: any;
    colorInterval: any;

    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'prescriptionValidation';
        this.isScanPrescriptionVisible = false;
    }

    getTypeArr(typeArr: number[]) {
        if (typeArr && typeArr.length > 0) {
            let result = 0;
            for (const act of typeArr) {
                result += act;
            }
            return result;
        }
        return 0;
    }

    setTypeArrDataSource(typeNo: number, patient?) {
        if (!typeNo) { return []; }
        const result = [];
        const arr = patient ? this.ds.static.patientType : this.ds.static.medicalUnitType;
        for (const val of arr) {
            // tslint:disable-next-line:no-bitwise
            if (typeNo & val.id) {
                result.push(val.id);
            }
        }
        return result;
    }

    closePopup() {
        this.popup.hide();
    }

    openPrescriptionScan() {
        clearInterval(this.colorInterval);
        this.isScanPrescriptionVisible = true;
        this.colorInterval = setInterval(() => {
            if (this.changeColor === '#e9e9e9') {
                this.changeColor = '#afafaf';
            } else {
                this.changeColor = '#e9e9e9';
            }
        }, 1000);
    }

    createDefault() {
        this.dsPrescriptionItems = [];
        return {};
    }

    onSaveEv() {
        return new Promise<any>((resolve, reject) => {
            const obj: any = {};
            Object.assign(obj, this.model);
            const currentFacility = JSON.parse(sessionStorage.getItem('currentFacility'));
            obj.facilityId = currentFacility.id;
            obj.category = this.getTypeArr(this.model.selectedPatientType);
            obj.prescriptionModel = this.getTypeArr(this.model.selectedMedicalUType);
            obj.ModelName = 'Prescription'; // irelevant sa nu crape la onSaveEv pe edit service
            const s = EntityStore.fromQuery(new EntityQuery('Prescription'));
            const op = this.model.id ? s.update(this.model.id, obj) : s.insert(obj);
            op.then((res: any) => {
                this.model.id = res.id || res.key;
                this.dsPrescriptionItems.forEach(element => {
                    element.prescriptionId = res.id || this.model.id;
                    const si = EntityStore.fromQuery(new EntityQuery('PrescriptionItem'));
                    const opi = element.id ? si.update(element.id, element) : si.insert(element);
                    opi.then((resItem: any) => {
                        element.id = resItem.id || resItem.key;
                    });
                });
                resolve(obj);
            });
        });
    }

    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            EntityStore.fromQuery(new EntityQuery('Prescription').eq('id', data.i_prescriptionId)).single().
                then((obj) => {
                    if (!obj) {
                        reject();
                    } else {
                        this.dsPrescriptionItems = [];
                        const q = new EntityQuery('PrescriptionItem').eq('prescriptionId', data.i_prescriptionId);
                        EntityStore.fromQuery(q).load().then((res: any) => {
                            if (res && res.data && res.data.length > 0) {
                                const arr = res.data;
                                for (let i = 0; i < arr.length; i++) {
                                    this.dsPrescriptionItems.push({
                                        ModelName: 'PrescriptionItems',
                                        id: arr[i].id,
                                        orderIndex: i + 1,
                                        name: arr[i].name,
                                        ds: arr[i].ds,
                                        quantity: arr[i].quantity,
                                        days: arr[i].days,
                                        refPrice: arr[i].refPrice,
                                        listDate: arr[i].listDate
                                    });
                                }
                            }
                        });
                        obj.hasReceipt = data.i_typeId === -3 ? true : false;
                        obj.selectedPatientType = this.setTypeArrDataSource(obj.category, true);
                        obj.selectedMedicalUType = this.setTypeArrDataSource(obj.prescriptionModel);
                        resolve(obj);
                    }
                });
        });
    }

    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('Invoice')).remove(id);
    }

    reset(): void {
        this.model.id = null;
    }

    calculateQuantity(quantity: number, fQuantity: number, qtyPerPackage: number) {
        const fc = (!fQuantity ? 0 : fQuantity) /
            (!qtyPerPackage ? 1 : qtyPerPackage);
        return quantity + fc;
    }
}
