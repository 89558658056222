import { Component, OnInit, ViewChild } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import { EditPersonService } from './edit-person/edit-person.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { Router } from '@angular/router';

@Component({
    selector: 'app-person',
    templateUrl: './person.component.html'
})
export class PersonComponent implements OnInit {

    @ViewChild('gridPerson', { static: false }) gridPerson: DxDataGridComponent;
    dsPerson: CustomStore;
    onEditPersonClick: any;

    constructor(public ui: UIService, public ds: DataService, public edit: EditPersonService, private router: Router) {
        if (!this.ds.isAdmin()) {
            this.router.navigateByUrl('/supply/receiving');
        }
    }

    ngOnInit() {
        if (!this.ds.auth.isAuth) { return; }
        this.dsPerson = this.ds.getPerson();
        this.onEditPersonClick = this.onEditPersonClickEv.bind(this);
    }

    onEditPersonClickEv(e: any) {
        e.event.preventDefault();
        this.edit.showPopup(e.row.data, true).then(() => {
            this.gridPerson.instance.refresh();
        }, (error) => this.ds.serverError(error)
        );
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: 'Adaugă persoană', icon: 'plus', onClick: (e: any) => {
                    this.edit.showPopup({}).then(() => {
                        this.gridPerson.instance.refresh();
                        this.edit.createDefault();
                    });
                }
            },
            location: 'after'
        });
    }

}
