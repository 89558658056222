import { Injectable } from '@angular/core';

@Injectable()
export class ReceivingService {

    dsItems: any = [];
    gridInstance: any;
    receivingData: any;
    productData: any = {};

    constructor() {
    }
}
