import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent, DxPopupComponent } from 'devextreme-angular';
import { EditInvoiceService } from './edit-invoice.service';
import { ProxyService } from 'src/app/services/proxy.service';


@Component({
  selector: 'app-edit-invoice',
  templateUrl: './edit-invoice.component.html'
})
export class EditInvoiceComponent implements OnInit, OnDestroy {
  dsUom: CustomStore;
  dsInvoiceItems: CustomStore;

  calculateDueDate: any;
  calcInvAndEstDate: any;
  onNumericDiscountChange: any;
  onPercentDiscountChange: any;
  calcTotalDiscount: any;
  calcTotalPrice: any;
  customizeSummary: any;
  quantityEditorOptions: any;

  partyInstance: any;
  addBtInst: any;
  editModelSubscription: any;


  @ViewChild('gridProduct', { static: false }) gridProduct: DxDataGridComponent;
  @ViewChild('popupInstance', { static: false }) popupInstance: DxPopupComponent;

  constructor(public ui: UIService, public ds: DataService, public edit: EditInvoiceService, public proxy: ProxyService) { }

  ngOnInit() {
    this.editModelSubscription = this.edit.modelChange.subscribe((modelChanged: boolean) => {
      if (modelChanged) {
        if (this.addBtInst) {
          this.addBtInst.option('visible', !this.edit.model.id && !this.edit.model.fromReceipt);
        }
      }
    });

    this.calculateDueDate = this.calculateDueDateEv.bind(this);
    this.calcInvAndEstDate = this.calcInvAndEstDateEv.bind(this);
    this.onNumericDiscountChange = this.onNumericDiscountChangeEv.bind(this);
    this.onPercentDiscountChange = this.onPercentDiscountChangeEv.bind(this);
    this.calcTotalDiscount = this.calcTotalDiscountEv.bind(this);
    this.calcTotalPrice = this.calcTotalPriceEv.bind(this);
    this.customizeSummary = this.customizeSummaryEv.bind(this);

    this.quantityEditorOptions = {
      format: 'fixedPoint',
      precision: 0
    };


  }

  ngOnDestroy() {
    if (this.editModelSubscription) {
      this.editModelSubscription.unsubscribe();
    }
  }

  onNumericDiscountChangeEv(ev: any) {
    if (ev.event) {
      this.edit.discountType = 1;
      const prevValue = ev.previousValue !== null ? ev.previousValue : 0;
      this.edit.calculateDiscounts(this.edit.discountType, 0, prevValue);
      this.gridProduct.instance.refresh();
    }
  }

  onPercentDiscountChangeEv(ev: any) {
    if (ev.event) {
      this.edit.discountType = 2;
      const prevValue = this.edit.model.discount !== null ? this.edit.model.discount : 0;
      this.edit.calculateDiscounts(this.edit.discountType, 0, prevValue);
      this.gridProduct.instance.refresh();
    }
  }

  customizeSummaryEv(e: any) {
    return this.edit.totalInvoiceWithDiscount.toFixed(2) + ' lei';
  }

  calcTotalPriceEv(row: any) {
    const tprice = (row.quantity + row.fQuantity / (!row.qtyPerPackage ? 1 : row.qtyPerPackage)) *
      row.pricePerPackage;
    return row ? this.ds.roundTo(tprice, 2) : null;
  }

  calcTotalDiscountEv(row) {
    const fq = row.fQuantity / (!row.qtyPerPackage ? 1 : row.qtyPerPackage);
    const discount = this.ds.roundTo(row.discount, 2);
    const res = (((row.quantity + fq) * row.pricePerPackage) - discount);
    return row ? this.ds.roundTo(res, 2) : null;
  }

  fractionCustomValidation = (options) => {
    if (options.data.fQuantity >= options.data.qtyPerPackage) {
      options.rule.message = 'Fracția nu poate fi mai mare ca numărul de comprimate / FA (' + options.data.qtyPerPackage + ').';
      return false;
    }
    if (options.value < 0) {
      options.rule.message = 'Fracția nu poate fi negativă.';
      return false;
    }
    return true;
  }

  checkTotalQuantityValidation = (options) => {
    if (!options.data.fQuantity && !options.data.quantity) {
      options.rule.message = 'Cantitatea sau fracția nu poate fi nulă.';
      return false;
    }
    return true;
  }


  quantityCustomValidation = (options) => {
    const min = options.data.qtyPerPackage === 1 ? 1 : 0;

    if (options.value < min || (!options.value && min === 1)) {
      options.rule.message = 'Cantitatea nu poate fi mai mică de ' + min + '.';
      return false;
    }

    return true;
  }

  stockQuantityValidation = (options) => {
    const selectedQuantity = this.edit.calculateQuantity(options.data.quantity, options.data.fQuantity, options.data.qtyPerPackage);
    if (options.data.quantityStock < selectedQuantity) {
      options.rule.message = 'Cantitate stoc depășită, cantitate maximă permisă: ' + options.data.quantityStock;
      return false;
    }
    return true;
  }

  onEditorPreparing(ev: any) {
    if (ev.parentType === 'dataRow' && ev.dataField === 'fQuantity') {
      if (ev.row.data.qtyPerPackage === 1) {
        ev.editorOptions.readOnly = true;
      }
    }
  }


  onSaveCell(e: any) {
    let quantity;
    if (e.newData) {
      if (e.newData.quantity === null) { e.newData.quantity = 0; }
      if (e.newData.fQuantity === null) { e.newData.fQuantity = 0; }
      if (e.newData.discount === null) { e.newData.discount = 0; }

      const item = this.edit.dsInvoiceItems.find(x => x.productId === e.oldData.productId && x.orderIndex === e.oldData.orderIndex);
      quantity = this.edit.calculateQuantity(e.oldData.quantity, e.oldData.fQuantity, e.oldData.qtyPerPackage);
      this.edit.totalInvoice -= e.oldData.pricePerPackage * quantity;
      this.edit.totalDiscount -= e.oldData.discount;
      this.edit.totalInvoiceWithDiscount = this.edit.totalInvoice - this.edit.totalDiscount;
      if (e.newData.discount || e.newData.quantity || e.newData.fQuantity) {
        if (e.newData.quantity) {
          const fquant = e.newData.fQuantity === 0 ? e.newData.fQuantity : item.fQuantity;
          quantity = this.edit.calculateQuantity(e.newData.quantity, fquant, item.qtyPerPackage);
          this.edit.calculateTotalAreaValues(quantity, item.pricePerPackage, item.discount);
        }
        if (e.newData.discount) {
          quantity = this.edit.calculateQuantity(item.quantity, item.fQuantity, item.qtyPerPackage);
          this.edit.calculateTotalAreaValues(quantity, item.pricePerPackage, e.newData.discount);
        }
        if (e.newData.fQuantity) {
          const qant = e.newData.quantity === 0 ? e.newData.quantity : item.quantity;
          quantity = this.edit.calculateQuantity(qant, e.newData.fQuantity, item.qtyPerPackage);
          if (e.newData.fQuantity >= item.qtyPerPackage) {
            e.newData.quantity = Math.floor(quantity);
            e.newData.fQuantity = e.newData.fQuantity - (Math.floor(e.newData.fQuantity / item.qtyPerPackage) * item.qtyPerPackage);
          }
          this.edit.calculateTotalAreaValues(quantity, item.pricePerPackage, item.discount);
        }
      } else if (e.newData.discount === 0 || e.newData.discount === null) {
        quantity = this.edit.calculateQuantity(item.quantity, item.fQuantity, item.qtyPerPackage);
        this.edit.calculateTotalAreaValues(quantity, item.pricePerPackage, (!e.newData.discount ? 0 : e.newData.discount));
      } else if (e.newData.fQuantity === 0 || e.newData.fQuantity === null) {
        quantity = this.edit.calculateQuantity(item.quantity, e.newData.fQuantity, item.qtyPerPackage);
        this.edit.calculateTotalAreaValues(quantity, item.pricePerPackage, item.discount);
      } else if (e.newData.quantity === 0 || e.newData.quantity === null) {
        quantity = this.edit.calculateQuantity(e.newData.quantity, item.fQuantity, item.qtyPerPackage);
        this.edit.calculateTotalAreaValues(quantity, item.pricePerPackage, item.discount);
      }
    }
  }

  onRowRemoved(e: any) {
    if (e.data) {
      if (this.edit.dsInvoiceItems.length === 0) {
        this.edit.initTotal();
        this.edit.model.discount = 0;
        this.edit.model.percentDiscount = 0;
      } else {
        const quantity = this.edit.calculateQuantity(e.data.quantity, e.data.fQuantity, e.data.qtyPerPackage);
        this.edit.totalInvoice -= e.data.pricePerPackage * quantity;
        this.edit.totalDiscount -= e.data.discount;
        this.edit.totalInvoiceWithDiscount = this.edit.totalInvoice - this.edit.totalDiscount;
        this.edit.calculateFormDiscount(this.edit.discountType);
        const ds = this.edit.dsInvoiceItems;
        for (let i = 0; i < ds.length; i++) {
          ds[i].orderIndex = i + 1;
        }
      }
    }
  }

  onPartyInit(event: any) {
    this.partyInstance = event.component;
  }


  openPartyAdd(event: any) {
    this.partyInstance.close();
    if (this.edit.model.partyType === 1) {
      this.proxy.editCompany.showPopup(null).then(() => {
        if (this.partyInstance) {
          // this.partyInstance.getDataSource().reload();
          this.edit.initPartyDs(this.edit.model.partyType);
        }
      });
    } else {
      this.proxy.editPerson.showPopup(null).then(() => {
        this.edit.initPartyDs(this.edit.model.partyType);
      });
    }

    event.event.preventDefault();
    event.event.stopPropagation();
  }

  calculateDueDateEv(event: any) {
    if (event.event && this.edit.model.date && this.edit.model.dueDays) {
      this.edit.model.dueDate = this.ds.addDaysToDate(
        this.clearDateTime(this.edit.model.date), this.edit.model.dueDays);
    }
  }

  calcInvAndEstDateEv(event: any) {
    if (event.event) {
      this.edit.model.dueDays = this.calcDueDays(this.clearDateTime(event.value));
    }
  }

  clearDateTime(val: any) {
    const y = new Date(val).getFullYear(),
      m = new Date(val).getMonth(),
      d = new Date(val).getDate();
    return new Date(y, m, d, 0, 0, 0, 0);
  }

  calcDueDays(val: any) {
    const diffTime = Math.abs(new Date(val).getTime() - new Date(this.edit.model.date).getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  toolbarPreparing(e: any) {
    this.ui.prepareToolbar(e, true);
    e.toolbarOptions.items.unshift({
      widget: 'dxButton',
      location: 'after',
      options: {
        text: 'Adaugă articol',
        visible: !this.edit.model.id && !this.edit.model.fromReceipt,
        icon: 'plus',
        onClick: () => {
          if (this.proxy.globalSearch.gridInstance) {
            this.proxy.globalSearch.initGlobalSearch();
          }
          this.proxy.globalSearch.showPopup({ type: 'stock' }, true).then((data) => {
            if (data) {
              this.proxy.editInvoice.addProduct(data, true);
            }
          });
        },
        onInitialized: (ev: any) => { this.addBtInst = ev.component; }
      }
    });
  }

}
