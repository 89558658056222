import { Component, OnInit } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import { EditFidelityPersonService } from './edit-fidelity-person.service';

@Component({
  selector: 'app-edit-fidelity-person',
  templateUrl: './edit-fidelity-person.component.html'
})
export class EditFidelityPersonComponent implements OnInit {
  dsFidelityType: any;

  constructor(public ui: UIService, public ds: DataService, public edit: EditFidelityPersonService) { }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.dsFidelityType = this.ds.getEnumValue('FIDELITY_TYPE');
  }

}
