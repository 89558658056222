import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent } from 'devextreme-angular';
import { EditOrderService } from './edit-order/order-edit.service';
import { confirm } from 'devextreme/ui/dialog';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html'
})
export class OrderComponent implements OnInit {

  dsOrder: CustomStore;
  dsOrderType: CustomStore;
  dsFacility: any;
  dsOrderStatus: { id: number; name: string; }[];
  dsPartyTo: CustomStore;
  dsPartyFrom: CustomStore;
  dsCurrency: CustomStore;
  dsContactMech: any;

  @ViewChild('gridOrder', { static: false }) gridOrder: DxDataGridComponent;
  @ViewChild('gridDetail', { static: false }) gridDetail: DxDataGridComponent;
  // onEditOrderClick: any;
  // onDeleteOrderClick: any;
  dseditOrderItem: CustomStore;
  dsProduct: CustomStore;
  dsTaxType: CustomStore;

  constructor(public ds: DataService, public ui: UIService, public edit: EditOrderService) { }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.dsOrder = this.ds.getOrder();
    if (!this.ds.currentFacility) {
      this.ds.isFacilityLoaded.subscribe(() => {
        this.dsOrder = this.ds.getOrder();
      });
    } else {
      this.dsOrder = this.ds.getOrder();
    }


    // this.dsOrderType = this.ds.getEnumValue('ORDER_TYPE');
    // this.dsCurrency = this.ds.getEnumValue('CURRENCY');
    // this.dsContactMech = this.ds.getEnumValue('MECH');
    this.dsFacility = this.ds.getFacility();
    this.dsPartyFrom = this.ds.getParty(1);

    // this.onEditOrderClick = this.onEditOrderClickEv.bind(this);
    // this.onDeleteOrderClick = this.onDeleteOrderClickEv.bind(this);

    this.dsProduct = this.ds.getProduct();
    this.dsTaxType = this.ds.getEnumValue('TAXID');
  }

  onInitDetail(event: any, data: any) {
    if (event.component.option('dataSource') == null) {
      event.component.option('dataSource', this.ds.getOrderItemView(data.data.id));
    }
  }

  onDeleteOrderClick(e: any) {
    this.edit.remove(e.row.data.id).then(() => {
      notify('Succes', 'success', 2000);
      this.gridOrder.instance.refresh();
    });
  }

  onEditOrderClick(e: any) {
    this.edit.showPopup(e.row.data.id, true).then(
      () => {
        this.gridOrder.instance.refresh();
      },
      (error) => this.ds.serverError(error)
    );
  }

  onFinishOrderClick(e: any) {
    confirm('Ești sigur că vrei să marchezi această comandă ca fiind finalizată ?', 'Confirmare').
      then((val: boolean) => {
        if (val) {
          EntityStore.fromQuery(new EntityQuery('OrderHeader')).update(e.row.data.id, { statusId: 2 }).then(() => {
            notify('Succes', 'success', 2000);
            this.gridOrder.instance.refresh();
          });
        }
      });
  }

  toolbarPreparing(e: any) {
    this.ui.prepareToolbar(e, true);
    e.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        text: 'Adaugă comandă',
        icon: 'plus',
        onClick: () => {
          this.edit.dsOrderItems = [];
          this.edit.dsInitialOrderItems = [];
          this.edit.showPopup({ facilityId: this.ds.currentFacility.id, date: new Date() }, false).then(() => {
            this.gridOrder.instance.refresh();
          });
        }
      },
      location: 'after'
    });
  }

}
