import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import { EditPriceChangeService } from './edit-price-change.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { EntityStoreOptions } from '@dohu/ibis-entity';

@Component({
  selector: 'app-price-change',
  templateUrl: './price-change.component.html'
})
export class PriceChangeComponent implements OnInit {

  dsPriceChange: CustomStore;
  dsPriceChangeItem: CustomStore;
  dsFacility: CustomStore;
  priceChangeId: string;

  @ViewChild('gridPriceChange', { static: false }) gridPriceChange: DxDataGridComponent;

  constructor(public ds: DataService, public ui: UIService, public edit: EditPriceChangeService) {
    this.dsPriceChange = this.ds.getPriceChange();
    this.dsFacility = this.ds.getFacility();
  }

  ngOnInit() {
  }

  toolbarPreparing(event: any) {
    this.ui.prepareToolbar(event, true);
    event.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        hint: 'Adaugă',
        icon: 'plus',
        text: 'Adaugă proces verbal',
        onClick: (e: any) => this.edit.showPopup(null)
          .then(() => this.gridPriceChange.instance.refresh(), () => { })
      },
      location: 'after'
    });
  }

  prepareDetailToolbar(event: any) {
    this.ui.prepareToolbar(event, true);
    event.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        hint: 'Descarcă proces verbal',
        icon: 'doc',
        onClick: (e: any) => this.onDownloadRaportClick()
      },
      location: 'after'
    });
  }
  onPriceChangeExpandEv(event: any) {
    this.priceChangeId = event.key;
    this.dsPriceChangeItem = this.ds.getPriceChangeItemById(this.priceChangeId);
  }

  onDownloadRaportClick() {
    this.ds.getFile(EntityStoreOptions.DefaultServer + 'DownloadPriceChangeReport' + '/' + this.priceChangeId);
  }
}
