import { Component, OnInit } from '@angular/core';
import { EditReceivingItemService } from './edit-receiving-item.service';
import { UIService } from 'src/app/services';
import { ReceivingService } from '../receiving.service';


@Component({
    selector: 'app-receiving-item-edit',
    templateUrl: './edit-receiving-item.component.html'
})
export class EditReceivingItemComponent implements OnInit {

    onQuantityChanged: any;
    onFractionChanged: any;
    onAdaosChanged: any;
    onSalePriceChanged: any;
    onQuantityPriceChanged: any;

    constructor(public edit: EditReceivingItemService, public ui: UIService, public receiving: ReceivingService) { }

    ngOnInit() {
        this.onQuantityChanged = this.onQuantityChangedEv.bind(this);
        this.onFractionChanged = this.onFractionChangedEv.bind(this);
        this.onQuantityPriceChanged = this.onQuantityPriceChangedEv.bind(this);
        this.onAdaosChanged = this.onAdaosChangedEv.bind(this);
        this.onSalePriceChanged = this.onSalePriceChangedEv.bind(this);
    }

    fractionValidation = (options) => {
        if (options.value >= this.edit.model.qtyPerPackage) {
            options.rule.message = 'Fracția nu poate fi mai mare sau egală cu Comp / FA.';
            return false;
        }
        return true;
    }

    totalQuantityValidation = (options) => {
        if (this.edit.model.quantity === 0 && this.edit.model.fQuantity === 0) {
            options.rule.message = 'Cantitatea sau fracția nu poate fi nulă.';
            return false;
        }
        return true;
    }

    checkFraction() {
        if (this.edit.model.fQuantity >= this.edit.model.qtyPerPackage) {
            return 'red';
        } else {
            return 'white';
        }
    }

    onQuantityPress(ev: any) {
        const code = ev.event.charCode;
        if (code === 46 || code === 44 || code === 45 || code === 101 || code === 69) {
            ev.event.preventDefault();
            ev.event.stopPropagation();
        }
    }

    onQuantityChangedEv(ev: any) {
        if (ev.event) {
            if (ev.value === null) { ev.component.option('value', this.edit.model.qtyPerPackage === 1 ? 1 : 0); }
            const fc = (!this.edit.model.fQuantity ? 0 : this.edit.model.fQuantity) /
                (!this.edit.model.qtyPerPackage ? 1 : this.edit.model.qtyPerPackage);
            this.edit.model.totalQuantity = this.roundTo((ev.value + fc), 2);
        }
    }

    onFractionChangedEv(ev: any) {
        if (ev.event) {
            if (ev.value === null) { ev.component.option('value', 0); }
            const fc = (!ev.value ? 0 : ev.value) /
                (!this.edit.model.qtyPerPackage ? 1 : this.edit.model.qtyPerPackage);
            this.edit.model.totalQuantity = this.roundTo((this.edit.model.quantity + fc), 2);
            this.edit.model.quantity += Math.floor(fc);
            this.edit.model.fQuantity = ev.value - (Math.floor(fc) * this.edit.model.qtyPerPackage);
        }
    }

    onQuantityPriceChangedEv(ev: any) {
        if (ev.value) {
            const tvaFa = Math.round(this.edit.model.unitCost * this.edit.model.taxPercent) / 100;
            const ttP = this.edit.model.unitCost + tvaFa;
            this.edit.model.pricePerPackage = this.roundTo((ttP + (ttP * this.edit.model.adaosCom / 100)), 2);
        }
    }

    onAdaosChangedEv(ev: any) {
        if (ev.event) {
            const tvaFa = Math.round(this.edit.model.unitCost * this.edit.model.taxPercent) / 100;
            const ttP = this.edit.model.unitCost + tvaFa;
            this.edit.model.pricePerPackage = this.roundTo((ttP + (ttP * this.edit.model.adaosCom / 100)), 2);
        }
    }

    onSalePriceChangedEv(ev: any) {
        if (ev.event) {
            const tvaFa = Math.round(this.edit.model.unitCost * this.edit.model.taxPercent) / 100;
            const buyPrice = this.edit.model.unitCost + tvaFa;
            this.edit.model.adaosCom = this.roundTo(((this.edit.model.pricePerPackage - buyPrice) / buyPrice * 100), 2) || 0;
        }
    }

    roundTo(n, digits) {
        let negative = false;
        if (digits === undefined) {
            digits = 0;
        }
        if (n < 0) {
            negative = true;
            n = n * -1;
        }
        const multiplicator = Math.pow(10, digits);
        n = parseFloat((n * multiplicator).toFixed(11));
        n = parseFloat((Math.round(n) / multiplicator).toFixed(2));
        if (negative) {
            n = (n * -1).toFixed(2);
            n = parseFloat(n);
        }
        return n;
    }

}

