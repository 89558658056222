import { Component, OnInit } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import { EditProductService } from '../../../shared/edit-product/edit-product.service';
import { confirm } from 'devextreme/ui/dialog';

@Component({
    selector: 'app-unprocessed',
    templateUrl: './unprocessed.component.html',
    styleUrls: ['./unprocessed.component.css']
})
export class UnprocessedComponent implements OnInit {
    dsUnprocessed: CustomStore;
    isPrincipal = false;

    constructor(public ui: UIService, public ds: DataService, public edit: EditProductService) { }

    ngOnInit() {
        if (!this.ds.auth.isAuth) { return; }
        this.dsUnprocessed = this.ds.getUnprocessed();
        this.ds.isPricipal().then((response: any) => {
            this.isPrincipal = response === 'YES';
        });
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);
        event.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: 'Adaugă produs', icon: 'plus',
                onClick: (e: any) => {
                    this.edit.showPopup({ appId: this.ds.auth.companyId }, true).then((res: any) => {
                        this.edit.createDefault();
                        if (res && res.isSaving) {
                            event.component.refresh();
                        }
                    });
                }
            },
            location: 'after'
        });
    }

    promoteProduct = (ev: any) => {
        confirm('Ești sigur că vrei să dai spre promovare acest produs ?', 'Confirmare').
            then((val: boolean) => {
                if (val) {
                    this.ds.setToProcessed(ev.row.data.id).then(() => {
                        ev.component.refresh();
                    });
                }
            });
    }

    editProduct = (ev: any) => {
        const obj = JSON.parse(JSON.stringify(ev.row.data));
        this.edit.showPopup(obj, true).then((res: any) => {
            this.edit.createDefault();
            if (res && res.isSaving) {
                ev.component.refresh();
            }
        });
    }
}
