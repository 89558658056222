import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { DxMultiViewComponent, DxPopupComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { DataService } from '../services';
import { EntityQuery, EntityStore } from '@dohu/ibis-entity';
import { iBisAuthService } from '@dohu/ibis-auth';
import CustomStore from 'devextreme/data/custom_store';

@Component({
    selector: 'app-init-app',
    templateUrl: './init-app.component.html'
})

export class InitAppComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() isInitAppVisible: boolean;
    @Input() initOptions: any;
    @Output() closePopup = new EventEmitter<any>();
    multiViewItems: any;
    dsFacilityType: CustomStore;

    wizardState: number;

    goToNext: any;
    onFinish: any;
    wizardValidationArr: string[];
    wizardValidation: any;
    phoneNumberValidation: any;
    homePageValidation: any;
    wizardData: any = {};


    @ViewChild('multiview', { static: false }) multiview: DxMultiViewComponent;
    @ViewChild('popupInitApp', { static: false }) popupInitApp: DxPopupComponent;

    constructor(public ds: DataService, public auth: iBisAuthService) {
        this.goToNext = this.gotoNextEv.bind(this);
        this.onFinish = this.onFinishEv.bind(this);
    }

    ngOnInit() {
        this.dsFacilityType = this.ds.getEnumValue('FACILITY');
        // tslint:disable-next-line: max-line-length
        this.homePageValidation = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        // tslint:disable-next-line: max-line-length
        this.phoneNumberValidation = /^(?:(?:(?:00\s?|\+)40\s?|0)(?:7\d{2}\s?\d{3}\s?\d{3}|(21|31)\d{1}\s?\d{3}\s?\d{3}|((2|3)[3-7]\d{1})\s?\d{3}\s?\d{3}|(8|9)0\d{1}\s?\d{3}\s?\d{3}))$/;

        this.multiViewItems = new DataSource({
            store: [{
                title: 'Configurare companie'
            }, {
                title: 'Configurare punct de lucru'
            }, {
                title: 'Finalizare'
            }]
        });
    }

    ngAfterViewInit() {
        this.wizardValidationArr = [undefined, 'companyValidation', 'facilityValidation', undefined];
        setTimeout(() => {
            this.initMultiView();
        }, 0);
    }

    ngOnChanges(changes: any) {
        if (this.isInitAppVisible) {
            this.initMultiView();
        }
    }

    resetSlide() {
        const current = this.multiview.instance.option('selectedIndex');
        this.wizardState = current + 1;
        this.multiview.instance.option('selectedIndex', current + 1);
        this.wizardValidation = this.wizardValidationArr[this.wizardState];
        this.wizardData = {};

        if (this.wizardState === 1 && this.initOptions.hasCompany) {
            this.resetSlide();
            return;
        }

        switch (this.wizardState) {
            case 1:
                this.wizardData.legalName = this.auth.companyName;
                break;
            case 2:
                // temporar
                if (this.ds.currentFacility && this.ds.currentFacility.id) {
                    this.resetSlide();
                }
                break;
        }
    }

    gotoNextEv(event: any) {
        event.event.preventDefault();
        // this.popupInitApp.instance.option('height', '100%');
        if (event.validationGroup) {
            const validation = event.validationGroup.validate();
            if (!validation.isValid) {
                notify('Trebuie să completați toate câmpurile obligatorii.', 'error', 3000);
                return;
            }
        }
        if (this.wizardState === 0) {
            setTimeout(() => {
                this.resetSlide();
            }, 0);
        } else if (this.wizardState === 1) {
            EntityStore.fromQuery(new EntityQuery('Company')).insert(this.wizardData).then((data: any) => {
                EntityStore.fromQuery(new EntityQuery('Party')).insert({ typeId: 3, id: data.id })
                    .then(() => {
                        notify('Companie adăugată cu succes!', 'success', 3000);
                        setTimeout(() => {
                            this.resetSlide();
                        }, 0);
                    });
            });
        } else if (this.wizardState === 2) {
            this.wizardData.openDate = new Date();
            EntityStore.execute('AddFacility', this.wizardData).then((res) => {
                notify('Punct de lucru adăugat cu succes!', 'success', 3000);
                setTimeout(() => {
                    this.resetSlide();
                }, 0);
            });
        }
    }

    onFinishEv(event: any) {
        event.event.preventDefault();
        this.closeWizard(event);
        this.ds.redirectTo('/sales/receipt');
    }

    closeWizard(event: any) {
        this.isInitAppVisible = false;
        this.wizardState = -1;
        this.closePopup.emit(this.isInitAppVisible);
    }

    initMultiView() {
        if (this.initOptions) {
            this.wizardState = 0;
            setTimeout(() => {
                if (this.multiview) {
                    this.wizardValidation = this.wizardValidationArr[this.wizardState];
                    this.multiview.instance.option('selectedIndex', this.wizardState);
                }
            }, 0);
        }
    }

}
