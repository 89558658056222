import { Component, OnInit, ViewChild } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';
import { DxDataGridComponent } from 'devextreme-angular';
import { ProxyService } from 'src/app/services/proxy.service';
import { EntityStoreOptions } from '@dohu/ibis-entity';
import { custom } from 'devextreme/ui/dialog';

@Component({
  selector: 'app-sales-invoice',
  templateUrl: './sales-invoice.component.html'
})
export class SalesInvoiceComponent implements OnInit {

  dsInvoiceView: CustomStore;
  dsInvoiceItems: CustomStore;
  customDialog: any;

  @ViewChild('gridSalesInvoice', { static: false }) gridSalesInvoice: DxDataGridComponent;

  onEditInvoiceClick: (e: any) => void;
  onDeleteInvoiceClick: (e: any) => void;

  onViewInvoiceClick: any;
  onDownloadClick: any;
  calcTotalDiscount: any;
  calcTotalPrice: any;
  customizeSummary: any;
  totalToPay: any;

  constructor(public ui: UIService, public ds: DataService, public proxy: ProxyService) {

  }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    if (!this.ds.currentFacility) {
      this.ds.isFacilityLoaded.subscribe(() => {
        this.dsInvoiceView = this.ds.getInvoiceView(false);
      });
    } else {
      this.dsInvoiceView = this.ds.getInvoiceView(false);
    }
    this.onEditInvoiceClick = this.onEditInvoiceClickEv.bind(this);
    this.onDeleteInvoiceClick = this.onDeleteInvoiceClickEv.bind(this);
    this.onViewInvoiceClick = this.onViewInvoiceClickEv.bind(this);
    this.calcTotalDiscount = this.calcTotalDiscountEv.bind(this);
    this.calcTotalPrice = this.calcTotalPriceEv.bind(this);
    this.customizeSummary = this.customizeSummaryEv.bind(this);
    this.onDownloadClick = this.onDownloadClickEv.bind(this);
  }

  onViewInvoiceClickEv(e: any) {
    e.event.preventDefault();
    this.proxy.editInvoice.disableGlobalDiscount = true;
    this.proxy.editInvoice.showPopup(e.row.data, true).then(() => {
      this.gridSalesInvoice.instance.refresh();
    });
  }

  onDownloadClickEv(ev: any) {
    this.ds.getFile(EntityStoreOptions.DefaultServer + 'DownloadSaleInvoice' + '/' + ev.row.key);
  }

  onGridInitialized(row: any) {
    this.dsInvoiceItems = this.ds.getInvoiceItems(row.data.i_id);
  }

  onInvoiceExpand(event: any) {
    event.component.collapseAll(-1);
  }

  onDeleteInvoiceClickEv(e: any) {
    e.event.preventDefault();
    this.proxy.editInvoice.remove(e.row.data.i_id).then(() => {
      notify('Success', 'success', 2000);
      this.gridSalesInvoice.instance.refresh();
    });
  }

  onRowClick(ev: any) {
    if (ev.data) {
      this.totalToPay = ev.data.ii_totalPrice;
    }
  }

  onEditInvoiceClickEv(e: any) {
    e.event.preventDefault();
    this.proxy.editInvoice.dsInvoiceItems = this.ds.getInvoiceItems(e.row.data.i_id);
    this.proxy.editInvoice.showPopup(e.row.data, true).then(
      () => {
        this.proxy.editInvoice.dsInvoiceItems = this.ds.getInvoiceItems();
        this.gridSalesInvoice.instance.refresh();
      }, (error) => this.ds.serverError(error)
    );
  }

  customizeSummaryEv(e: any) {
    const val = this.totalToPay || e.value;
    return val.toFixed(2) + ' lei';
  }

  calcTotalDiscountEv(row: any) {
    return row ? this.ds.roundTo(((row.ii_quantity * row.ii_pricePerPackage) - row.ii_discount), 2) : null;
  }

  calcTotalVal = (row: any) => {
    return row ? this.ds.roundTo(row.totalPrice + row.ii_discount, 2) : null;
  }

  calcTotalPriceEv(row: any) {
    return row.ii_totalPrice ? this.ds.roundTo(row.ii_totalPrice - (row.i_discount || 0), 2) : null;
  }

  initDialog() {
    this.customDialog = custom({
      title: 'Confirmare',
      messageHtml: 'Doriți întocmirea unei facturi pe persoană fizică sau juridică ?',
      buttons: [{
        text: 'Persoană fizică',
        onClick: () => {
          return true;
        }
      },
      {
        text: 'Persoană juridică',
        onClick: () => {
          return false;
        }
      },
      ]
    });
  }

  toolbarPreparing(event: any, grid: any) {
    this.ui.prepareToolbar(event, true);
    const dueDate = this.ds.addDaysToDate(new Date(), 30);
    if (grid === 'master') {
      event.toolbarOptions.items.unshift({
        widget: 'dxButton',
        options: {
          text: 'Adaugă factură',
          icon: 'plus',
          onClick: (e: any) => {
            this.initDialog();
            this.customDialog.show().then((val: boolean) => {
              if (val) {
                this.proxy.editInvoice.createDefault();
                this.proxy.editInvoice.initPartyDs(0);
                this.proxy.editInvoice.showPopup(
                  {
                    fromReceipt: false, date: new Date(), typeId: -2, dueDays: 30, dueDate: dueDate,
                    discount: 0, facilityId: this.ds.currentFacility.id, partyType: 0,
                  }).then(
                    () => {
                      this.gridSalesInvoice.instance.refresh();
                    });
              } else {
                this.proxy.editInvoice.createDefault();
                this.proxy.editInvoice.initPartyDs(1);
                this.proxy.editInvoice.showPopup(
                  {
                    fromReceipt: false, date: new Date(), typeId: -2, dueDays: 30, dueDate: dueDate,
                    discount: 0, facilityId: this.ds.currentFacility.id, partyType: 1,
                  }).then(
                    () => {
                      this.gridSalesInvoice.instance.refresh();
                    });
              }
            });

          }
        },
        location: 'after'
      });
    }
  }
}
