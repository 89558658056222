import { Injectable } from '@angular/core';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services';

@Injectable()
export class EditProductService extends iBisEditService {
    dsBooleans: any = [];
    selectedBooleans: any = [];
    characters: string;
    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'productValidation';
        this.characters = '';
    }

    createDefault() {
        document.removeEventListener('keydown', this.onKeyDown);
        return {};
    }

    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            document.addEventListener('keydown', this.onKeyDown);
            this.setBooleansData(data);
            resolve(data);
        });
    }

    onKeyDown = (e: any) => {
        setTimeout(() => {
            this.characters = '';
        }, 1000);
        if ((e.keyCode === 17 || e.keyCode === 74) && e.ctrlKey === true) {
            e.preventDefault();
            this.model.barCode = this.characters.length >= 8 ? this.characters : this.model.barCode;
        }
        const except = ((e.keyCode === 17 || e.keyCode === 74) && e.ctrlKey === true) || e.keyCode === 13;
        if (!except) {
            this.characters = this.characters + e.key;
        }
    }

    onRemove(id: string): Promise<void> {
        return new Promise((resolve) => { });
    }

    setBooleansData(data: any) {
        this.selectedBooleans = [];
        this.dsBooleans = [{ value: data.isNarcotic || false, name: 'Narcotic', fieldName: 'isNarcotic' },
        { value: data.isFractional || false, name: 'Fracțional', fieldName: 'isFractional' },
        { value: data.isSpecial || false, name: 'Special', fieldName: 'isSpecial' },
        { value: data.isBrand || false, name: 'Brand', fieldName: 'isBrand' },
        { value: data.hasBioEchiv || false, name: 'Bio', fieldName: 'hasBioEchiv' }];

        for (let i = 0; i < this.dsBooleans.length; i++) {
            if (this.dsBooleans[i].value) {
                this.selectedBooleans.push(this.dsBooleans[i].fieldName);
            }
        }
    }

    getBooleansData(data: any) {
        this.dsBooleans.forEach((boolean: any) => {
            data[boolean.fieldName] = boolean.value;
        });
        return data;
    }

    onSaveEv(): Promise<any> {
        return new Promise((resolve) => {
            const p = EntityStore.fromQuery(new EntityQuery('Product'));
            this.model = this.getBooleansData(this.model);
            const r = this.model.id ? p.update(this.model.id, this.model) : p.insert(this.model);
            r.then(d => {
                this.model.isSaving = true;
                resolve(this.model);
            });
        });
    }

    reset(): void {
        this.model = {};
    }
}

