import { Component, OnInit, ViewChild } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { EntityStore } from '@dohu/ibis-entity';

@Component({
    selector: 'app-reservation',
    templateUrl: './reservation.component.html'
})
export class ReservationComponent implements OnInit {

    dsReservationView: CustomStore;
    dsReservationItems: CustomStore;

    constructor(public ui: UIService, public ds: DataService) { }

    ngOnInit() {
        this.dsReservationView = this.ds.getReservation();
    }

    onReservationClick = (ev: any) => {
        EntityStore.execute('ApproveInventoryReservation', { id: ev.row.data.ir_id }).then((response: any) => {
            if (response === 'OK') {
                notify('Succes.', 'success', 3000);
                ev.component.refresh();
            }
        }, error => {
            if (error === 'E101') {
                notify('Stoc insuficient pentru produsul selectat.', 'warning', 3000);
            }
        });
    }

    onDeleteClick = (ev: any) => {
        confirm('Ești sigur că vrei să ștergi această înregistrare?', 'Confirmare').
            then((val: boolean) => {
                if (val) {
                    if (!ev.row.data.iv_inventoryId) {
                        this.ds.deleteReservation(ev.row.data.ir_id).then(() => {
                            notify('Înregistrare ștearsă cu succes.', 'success', 3000);
                            ev.component.refresh();
                        });
                    } else {
                        EntityStore.execute('RemoveInventoryReservation', { id: ev.row.data.ir_id }).then((response: any) => {
                            if (response === 'OK') {
                                notify('Succes.', 'success', 3000);
                                ev.component.refresh();
                            }
                        }, error => {
                                notify('Eroare la efectuarea ștergerii.', 'error', 3000);
                        });
                    }

                }
            });
    }

    checkApproveCondition = (ev: any) => {
        return !ev.row.data.iv_inventoryId;
    }

    toolbarPreparing(event: any, grid: any) {
        this.ui.prepareToolbar(event, true);
        if (grid === 'master') {
            //   event.toolbarOptions.items.unshift({
            //     widget: 'dxButton',
            //     options: {
            //       text: 'Adaugă rezervare',
            //       icon: 'plus',
            //     //   onClick: (e: any) => {
            //     //     this.proxy.editInvoice.createDefault();
            //     //     this.proxy.editInvoice.showPopup(
            //     //       {
            //     //         fromReceipt: false, date: new Date(), typeId: -2, dueDays: 30, dueDate: dueDate,
            //     //         discount: 0, facilityId: this.ds.currentFacility.id
            //     //       }).then(
            //     //         () => {
            //     //           this.gridSalesInvoice.instance.refresh();
            //     //         });
            //     //   }
            //     },
            //     location: 'after'
            //   });
        }
    }

}
