import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { iBisAuthService, iBisConfirmInviteComponent, iBisResetPasswordComponent } from '@dohu/ibis-auth';
import { LoginFormComponent } from './shared/login/login-form.component';
import { OrderComponent } from './view/supply/order/order.component';
import { SupplyInvoiceComponent } from './view/supply/supply-invoice/supply-invoice.component';
import { ReceivingComponent } from './view/supply/receiving/receiving.component';
import { StockComponent } from './view/stock/stock/stock.component';
import { TransferComponent } from './view/stock/transfer/transfer.component';
import { PriceChangeComponent } from './view/stock/price-change/price-change.component';
import { WithdrawalComponent } from './view/stock/withdrawal/withdrawal.component';
import { VarianceInComponent } from './view/stock/variance-in/variance-in.component';
import { VarianceOutComponent } from './view/stock/variance-out/variance-out.component';
import { PhysicalInventoryComponent } from './view/stock/physical-inventory/physical-inventory.component';
import { RegisterComponent } from './view/register/register.component';
import { ReceiptComponent } from './view/sales/receipt/receipt.component';
import { SalesInvoiceComponent } from './view/sales/sales-invoice/sales-invoice.component';
import { CashRegisterComponent } from './view/sales/cash-register/cash-register.component';
import { PromoComponent } from './view/sales/promo/promo.component';
import { ActiveSubstanceComponent } from './view/nomenclature/active-substance/active-substance.component';
import { DrugsComponent } from './view/nomenclature/drugs/drugs.component';
import { FidelityCardComponent } from './view/admin/fidelity-card/fidelity-card.component';
import { EnumGeneralComponent } from './view/admin/enumGeneral/enumGeneral.component';
import { CompanyComponent } from './view/admin/company/company.component';
import { FacilityComponent } from './view/admin/facility/facility.component';
import { PersonComponent } from './view/admin/person/person.component';
import { MedicComponent } from './view/nomenclature/medic/medic.component';
import { ProfileComponent } from './view/profile/profile.component';
import { UserComponent } from './view/admin/user/user.component';
import { ReturnProductComponent } from './view/supply/return/return-product.component';
import { InventoryHistoryComponent } from './view/stock/inventory-history/inventory-history.component';
import { CompanyDetailsComponent } from './view/company-details/company-details.component';
import { UnprocessedComponent } from './view/nomenclature/unprocessed/unprocessed.component';
import { ParapharmaceuticalComponent } from './view/nomenclature/parapharmaceutical/parapharmaceutical.component';
import { CashRegisterKitComponent } from './view/admin/cash-register-kit/cash-register-kit.component';
import { ReservationComponent } from './view/sales/reservation/reservation.component';
import { ExpiredProductComponent } from './view/report/expired-product/expired-product.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }, {
    path: 'sales', children: [
      {
        path: 'receipt',
        component: ReceiptComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'invoice',
        component: SalesInvoiceComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'cashRegister',
        component: CashRegisterComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'promo',
        component: PromoComponent,
        canActivate: [iBisAuthService]
      },
      {
        path: 'reservation',
        component: ReservationComponent,
        canActivate: [iBisAuthService]
      }
    ]
  }, {
    path: 'stock', children: [
      {
        path: 'current',
        component: StockComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'history',
        component: InventoryHistoryComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'transfer',
        component: TransferComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'priceChange',
        component: PriceChangeComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'withdrawal',
        component: WithdrawalComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'varianceIn',
        component: VarianceInComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'varianceOut',
        component: VarianceOutComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'physicalInventory',
        component: PhysicalInventoryComponent,
        canActivate: [iBisAuthService]
      }
    ]
  }, {
    path: 'supply', children: [
      {
        path: 'order',
        component: OrderComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'invoice',
        component: SupplyInvoiceComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'receiving',
        component: ReceivingComponent,
        canActivate: [iBisAuthService]
      },
      {
        path: 'return',
        component: ReturnProductComponent,
        canActivate: [iBisAuthService]
      }
    ]
  }, {
    path: 'report', children: [
      {
        path: 'expired',
        component: ExpiredProductComponent,
        canActivate: [iBisAuthService]
      }
    ]
  }, {
    path: 'nomenclature', children: [
      {
        path: 'activeSubstance',
        component: ActiveSubstanceComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'drugs',
        component: DrugsComponent,
        canActivate: [iBisAuthService]
      },
      {
        path: 'unprocessed',
        component: UnprocessedComponent,
        canActivate: [iBisAuthService]
      },
      {
        path: 'parapharmaceutical',
        component: ParapharmaceuticalComponent,
        canActivate: [iBisAuthService]
      },
      {
        path: 'medic',
        component: MedicComponent,
        canActivate: [iBisAuthService]
      }
    ]
  }, {
    path: 'admin', children: [
      {
        path: 'enumGeneral',
        component: EnumGeneralComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'company',
        component: CompanyComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'person',
        component: PersonComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'facility',
        component: FacilityComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'fidelityCard',
        component: FidelityCardComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'user',
        component: UserComponent,
        canActivate: [iBisAuthService]
      }, {
        path: 'kit',
        component: CashRegisterKitComponent,
        canActivate: [iBisAuthService]
      }
    ]
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'login',
    component: LoginFormComponent
  },
  {
    path: 'company',
    component: CompanyDetailsComponent
  },
  {
    path: 'register',
    component: RegisterComponent,
  }, {
    path: 'confirmInvite',
    component: iBisConfirmInviteComponent,
    data: [{ returnUrl: '/login' }]
  }, {
    path: 'resetPassword',
    component: iBisResetPasswordComponent,
    data: [{ title: 'Resetează parola', isReset: true, returnUrl: '/login' }]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, enableTracing: false })],
  providers: [iBisAuthService],
  exports: [RouterModule]
})
export class AppRoutingModule { }
