import { Component, OnInit, ViewChild } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { iBisAuthService, iBisEditUserService } from '@dohu/ibis-auth';
import { EditEntityRoleService } from './edit-entity-role/edit-entity-role.service';
import notify from 'devextreme/ui/notify';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html'
})

export class UserComponent implements OnInit {
    dsUser: CustomStore;
    dsUserRole: CustomStore;
    dsFacility: CustomStore;

    onEditUserClick: any;
    onEditEntityClick: any;
    onRemoveEntityClick: any;

    isInviteUserVisible: boolean;

    @ViewChild('gridUser', { static: false }) gridUser: DxDataGridComponent;
    @ViewChild('gridRoles', { static: false }) gridRoles: DxDataGridComponent;


    constructor(public ui: UIService, public ds: DataService, public auth: iBisAuthService,
        public edit: iBisEditUserService, public editEntityRole: EditEntityRoleService) {
    }

    ngOnInit() {
        if (!this.ds.auth.isAuth) { return; }
        this.dsUserRole = this.ds.getUserLoginRole();
        this.dsUser = this.ds.securityUsers();
        this.dsFacility = this.ds.getAllFacilities();
        this.onEditUserClick = this.onEditUserClickEv.bind(this);
        this.onEditEntityClick = this.onEditEntityClickEv.bind(this);
        this.onRemoveEntityClick = this.onRemoveEntityClickEv.bind(this);
    }

    onEditUserClickEv(ev: any) {
        ev.event.preventDefault();
        this.edit.authServer = this.ds.environment.authServer;
        this.edit.showPopup(ev.row.data, true, this.ds.environment.saasServer).then(
            () => this.gridUser.instance.refresh(),
            (error) => this.ds.serverError(error)
        );
    }

    onEditEntityClickEv(ev: any) {
        ev.event.preventDefault();
        this.editEntityRole.dsEntitiesExcept = this.ds.getFacilitiesExcept(ev.row.data.userId);
        this.editEntityRole.showPopup(ev.row.data, true).then(
            () => this.gridRoles.instance.refresh(),
            (error) => this.ds.serverError(error));
    }

    onRemoveEntityClickEv(ev: any) {
        ev.event.preventDefault();
        this.editEntityRole.remove(ev.row.data.id).then(() => {
            notify('Success', 'success', 2000);
            this.gridRoles.instance.refresh();
        });
    }

    onClosePopup(e: any) {
        this.isInviteUserVisible = false;
    }

    onTitleClickEv(ev: any) {
        this.dsUser = this.ds.getUserByRole(ev.itemData.id);
    }

    onInitDetail(event: any, data: any) {
        if (event.component.option('dataSource') == null) {
            event.component.option('dataSource', this.ds.getEntityRole(data.data.u_id));
        }
    }

    onUserExpand(event: any) {
        event.component.collapseAll(-1);
    }

    toolbarPreparing(event: any, isUserGrid?: boolean, userId?: string) {
        this.ui.prepareToolbar(event, true);
        if (isUserGrid) {
            event.toolbarOptions.items.unshift({
                widget: 'dxButton',
                options: {
                    text: 'Invită utilizator',
                    icon: 'email',
                    onClick: (e: any) => { this.isInviteUserVisible = true; }
                },
                location: 'after'
            });
            event.toolbarOptions.items.unshift({
                widget: 'dxButton',
                options: {
                    text: 'Adaugă utilizator',
                    icon: 'plus',
                    onClick: (e: any) => {
                        this.edit.authServer = this.ds.environment.authServer;
                        this.edit.showPopup({  isActiv: true }, false, this.ds.environment.saasServer).then(
                            () => this.gridUser.instance.refresh(),
                            (error) => this.ds.serverError(error)
                        );
                    }
                },
                location: 'after'
            });
        } else {
            event.toolbarOptions.items.unshift({
                widget: 'dxButton',
                options: {
                    text: 'Atribuie entitate',
                    icon: 'plus',
                    onClick: (e: any) => {
                        this.editEntityRole.dsEntitiesExcept = this.ds.getFacilitiesExcept(userId);
                        this.editEntityRole.showPopup({ userId: userId }).then(() => {
                            this.gridRoles.instance.refresh();
                        });
                    }
                },
                location: 'after'
            });
        }
    }
}
