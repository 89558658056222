import { Component, OnInit } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { iBisAuthService } from '@dohu/ibis-auth';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DataService } from 'src/app/services';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerData: any;
  forgotPassword: boolean;
  resetEmail: string;

  constructor(private auth: iBisAuthService, private ds: DataService, public activeRoute: ActivatedRoute, public router: Router) {
    this.activeRoute.queryParams.subscribe(params => {
      this.forgotPassword = params['forgotPassword'] === 'true';
    });
  }

  ngOnInit() {
    this.registerData = { isConfirm: false };
  }

  onRegister(e: any) {
    const validation = e.validationGroup.validate();
    if (!validation.isValid) {
      notify('Trebuie să completați toate câmpurile obligatorii.', 'error', 3000);
      return;
    }

    const fields = ['_Companie_', '_CIF_', '_Telefon_'];
    const values = new Array();
    for (let i = 0; i < fields.length; i++) {
      values.push(this.registerData[fields[i]]);
    }
    const obj = {
      registerType: 'EMIM',
      fullName: this.registerData.fullName,
      emailAddress: this.registerData.emailAddress,
      deployId: environment.deployId,
      fields: fields,
      values: values
    };

    this.auth.register(obj).then(() => {
      notify('În câteva momente vei primi un e-mail de confirmare cu toate detaliile necesare. Îți mulțumim.', 'success', 10000);
      setTimeout(() => {
        this.router.navigateByUrl('/login');
      }, 3000);
      this.registerData = { isConfirm: false };
    }, (error: any) => {
      this.ds.serverError(error);
    });
  }

  onResetPassword(e: any) {
    const validation = e.validationGroup.validate();
    if (!validation.isValid) {
      notify('Trebuie să completați toate câmpurile obligatorii.', 'error', 3000);
      return;
    }

    this.auth.forgotPassword(this.resetEmail).then(() => {
      notify('În câteva momente vei primi un e-mail pentru restarea de parola. Îți mulțumim.', 'success', 5000);
      setTimeout(() => {
        this.router.navigateByUrl('/login');
      }, 5000);
    }, (error: any) => {
      this.ds.serverError(error);
    });
  }

}
