import { Component, OnInit, ViewChild } from '@angular/core';
import { UIService } from '../../../services/ui.service';
import { DataService } from '../../../services/data.service';
import { EditPhysicalInventoryService } from './edit-physical-inventory.service';
import { ProxyService } from 'src/app/services/proxy.service';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog';
import { DxDataGridComponent } from 'devextreme-angular';
import { EntityStore } from '@dohu/ibis-entity';

@Component({
    selector: 'app-edit-physical-inventory',
    templateUrl: './edit-physical-inventory.component.html'
})
export class EditInventoryComponent implements OnInit {

    @ViewChild('gridInventoryItem', { static: false }) gridInventoryItem: DxDataGridComponent;
    onSave: any;
    onSaveNew: any;
    onFinish: any;
    onDeleteItem: any;

    constructor(public ui: UIService, public ds: DataService, public edit: EditPhysicalInventoryService, public proxy: ProxyService) {
        this.onSave = this.onSaveEv.bind(this, 0);
        this.onSaveNew = this.onSaveEv.bind(this, 1);
        this.onDeleteItem = this.onDeleteItemEv.bind(this);
        this.onFinish = this.onFinishEv.bind(this);
    }

    ngOnInit() { }

    toolbarPreparing(ev: any) {
        this.ui.prepareToolbar(ev, true);
        ev.toolbarOptions.items.unshift({
            widget: 'dxButton',
            options: {
                hint: 'Adaugă',
                text: 'Adaugă produs',
                icon: 'plus',
                onClick: (e: any) => {
                    if (this.proxy.globalSearch.gridInstance) {
                        this.proxy.globalSearch.initGlobalSearch();
                    }
                    this.proxy.globalSearch.showPopup({ type: 'stock' }, true).then((data) => {
                        if (data) {
                            const obj = {
                                orderIndex: this.edit.dsInventoryItem.length + 1,
                                productId: data.p_id,
                                pName: data.p_name,
                                pCode: data.p_code,
                                unitCost: data.i_unitCost || 0,
                                actualQuantity: 0,
                                actualFQuantity: 0,
                                quantity: data.i_quantity || 0,
                                fQuantity: data.i_fQuantity || 0,
                                inventoryId: data.i_id
                            };
                            this.edit.dsInventoryItem.push(obj);
                            this.gridInventoryItem.instance.refresh();
                        }
                    });
                }
            },
            location: 'after'
        });
    }

    onSaveEv(onSave: any, ev: any) {
        let ok = true;
        this.edit.dsInventoryItem.forEach(element => {
            if (!(element.actualQuantity) && !(element.actualFQuantity)) {
                ok = false;
            }
        });
        if (ok) {
            if (onSave === 1) {
                this.edit.onSaveNew(ev);
            } else {
                this.edit.onSaveClose(ev);
            }
        } else {
            notify('Cantitatea și cantitatea fracționară trebuie să fie completate!', 'error', 3000);
        }

    }

    onDeleteItemEv(ev: any) {
        ev.event.preventDefault();
        confirm('Ești sigur că vrei să ștergi această înregistrare ?', 'Confirmare').then(val => {
            if (val) {
                if (this.edit.model.id) {
                    this.edit.onRemove(ev.row.data.id).then(() => {
                        notify('Success', 'success', 2000);
                        this.edit.populateInventoryItem(this.edit.model.id);
                        this.gridInventoryItem.instance.refresh();
                    }, err => {
                        this.ds.serverError(err);
                    });
                } else {

                    this.edit.dsInventoryItem.splice(ev.row.rowIndex, 1);
                    this.gridInventoryItem.instance.refresh();
                }
            }
        });
    }

    onFinishEv(ev: any) {
        ev.event.preventDefault();
        confirm('Ești sigur că vrei să finalizezi?', 'Confirmare').then(val => {
            if (val) {
                this.edit.hidePopup();
                this.edit.model.items = this.edit.dsInventoryItem;
                return EntityStore.execute('FinishPhisycalInventory', this.edit.model);
            }
        });
    }
}
