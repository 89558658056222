import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { DxDataGridComponent } from 'devextreme-angular';
import { EditPhysicalInventoryService } from './edit-physical-inventory.service';
import { ThrowStmt } from '@angular/compiler';
import { ConcatSource } from 'webpack-sources';

@Component({
  selector: 'app-physical-inventory',
  templateUrl: './physical-inventory.component.html'
})
export class PhysicalInventoryComponent implements OnInit {

  dsInventory: CustomStore;
  dsFacility: CustomStore;
  dsInventoryItem: CustomStore;
  dsProduct: CustomStore;
  dsCurrency: CustomStore;

  @ViewChild('gridInventory', { static: false }) gridInventory: DxDataGridComponent;

  constructor(public ds: DataService, public ui: UIService, public edit: EditPhysicalInventoryService) {
  }

  ngOnInit() {
    this.dsInventory = this.ds.getPhysicalInventory();
    this.dsFacility = this.ds.getFacility();
    this.dsProduct = this.ds.getProduct();
  }

  mainToolbarPreparing(e: any) {
    this.ui.prepareToolbar(e, true);
    e.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        hint: 'Adaugă',
        icon: 'plus',
        text: 'Adaugă inventar',
        onClick: (e: any) => this.edit.showPopup(null)
          .then(() => this.gridInventory.instance.refresh(), () => { })
      },
      location: 'after'
    });
  }

  onEditPhysicalInvClickEv(data_row: any) {
    this.edit.showPopup(data_row.data, true)
      .then(() => this.gridInventory.instance.refresh(), () => { });
  }

  onInventoryExpand(ev: any) {
    ev.component.collapseAll(-1);
    this.dsInventoryItem = this.ds.getItemByPhysicalInventory(ev.key);
  }
}
