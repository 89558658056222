import { Component, OnInit } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import { iBisAuthService } from '@dohu/ibis-auth';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import notify from 'devextreme/ui/notify';

@Component({
    selector: 'app-company-details',
    templateUrl: './company-details.component.html',
    styleUrls: ['./company-details.component.css']
})
export class CompanyDetailsComponent implements OnInit {

    company: any = {};
    homePageValidation: RegExp;
    phoneNumberValidation: RegExp;

    constructor(public ui: UIService, public auth: iBisAuthService, public ds: DataService) {
        // tslint:disable-next-line: max-line-length
        this.homePageValidation = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        // tslint:disable-next-line: max-line-length
        this.phoneNumberValidation = /^(?:(?:(?:00\s?|\+)40\s?|0)(?:7\d{2}\s?\d{3}\s?\d{3}|(21|31)\d{1}\s?\d{3}\s?\d{3}|((2|3)[3-7]\d{1})\s?\d{3}\s?\d{3}|(8|9)0\d{1}\s?\d{3}\s?\d{3}))$/;
    }

    ngOnInit() {
        setTimeout(() => {
            const div = document.getElementById('container');
            div.style.height = this.ui.fullHeight;
        }, 0);

        EntityStore.fromQuery(new EntityQuery('Company')
            .link('id', 'id', new EntityQuery('Party').eq('typeId', 3))).single().then((res: any) => {
                Object.assign(this.company, res);
            });
    }

    SaveCompanyDetails(ev: any) {
        const validation = ev.validationGroup.validate();
        if (!validation.isValid) {
            return;
        }

        EntityStore.fromQuery(new EntityQuery('Company')).update(this.company.id, this.company).then(() => {
            notify('Date companie salvate cu succes!', 'success', 3000);
        });
    }
}
