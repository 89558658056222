import { Injectable } from '@angular/core';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { DataService } from '../../../services/data.service';
import { iBisEditService } from '@dohu/ibis-common';
import notify from 'devextreme/ui/notify';

@Injectable({
    providedIn: 'root'
})
export class EditPriceChangeService extends iBisEditService {

    dsPriceChangeItem: any = [];
    validation: string;
    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'priceChangeValidation';
    }

    createDefault() {
        this.dsPriceChangeItem = [];
        const result: any = { date: new Date() };
        if (this.ds) {
            result.facilityId = this.ds.currentFacility.id;
            result.facilityName = this.ds.currentFacility.text;
        }
        return result;
    }

    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            EntityStore.fromQuery(new EntityQuery('PriceChange').eq('id', data.id)).single().then((obj) => {
                if (!obj) {
                    reject();
                } else {
                    resolve(obj);
                }
            });
        });
    }

    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('PriceChange')).remove(id);
    }
    onSaveEv(): Promise<any> {
        for (let i = 0; i < this.dsPriceChangeItem.length; i++) {
            this.dsPriceChangeItem[i].orderIndex = i + 1;
        }
        this.model.items = this.dsPriceChangeItem;
        return EntityStore.execute('AddPriceChange', this.model);
    }
    reset(): void {
        this.model = this.createDefault();
    }
}
