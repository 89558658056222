import { Injectable } from '@angular/core';
import { EntityQuery, EntityStore, EntityConditionGroup } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import { DataService } from 'src/app/services';
import { iBisEditService } from '@dohu/ibis-common';

@Injectable()
export class GlobalSearchService extends iBisEditService {

    gridInstance: any;
    searchInstance: any;

    dsItems: CustomStore;
    dsTax: any;

    searchValue: any;
    productInfo: any = {};

    constructor(public ds: DataService) {
        super(ds.lg);
    }

    createDefault() {
        return {};
    }


    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.dsItems = this.setInventoryGlobalSearch(data);
            this.ds.getTax().load().then((res: any) => {
                this.dsTax = res.data;
            });
            resolve(data);
        });
    }

    setInventoryGlobalSearch(data: any) {
        const q = new EntityQuery('InventoryView');
        const p = new EntityQuery('Product').addOrderBy(['name']);
        let group: any;
        group = new EntityConditionGroup().gt('i_quantity', 0).gt('i_fQuantity', 0);
        group.useOr = true;
        switch (data.type) {
            case 'products':
                // q.addOrderBy(['p.name']);
                break;
            case 'stock':
                q.conditionGroups.groups.push(group);
                q.gt('i_pricePerPackage', 0).addOrderBy(['i.receivedDate']);
                q.eq('i.facilityId', this.ds.currentFacility.id);
                break;
            case 'return':
                q.conditionGroups.groups.push(group);
                q.gt('i_pricePerPackage', 0).addOrderBy(['i.receivedDate']);
                q.link('i.productId', 'productId', new EntityQuery('InvoiceItem')
                    .linkEq('invoiceId', 'id', 'Invoice', 'partyId', data.partyId));
                q.eq('i.facilityId', this.ds.currentFacility.id);
                q.distinct = true;
                break;
            case 'header-search':
                const retired = new EntityConditionGroup().eq('i_isRetired', null).eq('i_isRetired', false);
                retired.useOr = true;
                q.conditionGroups.groups.push(retired);
                q.conditionGroups.groups.push(group);
                q.gt('i_pricePerPackage', 0).addOrderBy(['i.receivedDate']);
                break;
        }
        const query = data.type === 'products' ? p : q;
        const keyField = data.type === 'products' ? 'id' : 'i_id';
        const cso = EntityStore.store(query, false, keyField);
        return new CustomStore(cso);
    }

    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('Invoice')).remove(id);
    }

    onSaveEv(): Promise<any> {
        return new Promise((resolve) => {
            this.model.isSaving = true;
            resolve(this.model);
        });
    }

    reset(): void {
    }

    initGlobalSearch() {
        this.productInfo = {};
        this.searchValue = null;
        this.gridInstance.searchByText('');
        this.gridInstance.option('focusedRowIndex', -1);
        setTimeout(() => {
            const scrollToTop = this.gridInstance.getScrollable();
            scrollToTop.scrollTo(0);
        }, 0);
        this.autoFocusSearch();
    }

    autoFocusSearch() {
        if (this.searchInstance) {
            setTimeout(() => {
                this.searchInstance.focus();
            }, 1000);
        }
    }
}
