import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { ProxyService } from 'src/app/services/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-promo',
  templateUrl: './promo.component.html'
})
export class PromoComponent implements OnInit {

  dsPromo: CustomStore;
  dsPromoType: CustomStore;
  dsProduct: any;
  dsCurrency: CustomStore;

  @ViewChild('gridPromo', { static: false }) gridPromo: DxDataGridComponent;
  @ViewChild('gridDetail', { static: false }) gridDetail: DxDataGridComponent;
  onDeletePromoClick: any;
  onEditPromoClick: any;
  onEditItemClick: any;
  onDeleteItemClick: any;

  constructor(public ds: DataService, public ui: UIService, public proxy: ProxyService) { }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.dsPromo = this.ds.getCatalog();
    this.dsPromoType = this.ds.getEnumValue('PROMOTYPE');
    this.dsProduct = this.ds.getInventory();

    this.onEditPromoClick = this.onEditPromoClickEv.bind(this);
    this.onDeletePromoClick = this.onDeletePromoClickEv.bind(this);
    this.onEditItemClick = this.onEditItemClickEv.bind(this);
    this.onDeleteItemClick = this.onDeleteItemClickEv.bind(this);
  }

  onInitDetail(event: any, data: any) {
    if (event.component.option('dataSource') == null) {
      event.component.option('dataSource', this.ds.getCatalogProduct(data.data.id));
    }
  }

  onDeletePromoClickEv(e: any) {
    e.event.preventDefault();
    this.proxy.editPromo.remove(e.row.data.id).then(() => {
      notify('Success', 'success', 2000);
      this.gridPromo.instance.refresh();
    });
  }

  onEditPromoClickEv(e: any) {
    e.event.preventDefault();
    this.proxy.editPromo.showPopup(e.row.data.id, true).then(
      (res: any) => {
        if (res && res.isSaving) {
          this.gridPromo.instance.refresh();
        }
      },
      (error) => this.ds.serverError(error)
    );
  }

  onDeleteItemClickEv(e: any) {
    e.event.preventDefault();
    this.proxy.promoItem.remove(e.row.data.id).then((res: any) => {
      if (res && res.isSaving) {
        notify('Success', 'success', 2000);
        this.gridDetail.instance.refresh();
      }
    });
  }

  onEditItemClickEv(e: any) {
    e.event.preventDefault();
    this.proxy.promoItem.showPopup(e.row.data.id, true).then(
      () => this.gridDetail.instance.refresh(),
      (error) => this.ds.serverError(error)
    );
  }

  toolbarPreparing(e: any, type: string, data?: any) {
    this.ui.prepareToolbar(e, true);
    if (type === 'master') {
      e.toolbarOptions.items.unshift({
        widget: 'dxButton',
        options: {
          text: 'Adaugă promoție',
          icon: 'plus',
          onClick: () => this.proxy.editPromo.showPopup({}).then((res: any) => {
            if (res && res.isSaving) {
              this.gridPromo.instance.refresh();
            }
          })
        },
        location: 'after'
      });
    } else {
      e.toolbarOptions.items.unshift({
        widget: 'dxButton',
        options: {
          text: 'Adaugă articol',
          icon: 'plus',
          onClick: () => this.proxy.promoItem.showPopup({
            orderIndex: e.component.totalCount() + 1, catalogId: data.data.id,
            promoTypeId: data.data.typeId
          }).then((res: any) => {
            if (res && res.isSaving) {
              this.gridDetail.instance.refresh();
            }
          })
        },
        location: 'after'
      });
    }
  }
}
