import { Injectable } from '@angular/core';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services';

@Injectable()
export class EditReceivingItemService extends iBisEditService {

    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'productValidation';
    }

    getViewById(id: string) {
    }

    createDefault() {
        return { date: new Date() };
    }

    getById(data: any): Promise<any> {
        return new Promise(resolve => {
            resolve();
        });
    }

    onRemove(id: string): Promise<void> {
        return new Promise(resolve => {
            resolve();
        });
    }

    onSaveEv(): Promise<any> {
        return new Promise(resolve => {
            this.model.isSaving = true;
            resolve(this.model);
        });
    }

    reset(): void {
        this.model = {};
    }
}
