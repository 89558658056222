import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/services';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';
import { ProxyService } from 'src/app/services/proxy.service';
import CustomStore from 'devextreme/data/custom_store';

@Component({
    selector: 'app-print-receipt',
    templateUrl: 'print-receipt.component.html'
})
export class PrintReceiptComponent {

    @Input() isPrintReceiptVisible: boolean;
    @Input() totalToPay: number;
    @Input() receiptData: any;
    @Output() closePopup = new EventEmitter<any>();

    dsPaymentMethod: CustomStore;
    dsPaymentType: CustomStore;
    closePrintReceipt: any;
    printReceipt: any;
    maxDate: Date;
    printData: any = {};
    methodPaymentComponent: any;

    constructor(public ds: DataService, public router: Router, public proxy: ProxyService) {
        this.closePrintReceipt = this.closePrintReceiptEv.bind(this);
        this.printReceipt = this.printReceiptEv.bind(this);
        this.maxDate = new Date();

        if (!this.ds.auth.isAuth) { return; }
        // this.dsPaymentType = this.ds.getEnumValue('PAYMENT_TYPE');
    }

    closePrintReceiptEv() {
        this.isPrintReceiptVisible = false;
        this.closePopup.emit(this.isPrintReceiptVisible);
        this.printData = {};
    }

    onPrintShown() {
        Object.assign(this.printData, this.receiptData);
        this.ds.getEnumValue('METHOD').load().then((res: any) => {
            this.dsPaymentMethod = res.data;
            this.printData.methodId = res.data[0].id;
        });
        this.printData.typeId = null;
    }

    onPrintHidden() {
        this.isPrintReceiptVisible = false;
        this.closePopup.emit(this.isPrintReceiptVisible);
    }

    onPaymentChange = (ev: any) => {
        if (ev.selectedItem) {
            this.printData.paidMode = Number(ev.selectedItem.code);
        }
    }

    printReceiptEv(ev: any) {
        if (ev.validationGroup) {
            const validators = ev.validationGroup.validators.filter((x: any) => x._isHidden === false);
            const validation = ev.validationGroup;
            validation.validators = validators;
            if (!validation.validate().isValid) {
                notify('Trebuie să completați toate câmpurile obligatorii.', 'error', 3000);
                return;
            }
        }
        const payment = {
            typeId: this.printData.typeId,
            methodId: this.printData.methodId,
            refNumber: this.printData.refNumber,
            amount: this.totalToPay,
            comments: this.printData.comments,
            effectiveDate: this.printData.docDate
        };
        this.ds.getTodayReceiptCount().then((count: number) => {
            this.receiptData.facilityId = this.ds.currentFacility.id;
            const invoiceQuery = EntityStore.fromQuery(new EntityQuery('Invoice').eq('id', this.receiptData.id)).single();
            const receiptOp = this.receiptData.id ? invoiceQuery : EntityStore.execute('CreateOutgoing', this.receiptData);
            const data = this.createPrintItemsArray(this.printData.items);
            // this.ds.printReceipt(data);
            receiptOp.then((result: any) => {
                this.printData.refNumber = count + 1;
                const op = EntityStore.fromQuery(new EntityQuery('Payment')).insert(payment);
                op.then((res: any) => {
                    const paymentApplicaton = {
                        toPaymentId: res.id,
                        invoiceId: result.id || result,
                        ammountApply: this.totalToPay
                    };
                    const opa = EntityStore.fromQuery(new EntityQuery('PaymentApplication')).insert(paymentApplicaton);
                    opa.then((r: any) => {
                        this.closeInvoice(paymentApplicaton.invoiceId);
                        notify('Succes.', 'success', 3000);
                        this.closePrintReceiptEv();
                        if (this.router.url.indexOf('receipt') > -1) {
                            this.proxy.editReceipt.mainReceiptGridInstance.refresh();
                        } else {
                            this.ds.redirectTo('/sales/receipt');
                        }
                    });
                });
            });
        });
    }

    closeInvoice(invoiceId: string) {
        // dupa tiparirea bonului, va lua statusul 1
        return EntityStore.fromQuery(new EntityQuery('Invoice')).update(invoiceId, { statusId: 1 });
    }

    createPrintItemsArray(products: any) {
        const arr = [];
        for (let i = 0; i < products.length; i++) {
            const obj = {
                productName: products[i].name, taxCode: 2, price: products[i].pricePerPackage,
                department: 0, unit: 'BUC', quantity: products[i].quantity, discountType: 2,
                discountValue: this.printData.percentDiscount,
                opCode: 1, opPwd: '0001', tillNmb: 1, paidMode: this.printData.paidMode
            };
            arr.push(obj);
        }
        return arr;
    }
}
