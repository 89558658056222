import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import { ProxyService } from 'src/app/services/proxy.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { iBisAuthService } from '@dohu/ibis-auth';
import { custom } from 'devextreme/ui/dialog';
import { confirm } from 'devextreme/ui/dialog';
import { EntityStore } from '@dohu/ibis-entity';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html'
})
export class ReceiptComponent implements OnInit {

  dsReceiptView: CustomStore;
  customDialog: any;
  calcTotalPrice: any;

  @ViewChild('gridReceipt', { static: false }) gridReceipt: DxDataGridComponent;

  onViewReceiptClick: (e: any) => void;
  onViewPrescriptionClick: (e: any) => void;
  onDeleteReceiptClick: (e: any) => void;


  constructor(public ds: DataService, public ui: UIService, public auth: iBisAuthService, public proxy: ProxyService) { }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.initDialog();

    if (!this.ds.currentFacility) {
      this.ds.isFacilityLoaded.subscribe(() => {
        this.dsReceiptView = this.ds.getInvoicePrescriptionView();
      });
    } else {
      this.dsReceiptView = this.ds.getInvoicePrescriptionView();
    }

    this.onViewReceiptClick = this.onViewReceiptClickEv.bind(this);
    this.onViewPrescriptionClick = this.onViewPrescriptionClickEv.bind(this);
    this.calcTotalPrice = this.calcTotalPriceEv.bind(this);
    // this.onDeleteReceiptClick = this.onDeleteReceiptClickEv.bind(this);
  }

  onGridInit(ev: any) {
    this.proxy.editReceipt.mainReceiptGridInstance = ev.component;
  }

  initDialog() {
    this.customDialog = custom({
      title: 'Confirmare',
      messageHtml: 'Doriți întocmirea unui bon cu rețetă ?',
      buttons: [{
        text: 'Da',
        onClick: () => {
          return true;
        }
      },
      {
        text: 'Nu',
        onClick: () => {
          return false;
        }
      },
      ]
    });
  }

  calcTotalPriceEv(row: any) {
    return (row.ii_totalPrice ? this.ds.roundTo(row.ii_totalPrice - (row.i_discount || 0), 2) : null);
  }

  calculateSerialNo(row: any) {
    return row.pre_serial ? row.pre_serial + ' ' + row.pre_serialNo : null;
  }

  onReceiptChanged(ev: any) {
    ev.component.collapseAll(-1);
    ev.component.expandRow(ev.currentSelectedRowKeys[0]);
  }

  onViewReceiptClickEv(e: any) {
    // e.event.preventDefault();
    this.proxy.editReceipt.showPopup(e.row.data, true).then(() => {
      this.gridReceipt.instance.refresh();
    });
  }

  onReceiptRemoveClickEv(e: any) {
    confirm('Ești sigur că vrei să ștergi acest bon ?', 'Confirmare').
      then((val: boolean) => {
        if (val) {
          EntityStore.execute('RemoveInvoice', { id: e.row.data.i_id }).then((response) => {
            if (response) {
              switch (response) {
                case 'OK':
                  this.gridReceipt.instance.refresh();
                  notify('Factură ștearsă cu succes.', 'succes', 3000);
                  break;
                case 'ERROR':
                  notify('Eroare în urma ștergerii.', 'error', 3000);
                  break;
              }
            }
          }, err => {
            notify(err, 'error', 3000);
          });
        }
      });
  }

  onViewPrescriptionClickEv(e: any) {
    // e.event.preventDefault();
    this.proxy.editPrescription.showPopup(e.row.data, true).then(() => {
      this.gridReceipt.instance.refresh();
    });
  }

  toolbarPreparing(event: any) {
    this.ui.prepareToolbar(event, true);

    event.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        text: 'Adaugă rețetă / bon',
        icon: 'plus',
        onClick: (e: any) => {
          this.initDialog();
          this.customDialog.show().then((val) => {
            if (val) {
              this.proxy.editPrescription.openPrescriptionScan();
            } else {
              this.ds.getTodayReceiptCount().then((count: number) => {
                this.proxy.editReceipt.createDefault();
                this.proxy.editReceipt.showPopup(
                  {
                    statusId: 0,
                    refNumber: count + 1,
                    docDate: new Date(),
                    typeId: -3,
                    discount: 0,
                    percentDiscount: 0,
                    fidelityCard: { id: '', no: '', name: '' },
                    facilityId: this.ds.currentFacility.id,
                    fromPrescription: false
                  }
                ).then(() => {
                  this.gridReceipt.instance.refresh();
                }, err => {
                  console.log(err);
                });
              });

            }
          });
        }
      },
      location: 'after'
    });
  }
}
