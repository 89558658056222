import { Component, OnInit } from '@angular/core';
import { UIService, DataService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';

@Component({
    selector: 'app-inventory-history',
    templateUrl: './inventory-history.component.html'
})
export class InventoryHistoryComponent implements OnInit {
    dsInventoryHistory: CustomStore;

    constructor(public ui: UIService, public ds: DataService) { }

    ngOnInit() {
        if (!this.ds.auth.isAuth) { return; }
        this.dsInventoryHistory = this.ds.getInventoryVarianceView();
    }

    toolbarPreparing(event: any) {
        this.ui.prepareToolbar(event, true);
    }
}
