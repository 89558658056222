import { Injectable } from '@angular/core';
import { EntityQuery, EntityStore } from '@dohu/ibis-entity';
import { iBisAuthService } from '@dohu/ibis-auth';
import { DataService } from 'src/app/services';
import { Router } from '@angular/router';
import { iBisEditService } from '@dohu/ibis-common';

@Injectable()
export class EditFacilityService extends iBisEditService {
    constructor(public auth: iBisAuthService, public ds: DataService, public router: Router) {
        super(ds.lg);
        this.validation = 'facilityValidation';
    }
    createDefault() {
        return {};
    }
    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (!data.id) { reject(); }
            EntityStore.fromQuery(new EntityQuery('Facility').eq('id', data.id)).single().then((obj) => {
                if (!obj) {
                    reject();
                } else {
                    resolve(obj);
                }
            });
        });
    }
    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('Facility')).remove(id);
    }

    onSaveEv(): Promise<any> {
        return new Promise((resolve, reject) => {
            const obj = this.model;
            if (this.model.id) {
                EntityStore.fromQuery(new EntityQuery('Facility')).update(this.model.id, obj).then((res) => {
                    resolve(res);
                });
            } else {
                obj.openDate = new Date();
                return EntityStore.execute('AddFacility', obj).then((res) => {
                    resolve(res);
                });
            }
        });
    }
    reset(): void { }
}
