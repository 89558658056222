import { Component, NgModule, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import notify from 'devextreme/ui/notify';

import { DataService } from '../../services';
import { iBisAuthService } from '@dohu/ibis-auth';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxCheckBoxModule } from 'devextreme-angular/ui/check-box';
import { DxTextBoxModule, DxTextBoxComponent } from 'devextreme-angular/ui/text-box';
import { DxValidatorModule } from 'devextreme-angular/ui/validator';
import { DxValidationGroupModule } from 'devextreme-angular/ui/validation-group';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
  login = '';
  password = '';
  @ViewChild('txtPassword', { static: false }) txtPassword: DxTextBoxComponent;

  constructor(private auth: iBisAuthService, public ds: DataService, public router: Router) {
    const isLoginPage = this.router.url.indexOf('login') > -1;
    if (this.auth.isAuth && isLoginPage) {
      router.navigateByUrl(this.ds.previousUrl ? this.ds.previousUrl : '/supply/order');
    }
  }

  onLoginClick(args) {
    if (!args.validationGroup.validate().isValid) {
      return;
    }
    this.auth.login({ userName: this.login, password: this.password }).then((data) => {
      notify('Autentificare cu succes.', 'success', 3000);
      // if (this.router.url === '/login') {
      const admin = this.ds.menuItems.find((x: any) => x.infoPath === '/admin');
      // this.ds.toggleUnprocessedTabVisible(this.ds.menuItems);
      admin.visible = this.ds.isAdmin();
      if (this.router.url === '/login') {
        this.router.navigateByUrl('/sales/receipt');
      }
      this.ds.isLoginModalVisible = false;
      // args.validationGroup.reset();
    }, (error) => {
      if (typeof error === 'object') {
        notify(error.err, 'error', 3000);
      } else if (typeof error === 'string') {
        this.ds.serverError(error);
      }
    });
  }

  onEnterKey(type?) {
    if (type === 'us') {
      this.txtPassword.instance.focus();
    } else {
      document.getElementById('btLogin').focus();
      document.getElementById('btLogin').click();
    }
  }

  createAccount() {
    this.router.navigate(['/register'], { queryParams: { forgotPassword: false } });

  }
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxButtonModule,
    DxCheckBoxModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxValidationGroupModule
  ],
  declarations: [LoginFormComponent],
  exports: [LoginFormComponent]
})
export class LoginFormModule { }
