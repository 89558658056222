import { Injectable } from '@angular/core';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { DataService } from 'src/app/services';
import { iBisEditService } from '@dohu/ibis-common';
import notify from 'devextreme/ui/notify';

@Injectable()
export class EditCashRegisterKitService extends iBisEditService {

    manualTagBoxInstance: any;
    kitTagBoxInstance: any;
    initialFiles: any = [];

    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'cashRegisterValidation';
    }

    createDefault() {
        this.initialFiles = [];
        return {};
    }

    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.model = this.createDefault();
            if (data && data.id) {
                this.ds.getFileInfo(data.id).load().then((res: any) => {
                    if (res && res.data && res.data.length) {
                        for (let i = 0; i < res.data.length; i++) {
                            const item = res.data[i];
                            this.initialFiles.push(item);
                            if (this.checkIfManual(item)) {
                                this.model.manual = [item];
                                if (this.manualTagBoxInstance) {
                                    this.manualTagBoxInstance.option('value', [item]);
                                }
                            } else {
                                this.model.kit = [item];
                                if (this.kitTagBoxInstance) {
                                    this.kitTagBoxInstance.option('value', [item]);
                                }
                            }
                        }
                    }
                });
            }
            resolve(data);
        });
    }

    onRemove(id: string): Promise<void> {
        return new Promise((resolve) => { });
    }

    checkIfManual(item: any) {
        const name = item.name.toLowerCase();
        return name.indexOf('manual') > -1 || name.split('.').reverse()[0] === 'pdf';
    }

    onSaveEv(): Promise<any> {
        return new Promise((resolve) => {
            const obj = {
                name: this.model.name,
                description: this.model.description,
                uploadTime: this.model.uploadTime
            };
            const s = EntityStore.fromQuery(new EntityQuery('CashRegister'));
            const op = this.model.id ? s.update(this.model.id, obj) : s.insert(obj);
            op.then((res: any) => {
                this.model.id = res.id || res.key;
                const filesData: any = { files: [], initialFiles: this.initialFiles };
                if (this.model.manual && this.model.manual.length) {
                    filesData.files.push(this.model.manual[0]);
                }
                if (this.model.kit && this.model.kit.length) {
                    filesData.files.push(this.model.kit[0]);
                }
                this.saveFiles(this.model, filesData).then(() => {
                    // notify('Casa de marcat introdusă cu succes.', 'success', 3000);
                });

            });
            this.model.isSaving = true;
            resolve(this.model);
        });
    }

    deleteFiles(filesData: any) {
        return new Promise((resolve: any) => {
            const filesToDelete = filesData.initialFiles.filter((initialFile: any) => {
                return filesData.files.filter((afterSaveFile: any) => {
                    return initialFile.id === afterSaveFile.id;
                }).length === 0;
            });
            if (filesToDelete && filesToDelete.length) {
                filesToDelete.forEach((file: any, index: number) => {
                    const q = new EntityQuery('FileInfo');
                    EntityStore.fromQuery(q, false, 'id', this.ds.environment.saasServer).remove(file.id).then(() => {
                        if (filesToDelete.length === index + 1) {
                            resolve();
                            return;
                        }
                    });
                });
            }
            resolve();
        });
    }

    saveFiles(data: any, filesData: any) {
        return new Promise((resolve: any) => {
            if (data && (filesData.files && filesData.files.length) || filesData.initialFiles && filesData.initialFiles.length) {
                if (data && data.id && filesData.initialFiles && filesData.initialFiles.length) {
                    this.deleteFiles(filesData).then(() => {
                        this.insertFiles(filesData).then(() => {
                            resolve();
                        });
                    });
                } else {
                    this.insertFiles(filesData).then(() => {
                        resolve();
                    });
                }
            } else {
                resolve();
            }
        });
    }

    insertFiles(filesData: any) {
        return new Promise((resolve: any) => {
            filesData.files.forEach((file: any, index: number) => {
                if (file && file.id === null) {
                    this.ds.uploadFile(file, this.model.id).then(() => {
                    });
                }
                if (filesData.files.length === index + 1) {
                    resolve();
                }
            });
            if (filesData.files.length === 0) {
                resolve();
            }
        });
    }

    reset(): void {
        this.model = {};
    }
}
