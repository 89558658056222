import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DataService, UIService } from 'src/app/services';
import CustomStore from 'devextreme/data/custom_store';
import { ProxyService } from 'src/app/services/proxy.service';
import { DxDataGridComponent, DxFormComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import DataSource from 'devextreme/data/data_source';
import { ReceivingService } from './receiving.service';
import { confirm } from 'devextreme/ui/dialog';
import validationEngine from 'devextreme/ui/validation_engine';
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';

@Component({
  selector: 'app-receiving',
  templateUrl: './receiving.component.html'
})
export class ReceivingComponent implements OnInit, OnDestroy {

  dsOrders: CustomStore;
  dsParty: CustomStore;
  dseditOrderItems: CustomStore;
  dsFacility: CustomStore;
  dsTaxType: CustomStore;
  dsProduct: DataSource;
  dsTransferGroup: CustomStore;
  supplierLabel: String = 'Furnizor';

  dsPartySimple: any;

  onOrderChange: any;
  onTypeChanged: any;
  onTransferChange: any;
  calculateDueDate: any;
  calcInvAndEstDate: any;
  onDueDateInit: any;
  onDeleteItemClick: any;
  onEditItemClick: any;

  partyInstance: any;
  selectedRow: any;
  openWizard: boolean;
  readyForSaving = false;
  currentDate: Date;


  gridChange: Subject<any> = new Subject<any>();
  gridChangeSubscription: Subscription;

  @ViewChild('gridReceivingItem', { static: false }) gridReceivingItem: DxDataGridComponent;
  @ViewChild('receivingForm', { static: false }) receivingForm: DxFormComponent;


  constructor(public ds: DataService, public ui: UIService, public proxy: ProxyService, public edit: ReceivingService) {
    this.gridChangeSubscription = this.gridChange.subscribe(
      (changes: any) => {
        if (changes) {
          this.readyForSaving = changes.length ? true : false;
          for (let i = 0; i < changes.length; i++) {
            if (!changes[i].totalReceipt || !changes[i].expireDate) {
              this.readyForSaving = false;
              break;
            }
          }
        }
      });
  }

  ngOnInit() {
    if (!this.ds.auth.isAuth) { return; }
    this.currentDate = new Date();
    this.onOrderChange = this.onOrderChangeEv.bind(this);
    this.onTransferChange = this.onTransferChangeEv.bind(this);
    this.calculateDueDate = this.calculateDueDateEv.bind(this);
    this.calcInvAndEstDate = this.calcInvAndEstDateEv.bind(this);
    this.onDueDateInit = this.onDueDateInitEv.bind(this);

    this.onEditItemClick = this.onEditItemClickEv.bind(this);
    this.onDeleteItemClick = this.onDeleteItemClickEv.bind(this);
    this.selectedRow = [1];

    this.resetForm();

    // -1 aviz de vanzare -2 factura de vanzare -3 bon fiscal /  1 aviz de cumparare 2 factura de cumparare 3 inventar
    this.dsOrders = this.ds.getOrder(false);
    this.dsParty = this.ds.getParty(1);
    this.dsFacility = this.ds.getFacility();
    // this.dsTaxType = this.ds.getEnumValue('TAXID');
    this.ds.getOrderPartyFields().then((data: any) => {
      this.dsPartySimple = [];
      if (data && data.length > 0) {
        for (const r of data) {
          this.dsPartySimple.push(
            { id: r.f0, refNumber: r.f1, date: r.f2, partyId: r.f3, facilityId: r.f4, partyName: r.f5 || 'Necunoscut' });
        }
      }
    });
    this.dsTransferGroup = this.ds.getReceiveTransferGroup(this.ds.currentFacility.id);
  }

  ngOnDestroy() {
    this.gridChangeSubscription.unsubscribe();
  }

  onProductGridInit(ev: any) {
    this.edit.gridInstance = ev.component;
  }

  onPartyInit(event: any) {
    this.partyInstance = event.component;
  }

  openPartyAdd(event: any, type: number) {
    this.proxy.editCompany.showPopup(null).then(() => {
      if (this.partyInstance) {
        this.partyInstance.getDataSource().reload();
      }
    });
    event.event.preventDefault();
    event.event.stopPropagation();
  }

  onReceivingChanged(ev: any) {
    ev.component.collapseAll(-1);
    ev.component.expandRow(ev.currentSelectedRowKeys[0]);
  }

  openSupplierReceipt(ev: any) {
    this.openWizard = true;
  }

  save(e: any) {
    return this.edit.receivingData.transferGroupId ? this.saveTransfer(e, 1) : this.saveReceiving(e, 1);
  }

  saveTransfer(e: any, type: number) {
    this.edit.receivingData.items = this.edit.dsItems;
    this.ds.isLoadPanelVisible = true;
    if (this.edit.receivingData.transferGroupId) {
      EntityStore.execute('ReceiveTransfer', this.edit.receivingData).then((data) => {
        if (data === 'OK') {
          this.ds.isLoadPanelVisible = false;
          if (type === 1) {
            this.resetForm();
          }
          notify('Salvare efectuata cu success', 'success', 3000);
        } else {
          this.ds.isLoadPanelVisible = false;
          notify('Eroare in sistem. Incercați mai târziu', 'error', 3000);
        }
      }, err => {
        this.ds.isLoadPanelVisible = false;
        notify('Eroare in sistem. Inercati mai tarziu', 'error', 3000);
      });
    }
  }

  saveReceiving(e: any, type: number) {
    const group = validationEngine.getGroupConfig('customCValidation');
    const vals = group.validators;
    group.validators = vals.filter((x: any) => x._isHidden === false);
    const validation = e.validationGroup;
    if (!validation.validate().isValid || !group.validate().isValid) {
      notify('Trebuie să completați toate câmpurile obligatorii.', 'error', 3000);
      return;
    }
    const gridData = this.gridReceivingItem.instance.getDataSource().items();
    if (!gridData || (gridData && gridData.length === 0)) {
      notify('Trebuie să selectați măcar un articol.', 'error', 3000);
      return;
    }
    // Invoice -  "refNumber", "typeId", "partyId", "date", "docDate", "dueDate", "description"
    // Items  - "orderIndex", "productId", "quantity", "discount"
    // Items - "facilityId", "lotId", "receivedDate", "expireDate", "unitCost",  "pricePerPackage", "typeId",
    this.edit.receivingData.items = this.edit.dsItems;
    this.ds.isLoadPanelVisible = true;
    this.ds.sendReceiving(this.edit.receivingData).then((data) => {
      if (data === 'OK') {
        if (this.edit.receivingData.orderId) {
          EntityStore.fromQuery(new EntityQuery('OrderHeader')).update(this.edit.receivingData.orderId, { statusId: 2 });
        }
        this.ds.isLoadPanelVisible = false;
        if (type === 1) {
          this.resetForm();
        }
        notify('Salvare efectuata cu success', 'success', 3000);
      } else {
        this.ds.isLoadPanelVisible = false;
        notify('Eroare in sistem. Incercați mai târziu', 'error', 3000);
      }
    }, err => {
      this.ds.isLoadPanelVisible = false;
      notify('Eroare in sistem. Inercati mai tarziu', 'error', 3000);
    });
  }

  resetForm() {
    this.edit.dsItems = [];
    this.edit.productData = {};
    this.createDefault();
    this.edit.receivingData.dueDate = this.ds.addDaysToDate(
      this.clearDateTime(this.edit.receivingData.docDate), this.edit.receivingData.dueDays);
    const group = validationEngine.getGroupConfig('customCValidation');
    if (group) {
      group.reset();
    }
  }

  createDefault() {
    this.edit.receivingData = {
      typeId: 2,
      date: new Date(),
      docDate: new Date(),
      dueDays: 30,
      statusId: 1,
      facilityId: this.ds.currentFacility.id
    };
  }

  onOrderChangeEv(e: any) {
    const data = e.selectedItem || {};
    this.edit.dsItems = [];

    this.ds.loadOrderItems(data.id).then((items: any) => {
      if (items && items.data.length) {
        items.data.forEach((element: any) => {
          // const fc = (!element.oi_fQuantity ? 0 : element.oi_fQuantity) /
          //   (!element.p_qtyPerPackage ? 1 : element.p_qtyPerPackage);

          const item = {
            ModelName: 'ReceivingItems', // este irelevant
            id: element.oi_id,
            orderIndex: element.oi_orderIndex,
            requiredDate: element.oi_requiredDate,
            quantity: element.oi_quantity,
            productId: element.p_id,
            name: element.p_name,
            code: element.p_code,
            qtyPerPackage: element.p_qtyPerPackage,
            presentationMode: element.p_presentationMode,
            taxPercent: element.tax_percent,
            facilityId: this.ds.currentFacility.id,
            fQuantity: 0,
            totalQuantity: element.oi_quantity + 0, // quantity + fraction
            discount: 0,
            totalTva: 0,
            adaosCom: 0,
            receivedDate: null,
            expireDate: null

          };
          this.edit.dsItems.push(item);
          if (this.edit.dsItems.length === 1) {
            this.gridReceivingItem.instance.expandRow(item.orderIndex);
          } else {
            this.selectedRow = [item.orderIndex];
          }
        });
      }
    });
    this.edit.receivingData.partyId = data.partyId;
    this.edit.receivingData.facilityId = data.facilityId;
    this.supplierLabel = 'Furnizor';
  }

  onTransferChangeEv(ev: any) {
    this.edit.dsItems = [];
    if (ev.selectedItem) {
      const data = ev.selectedItem;
      this.edit.receivingData.refNumber = data.itg_documentId ? data.itg_documentId : '';
      this.edit.receivingData.typeId = 1;
      this.dsParty = this.ds.getFacility();
      this.edit.receivingData.partyId = data.itg_fromFacilityId;
      this.edit.receivingData.docDate = data.itg_sendDate;
      this.supplierLabel = 'Sursă';

      this.ds.getInventoryTransferView(data.itg_id).load().then((items) => {
        items.data.forEach(item => {
          if (item) {
            const quantity = item.vOut_quantity * (-1);
            const fQuantity = item.vOut_fQuantity * (-1);
            const fc = (!fQuantity ? 0 : fQuantity) /
              (!item.pOut_qtyPerPackage ? 1 : item.pOut_qtyPerPackage);

            const obj: any = {
              inventoryTransferId: item.it_id,
              transferGroupId: data.itg_id,
              orderIndex: this.edit.dsItems.length + 1,
              productId: item.pOut_id,
              name: item.pOut_name,
              code: item.pOut_code,
              pricePerPackage: item.iOut_pricePerPackage,
              qtyPerPackage: item.pOut_qtyPerPackage,
              presentationMode: item.pOut_presentationMode,
              quantity: quantity,
              fQuantity: fQuantity,
              totalQuantity: this.ds.roundTo((quantity + fc), 2),
              expireDate: item.iOut_expireDate,
              unitCost: item.iOut_unitCost,
              facilityId: item.iOut_facilityId,
              lotId: item.iOut_lotId,
              taxPercent: item.tax_percent
            };
            obj.totalReceipt = this.ds.roundTo(obj.totalQuantity * obj.pricePerPackage, 2);
            this.edit.dsItems.push(obj);
            if (this.edit.dsItems.length === 1) {
              this.gridReceivingItem.instance.expandRow(obj.orderIndex);
            } else {
              this.selectedRow = [obj.orderIndex];
            }
          }
        });
      });

    } else {
      this.edit.receivingData.refNumber = '';
      this.edit.receivingData.partyId = '';
      this.supplierLabel = 'Furnizor';
      this.dsParty = this.ds.getParty(1);
    }

  }

  onDataSourceChange(ev: any) {
    if (ev.name === 'dataSource') {
      const ds = this.edit.dsItems;
      for (let i = 0; i < ds.length; i++) {
        ds[i].orderIndex = i + 1;
      }
      this.gridChange.next(this.edit.dsItems);
    }
  }

  calculateDueDateEv(event: any) {
    if (event.event && (this.edit.receivingData.docDate || this.edit.receivingData.dueDays)) {
      this.edit.receivingData.dueDate = this.ds.addDaysToDate(
        this.clearDateTime(this.edit.receivingData.docDate), this.edit.receivingData.dueDays);
    }
  }

  calcInvAndEstDateEv(event: any) {
    if (event.event) {
      this.edit.receivingData.dueDays = this.calcDueDays(this.clearDateTime(event.value));
    }
  }

  onDueDateInitEv(event: any) {
    if (this.edit.receivingData.date && this.edit.receivingData.dueDays) {
      event.component.option('value', this.ds.addDaysToDate(
        this.clearDateTime(this.edit.receivingData.docDate), this.edit.receivingData.dueDays));
      this.edit.receivingData.dueDate = this.ds.addDaysToDate(
        this.clearDateTime(this.edit.receivingData.docDate), this.edit.receivingData.dueDays);
    }
  }

  calcDueDays(val: any) {
    const diffTime = Math.abs(new Date(val).getTime() - new Date(
      this.clearDateTime(this.edit.receivingData.docDate)).getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }

  clearDateTime(val: any) {
    const y = new Date(val).getFullYear(),
      m = new Date(val).getMonth(),
      d = new Date(val).getDate();
    return new Date(y, m, d, 0, 0, 0, 0);
  }

  customOrderDisplay(data: any) {
    return !data ? null : (data.refNumber + ' / ' + DataService.formatDate(data.date) + ' - ' + data.partyName);
  }

  customTransferDisplay = (rowInfo: any) => {
    if (rowInfo) {
      return rowInfo.fOut_name + ' -> Serie Nr.:' + rowInfo.itg_documentId;
    }
    return '';
  }

  // popup

  onDeleteItemClickEv(e: any) {
    e.event.preventDefault();
    confirm('Ești sigur că vrei să ștergi această înregistrare ?', 'Confirmare').then((val: boolean) => {
      if (val) {
        this.edit.dsItems.splice(e.row.rowIndex, 1);
        this.gridReceivingItem.instance.refresh();
        this.gridChange.next(this.edit.dsItems);
      }
    });
  }

  valueExprFunc() {
    if (this.edit.receivingData.transferGroupId) {
      return 'id';
    }
    return 'p_id';
  }

  onEditItemClickEv(e: any) {
    e.event.preventDefault();
    e.row.data.isSaving = false;
    const obj = {};
    Object.assign(obj, e.row.data);
    this.proxy.editReceivingItem.showPopup(obj).then((res: any) => {
      if (res) {
        Object.assign(this.edit.dsItems[e.row.dataIndex], res);
        this.gridChange.next(this.edit.dsItems);
      }
      this.gridReceivingItem.instance.refresh();
    });
  }

  toolbarPreparing(e: any) {
    this.ui.prepareToolbar(e, true);

    e.toolbarOptions.items.unshift({
      widget: 'dxButton',
      options: {
        text: 'Adaugă articol',
        icon: 'plus',
        onClick: (ev: any) => {
          if (this.edit.receivingData.transferGroupId) {
            notify('Nu poti adauga articole la receptionarea unui transfer!', 'error', 3000);
            return;
          }
          if (this.proxy.globalSearch.gridInstance) {
            this.proxy.globalSearch.initGlobalSearch();
          }
          this.proxy.globalSearch.showPopup({ type: 'products' }, true).then((data) => {
            if (data) {
              const obj = {
                ModelName: 'ReceivingItems', // este irelevant
                orderIndex: this.edit.dsItems.length + 1,
                productId: data.p_id || data.id,
                name: data.p_name || data.name,
                code: data.p_code || data.code,
                // pricePerPackage: data.i_pricePerPackage || 0,
                qtyPerPackage: data.p_qtyPerPackage || data.qtyPerPackage,
                presentationMode: data.p_presentationMode || data.presentationMode,
                quantity: 1,
                fQuantity: 0,
                totalQuantity: (0 + 1), // quantity + faction
                discount: 0,
                totalTva: 0,
                adaosCom: 0,
                totalReceipt: 0,
                unitCostDiscount: 0,
                taxPercent: data.tax_percent,
                // facilityId: data.i_facilityId,
                // receivedDate: data.i_receivedDate,
                expireDate: null,
                // unitCost: data.i_unitCost || 0,
              };

              Object.assign(obj, this.fieldsCalculation(obj));
              this.proxy.editReceivingItem.showPopup(obj).then((res: any) => {
                if (res) {
                  this.edit.dsItems.push(res);
                  this.gridReceivingItem.instance.refresh();
                  if (this.edit.dsItems.length === 1) {
                    this.gridReceivingItem.instance.expandRow(res.orderIndex);
                  } else {
                    this.selectedRow = [res.orderIndex];
                  }
                  this.gridChange.next(this.edit.dsItems);
                }
              });
            }
          });
        }
      },
      location: 'after'
    });
  }

  fieldsCalculation(res: any) {
    res.unitCostDiscount = this.ds.roundTo(res.unitCost - ((res.unitCost * res.discount) / 100), 2);
    const tvaFa = Math.round(res.unitCost * res.taxPercent) / 100;
    const buyPrice = res.unitCost + tvaFa;
    res.adaosCom = this.ds.roundTo(((res.pricePerPackage - buyPrice) / buyPrice * 100), 2) || 0;
    res.totalReceipt = this.ds.roundTo(res.totalQuantity * res.pricePerPackage, 2);
    res.totalTva = this.ds.roundTo((res.pricePerPackage * res.taxPercent / 100 * res.totalQuantity), 2);
    res.totalInvoice = this.ds.roundTo(res.unitCostDiscount * res.totalQuantity, 2);
    return res;
  }
}
