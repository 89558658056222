import { Injectable } from '@angular/core';
import { EditOrderService } from '../view/supply/order/edit-order/order-edit.service';
import { PromoEditService } from '../view/sales/promo/edit-promo/promo-edit.service';
import { PromoItemService } from '../view/sales/promo/edit-item/promo-item.service';
import { EditFidelityService } from '../view/admin/fidelity-card/edit-fidelity/edit-fidelity.service';
import { EditReceiptService } from '../view/sales/receipt/edit-receipt/edit-receipt.service';
import { ReceivingService } from '../view/supply/receiving/receiving.service';
import { GlobalSearchService } from '../shared/global-search/global-search.service';
import { EditCompanyService } from '../view/admin/company/edit-company/edit-company.service';
import { EditPersonService } from '../view/admin/person/edit-person/edit-person.service';
import { EditReceivingItemService } from '../view/supply/receiving/edit-receiving-item/edit-receiving-item.service';
import { EditFacilityService } from '../view/admin/facility/edit-facility/edit-facility.service';
import { EditEntityRoleService } from '../view/admin/user/edit-entity-role/edit-entity-role.service';
import { EditInvoiceService } from '../view/sales/sales-invoice/edit-invoice/edit-invoice.service';
import { SearchFidelityService } from '../view/admin/fidelity-card/search-fidelity-card/search-fidelity-card.service';
import { EditFidelityPersonService } from '../shared/edit-fidelity-person/edit-fidelity-person.service';
import { EditPrescriptionService } from '../view/sales/receipt/edit-receipt/edit-prescription.service';
import { ReturnProductService } from '../view/supply/return/return-product.service';
import { EditWithdrawalService } from '../view/stock/withdrawal/edit-withdrawal/edit-withdrawal.service';
import { EditTransferService } from '../view/stock/transfer/edit-transfer/edit-transfer.service';

@Injectable()
export class ProxyService {

    constructor(public editInvoice: EditInvoiceService, public editReceipt: EditReceiptService, public editOrder: EditOrderService,
        public editEntityRole: EditEntityRoleService, public editFacility: EditFacilityService, public returnProduct: ReturnProductService,
        public editPromo: PromoEditService, public promoItem: PromoItemService, public globalSearch: GlobalSearchService,
        public editFidelity: EditFidelityService, public searchFidelity: SearchFidelityService, public editTransfer: EditTransferService,
        public editWithdrawal: EditWithdrawalService, public editFidelityPerson: EditFidelityPersonService,
        public receiving: ReceivingService, public editReceivingItem: EditReceivingItemService, public editCompany: EditCompanyService,
        public editPerson: EditPersonService, public editPrescription: EditPrescriptionService) {
    }
}
