import { Injectable } from '@angular/core';
import { EntityStore, EntityQuery } from '@dohu/ibis-entity';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services';
import notify from 'devextreme/ui/notify';

@Injectable()
export class PromoEditService extends iBisEditService {

  constructor(public ds: DataService) {
    super(ds.lg);
    this.validation = 'promoValidation';
  }

  getViewById(id: string) {
    return EntityStore.fromQuery(new EntityQuery('Catalog').eq('id', id)).single();
  }

  createDefault() {
    return { date: new Date() };
  }

  getById(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getViewById(id).then((obj) => {
        if (obj === null) {
          reject();
        } else {
          resolve(obj);
        }
      }, (e) => reject(e));
    });
  }

  onRemove(id: string): Promise<void> {
    return new Promise((resolve: any) => {
      const q = new EntityQuery('CatalogProduct').eq('catalogId', id);
      EntityStore.removeByQuery(q).then(() => {
        EntityStore.fromQuery(new EntityQuery('Catalog')).remove(id).then(() => {
          resolve();
        }, error => notify('Eroare la ștergere', 'error', 3000));
      }, error => notify('Eroare la ștergere', 'error', 3000));
    });

  }

  onSaveEv(): Promise<any> {
    return new Promise((resolve: any) => {
      const s = EntityStore.fromQuery(new EntityQuery('Catalog'));
      const op = this.model.id ? s.update(this.model.id, this.model) : s.insert(this.model);
      op.then(() => {
        this.model.isSaving = true;
        resolve(this.model);
      });
    });
  }

  reset(): void {
    this.model = {};
  }
}
