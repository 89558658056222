import { Injectable } from '@angular/core';
import { EntityQuery, EntityStore } from '@dohu/ibis-entity';
import { iBisEditService } from '@dohu/ibis-common';
import { DataService } from 'src/app/services';

@Injectable()
export class EditPersonService extends iBisEditService {
    constructor(public ds: DataService) {
        super(ds.lg);
        this.validation = 'personValidation';
    }
    createDefault() {
        return { maritalStatus: false };
    }
    getById(data: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if (!data.id) { reject(); }
            EntityStore.fromQuery(new EntityQuery('Person').eq('id', data.id)).single().then((obj) => {
                if (!obj) {
                    reject();
                } else {
                    resolve(obj);
                }
            });
        });
    }
    onRemove(id: string): Promise<void> {
        return EntityStore.fromQuery(new EntityQuery('Person')).remove(id);
    }
    onSaveEv(): Promise<any> {
        const obj = this.model;
        const s = EntityStore.fromQuery(new EntityQuery('Person'));
        return (this.model.id ? s.update(this.model.id, obj) : s.insert(obj));
    }
    reset(): void { }
}
