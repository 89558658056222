import { Injectable, EventEmitter } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { EntityStore, EntityStoreOptions, EntityQuery, EntityConditionGroup } from '@dohu/ibis-entity';
import CustomStore from 'devextreme/data/custom_store';
import { environment } from 'src/environments/environment';
import { iBisAuthService } from '@dohu/ibis-auth';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { iBisLanguageService } from '@dohu/ibis-common';
import * as nav from '../../assets/navigation.json';
import * as sd from '../../assets/static.json';
import { from, Observable } from 'rxjs';

@Injectable()
export class DataService {
    menuItems: any;
    isLoginModalVisible: boolean;
    isLoadPanelVisible: boolean;
    dsInvoiceStatus: { id: number; name: string; }[];
    currentFacility: any;
    dsFacility: any[];
    isFacilityLoaded = new EventEmitter<any>();
    authChanged: Subject<any> = new Subject<any>();
    dsInventoryStatus: { id: number; value: string; }[];
    previousUrl: any;
    static: any;
    environment: { production: boolean; defaultUrlServer: string; deployId: string; saasServer: string; authServer: string; };
    // tslint:disable-next-line: max-line-length

    constructor(public auth: iBisAuthService, public router: Router, public http: HttpClient, public lg: iBisLanguageService) {
        this.isLoginModalVisible = false;
        EntityStoreOptions.OnError = (jqXHR) => {
            if (jqXHR.status === 401) {
                // auth.logout();
                notify('Authorization has expired. Retry to login.', 'error', 3000);
                // this.router.navigateByUrl('/login');
                this.isLoginModalVisible = true;
            }
        };
        this.isLoadPanelVisible = false;
        setTimeout(() => {
            this.menuItems = this.getMenuItems();
            if (this.auth.isAuth) {
                // this.toggleUnprocessedTabVisible(this.menuItems);
            }
        }, 0);

        this.static = <any>sd;
        this.dsInvoiceStatus = [{ id: 0, name: 'In asteptare' }, { id: 1, name: 'Finalizat' }];
        if (sessionStorage.getItem('currentFacility')) {
            this.currentFacility = JSON.parse(sessionStorage.getItem('currentFacility'));
        }
        this.environment = environment;
        this.dsInventoryStatus = [{ id: 0, value: 'În procesare' }, { id: 1, value: 'Finalizat' }];
    }

    static formatDate(date: Date) {
        if (date == null || date === undefined) {
            return '';
        }
        date = new Date(date);
        return EntityStore.toD2(date.getDate()) + '.' +
            EntityStore.toD2((date.getMonth() + 1)) + '.' + date.getFullYear();
    }

    static formatDateTime(date: Date) {
        if (date == null || date === undefined) {
            return '';
        }
        date = new Date(date);
        return EntityStore.toD2(date.getDate()) + '.' +
            EntityStore.toD2((date.getMonth() + 1)) + '.' +
            date.getFullYear() + ' ' + date.getHours() + ':' + EntityStore.toD2(date.getMinutes());
    }

    public isAdmin(): boolean {
        return this.auth.isAuth ? this.auth.user.roles.indexOf('admin') > -1 : this.auth.isAuth;
    }

    public isPricipal() {
        return EntityStore.execute('IsPricipal', {});
    }

    public getDate(rowData: any) {
        const col = this as any;
        return DataService.formatDate(rowData[col.dataField]);
    }

    getDateTime(rowData: any) {
        const col = this as any;
        return DataService.formatDateTime(rowData[col.dataField]);
    }

    addDaysToDate(date: any, days: number): Date {
        const d = new Date(date);
        d.setDate(date.getDate() + days);
        return d;
    }

    clearDateTime(date: any) {
        const d = new Date(date);
        d.setHours(0, 0, 0, 0);
        return d;
    }

    roundTo(n, digits) {
        let negative = false;
        if (digits === undefined) {
            digits = 0;
        }
        if (n < 0) {
            negative = true;
            n = n * -1;
        }
        const multiplicator = Math.pow(10, digits);
        n = parseFloat((n * multiplicator).toFixed(11));
        n = parseFloat((Math.round(n) / multiplicator).toFixed(2));
        if (negative) {
            n = (n * -1).toFixed(2);
            n = parseFloat(n);
        }
        return n;
    }

    sendReceiving(receivingData: any) {
        return EntityStore.execute('CreateEntries', receivingData);
    }

    scanReceipt(scanText: any) {
        return EntityStore.execute('ScanReceipt', { scanText });
    }

    searchRomsoft(txt: string) {
        return EntityStore.execute('Search', { searchTxt: txt });
    }

    updateRetiredProduct(arr: any) {
        return EntityStore.execute('UpdateRetiredProduct', { productArr: arr });
    }

    async updateProfile(user: any) {
        const q = new EntityQuery('UserLogin');
        await EntityStore.fromQuery(q, false, 'id', environment.saasServer).
            update(this.auth.user.id, { 'fullName': user.nume, 'phone': user.telefon });
        this.auth.user.symbol = this.auth.getSymbolOf(user.nume);
    }

    returnProduct(arr: any) {
        return EntityStore.execute('CreateProductReturn', arr);
    }

    getInvoiceItems(invoiceId?: string): CustomStore {
        const cso = EntityStore.store(new EntityQuery('InvoiceItemView').eq('ii_invoiceId', invoiceId)
            .addOrderBy(['ii.orderIndex']), false, 'ii_id');
        return new CustomStore(cso);
    }

    getEnumValueByCode(type: string) {
        return EntityStore.fromQuery(new EntityQuery('EnumValue').eq('code', type)).single();
    }

    getParty(typeId: number): CustomStore {
        const q = new EntityQuery('PartyView').addOrderBy(['name']);
        if (typeId !== null || typeId !== undefined) {
            q.eq('p_typeId', typeId);
        }
        const cso = EntityStore.store(q, false, 'p_id');
        return new CustomStore(cso);
    }

    getOrderPartyFields() {
        const q = new EntityQuery('OrderHeader').eq('facilityId', this.currentFacility.id).eq('statusId', 1).addOrderByDesc(['date']);
        q.fields.push('id', 'refNumber', 'date', 'partyId', 'facilityId');
        const p = new EntityQuery('PartyView');
        p.fields.push('name');
        q.link('partyId', 'p.id', p);
        return EntityStore.fromQuery(q, false, 'id').query();
    }

    getCompany(): CustomStore {
        const q = new EntityQuery('Company').addOrderBy(['legalName']).link('id', 'id', new EntityQuery('Party').neq('typeId', 3));
        const cso = EntityStore.store(q, false, 'id');
        return new CustomStore(cso);
    }

    getCompanyByCUI(cui: string) {
        return EntityStore.fromQuery(new EntityQuery('Company').eq('companyId', cui)).single();
    }

    addEditSalesInvoice(obj: any, id: string) {
        const s = EntityStore.fromQuery(new EntityQuery('Invoice'));
        if (id) {
            return s.update(id, obj);
        } else {
            return s.insert(obj);
        }
    }
    inviteUser(userDetails: any) {
        return EntityStore.execute('Invite', userDetails, environment.authServer);
    }

    getActiveSubstance(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('ActiveSubstance').addOrderBy(['code']), false, 'id');
        return new CustomStore(cso);
    }

    getDrug(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('Drug').addOrderBy(['name']), false, 'id');
        return new CustomStore(cso);
    }

    getInvoicePrescriptionView(): CustomStore {
        if (!this.currentFacility || !this.currentFacility.id) {
            notify('Nu exista nici un punct de lucru configurat pe compania curenta', 'warning', 5000);
            return new CustomStore(EntityStore.store(new EntityQuery('InvoicePrescriptionView').eq('i_typeId', 99), false, 'i_id'));
        }
        const q = new EntityQuery('InvoicePrescriptionView')
            .eq('i_facilityId', this.currentFacility.id).addOrderByDesc(['eti.created']);
        const group = new EntityConditionGroup().eq('i_typeId', -3).neq('i_prescriptionId', null);
        group.useOr = true;
        q.conditionGroups.groups.push(group);
        const cso = EntityStore.store(q, false, 'i_id');
        return new CustomStore(cso);
    }

    getTodayReceiptCount() {
        if (!this.currentFacility || !this.currentFacility.id) {
            notify('Nu exista nici un punct de lucru configurat pe compania curenta', 'warning', 5000);
            return new Promise((resolve) => { resolve({}); });
        }
        const q = new EntityQuery('InvoicePrescriptionView')
            .eq('i_facilityId', this.currentFacility.id).eq('i_typeId', -3).addOrderByDesc(['i.docDate']);
        q.eq('i_docDate', EntityStore.toDateTimeFilter(this.clearDateTime(new Date())));
        return EntityStore.fromQuery(q).totalCount();
    }

    getFacilityPlanningSchedule(facilityId: string): CustomStore {
        const q = new EntityQuery('PlanningSchedule')
            .linkEq('id', 'planningScheduleId', 'FacilityPlanningSchedule', 'facilityId', facilityId).addOrderBy(['dayOfWeek']);
        const cso = EntityStore.store(q, false, 'id');
        return new CustomStore(cso);
    }

    addScheduleToFacility(scheduleId: string, facilityId: string) {
        return EntityStore.fromQuery(new EntityQuery('FacilityPlanningSchedule'))
            .insert({ facilityId: facilityId, planningScheduleId: scheduleId });
    }

    getFacilityScheduleById(scheduleId: string) {
        const q = new EntityQuery('FacilityPlanningSchedule').eq('planningScheduleId', scheduleId);
        return EntityStore.fromQuery(q).single();
    }

    removeScheduleToFacility(id: string) {
        return EntityStore.fromQuery(new EntityQuery('FacilityPlanningSchedule')).remove(id);
    }

    getInvoiceView(isSupply: boolean): CustomStore {
        if (!this.currentFacility || !this.currentFacility.id) {
            notify('Nu exista nici un punct de lucru configurat pe compania curenta', 'warning', 5000);
            return new CustomStore();
        }
        const q = new EntityQuery('InvoiceView').eq('i.facilityId', this.currentFacility.id).addOrderByDesc(['i.date', 'eti.created']);
        if (isSupply) {
            q.gte('i.typeId', 0);
        } else {
            q.lte('i.typeId', 0).neq('i.typeId', -3);
        }
        const cso = EntityStore.store(q, false, 'i_id');
        return new CustomStore(cso);
    }

    getReceipt(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('Invoice').addOrderBy(['refNumber']), false, 'id');
        return new CustomStore(cso);
    }

    getUserLogin(): CustomStore {
        const q = new EntityQuery('ApplicationRole').eq('appId', this.auth.companyId);
        const qu = new EntityQuery('UserLogin').link('id', 'userId', q);
        return new CustomStore(EntityStore.store(qu, false, 'id', environment.saasServer));
    }

    getEntityRole(userId: string): CustomStore {
        const cso = EntityStore.store(new EntityQuery('EntityRole').eq('userId', userId)
            .addOrderByDesc(['fromDate']), false, 'id');
        return new CustomStore(cso);
    }

    getUserLoginRole(): CustomStore {
        const q = new EntityQuery('UserLoginRole');
        q.link('groupId', 'rolegroupid', new EntityQuery('ApplicationConfig')
            .linkEq('id', 'configId', 'Application', 'id', this.auth.companyId));
        return new CustomStore(EntityStore.store(q, false, 'id', environment.saasServer));
    }

    getUserByRole(roleId: string) {
        const q = new EntityQuery('UserLogin');
        const qa = new EntityQuery('ApplicationRole').eq('appId', this.auth.companyId).eq('roleId', roleId);
        q.link('id', 'userId', qa);
        const cso = EntityStore.store(q, false, 'id', environment.saasServer);
        return new CustomStore(cso);
    }

    securityUsers(): CustomStore {
        const q = new EntityQuery('UserLoginView').eq('appId', this.auth.companyId).addOrderBy(['u.fullName']);
        const cso = EntityStore.store(q, false, 'u_id', environment.saasServer);
        return new CustomStore(cso);
    }

    getUserInfo(userId: string) {
        return EntityStore.fromQuery(new EntityQuery('UserLogin').eq('id', userId), false, 'id', environment.saasServer).single();
    }

    getEnumType(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('EnumType').addOrderBy(['code']), false, 'id');
        return new CustomStore(cso);
    }

    getInventoryTransferView(groupId?: string): CustomStore {
        const q = new EntityQuery('InventoryTransferView').addOrderByDesc(['itg.sendDate']);
        if (groupId) {
            q.eq('it.inventoryTransferGroupId', groupId);
        }
        const cso = EntityStore.store(q, false, 'it_id');
        return new CustomStore(cso);
    }

    getInventoryTransferGroup(fromFacilityId: string) {
        const q = new EntityQuery('InventoryTransferGroup').addOrderByDesc(['sendDate']);
        const except = new EntityQuery('InventoryTransferGroup')
            .eq('toFacilityId', fromFacilityId).eq('receiveDate', null).addOrderByDesc(['sendDate']);
        const group = new EntityConditionGroup();
        group.useOr = true;
        group.eq('fromFacilityId', fromFacilityId).eq('toFacilityId', fromFacilityId);
        q.conditionGroups.groups.push(group);
        q.except(except);
        return EntityStore.fromQuery(q, false, 'id').load();
    }

    getReceiveTransferGroup(toFacilityId: string): CustomStore {
        const cso = EntityStore.store(new EntityQuery('InventoryTransferGroupView').eq('itg.toFacilityId', toFacilityId)
            .eq('itg.receiveDate', null).addOrderByDesc(['itg.sendDate']), false, 'itg.id');
        return new CustomStore(cso);
    }

    insertEnumType(obj: any) {
        return EntityStore.fromQuery(new EntityQuery('EnumType')).insert(obj);
    }

    getProduct(): CustomStore {
        const q = new EntityQuery('Product').eq('appId', null).addOrderBy(['name']);
        q.neq('typeId', 2);
        const cso = EntityStore.store(q, false, 'id');
        return new CustomStore(cso);
    }

    getParapharmaceuticals(): CustomStore {
        const q = new EntityQuery('Product').eq('appId', null).addOrderBy(['name']);
        q.eq('typeId', 2);
        const cso = EntityStore.store(q, false, 'id');
        return new CustomStore(cso);
    }

    getUnprocessed(): CustomStore {
        const q = new EntityQuery('Product').neq('appId', null).addOrderBy(['name']);
        const cso = EntityStore.store(q, false, 'id');
        return new CustomStore(cso);
    }

    setToProcessed(id: string) {
        return EntityStore.fromQuery(new EntityQuery('Product')).update(id, { appId: null, typeId: 2 });
    }

    getPhysicalInventory(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('PhysicalInventory').addOrderByDesc(['inventoryDate']), false, 'id');
        return new CustomStore(cso);
    }

    getCashRegister(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('CashRegister').addOrderByDesc(['uploadTime']), false, 'id');
        return new CustomStore(cso);
    }

    getItemByPhysicalInventory(id: string): CustomStore {
        const cso = EntityStore.store(new EntityQuery('PhysicalInventoryItemView').eq('pii.physicalInventoryId', id)
            .addOrderBy(['p.name']), false, 'pii_id');
        return new CustomStore(cso);
    }

    getPriceChange(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('PriceChange').addOrderByDesc(['date']), false, 'id');
        return new CustomStore(cso);
    }

    getPriceChangeItemById(priceChangeId: string): CustomStore {
        const cso = EntityStore.store(new EntityQuery('PriceChangeItemView').eq('cpi.priceChangeId', priceChangeId)
            .addOrderBy(['cpi.orderIndex']), false, 'cpi_id');
        return new CustomStore(cso);
    }

    getMedics(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('Physician').addOrderBy(['fullName']), false, 'id');
        return new CustomStore(cso);
    }

    getMedicByStencil(stencil: string) {
        return EntityStore.fromQuery(new EntityQuery('Physician').eq('stencil', stencil)).single();
    }

    getMedicSpeciality(id: any): CustomStore {
        const q = new EntityQuery('PhysicianSpeciality').addOrderByDesc(['validFrom']).eq('physicianId', id);
        const cso = EntityStore.store(q, false, 'id');
        return new CustomStore(cso);
    }

    getProductById(id: string) {
        return EntityStore.fromQuery(new EntityQuery('Product').eq('id', id)).single();
    }

    getPerson(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('Person').addOrderBy(['fullName']), false, 'id');
        return new CustomStore(cso);
    }

    getEnumValue(typeKey: any): CustomStore {
        const cso = EntityStore.store(new EntityQuery('EnumValue').linkEq('typeId', 'id', 'EnumType', 'code', typeKey)
            .addOrderBy(['code']), false, 'id');
        return new CustomStore(cso);
    }

    getTax(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('Tax').addOrderBy(['percent']), false, 'id');
        return new CustomStore(cso);
    }

    getEnumValueById(typeId: any): CustomStore {
        const cso = EntityStore.store(new EntityQuery('EnumValue').eq('typeId', typeId).addOrderBy(['code']), false, 'id');
        return new CustomStore(cso);
    }

    getEnumValueByTaxId(id: any): CustomStore {
        const cso = EntityStore.store(new EntityQuery('EnumValue').eq('id', id).addOrderBy(['code']), false, 'id');
        return new CustomStore(cso);
    }

    getEnumValues(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('EnumValue').addOrderBy(['code']), false, 'id');
        return new CustomStore(cso);
    }

    getInventory(isRetired?: boolean): CustomStore {
        if (!this.currentFacility || !this.currentFacility.id) {
            notify('Nu exista nici un punct de lucru configurat pe compania curenta', 'warning', 5000);
            return new CustomStore();
        }
        const q = new EntityQuery('InventoryView').addOrderByDesc(['i.receivedDate']);
        q.eq('i.facilityId', this.currentFacility.id);
        const group = new EntityConditionGroup();
        group.useOr = true;
        group.gt('i.quantity', 0);
        group.gt('i.fQuantity', 0);
        q.conditionGroups.groups.push(group);
        if (isRetired) {
            q.eq('i_isRetired', true);
        }
        const cso = EntityStore.store(q, false, 'i_id');
        return new CustomStore(cso);
    }

    getInventoryByName(as: string): CustomStore {
        if (!this.currentFacility || !this.currentFacility.id) {
            notify('Nu exista nici un punct de lucru configurat pe compania curenta', 'warning', 5000);
            return new CustomStore();
        }
        const q = new EntityQuery('InventoryView').addOrderBy(['p.name']);
        q.gt('i_quantity', 0);
        q.eq('i.facilityId', this.currentFacility.id);
        q.like('p_activeSubstance', '%' + as + '%');
        return new CustomStore(EntityStore.store(q, false, 'i_id'));
    }

    setRetiredToInventoryProduct(obj: any, isR: boolean) {
        return EntityStore.fromQuery(new EntityQuery('Inventory')).update(obj.i_id, { isRetired: isR });
    }

    getInventoryById(id: string) {
        return EntityStore.fromQuery(new EntityQuery('InventoryView').eq('p.id', id)).single();
    }

    onGlobalSearchClose(): CustomStore {
        const q = new EntityQuery('InventoryView');
        q.gt('i_quantity', 0).gt('i_pricePerPackage', 0).addOrderBy(['i.receivedDate']);
        q.eq('i.facilityId', this.currentFacility.id);
        const cso = EntityStore.store(q, false, 'i_id');
        return new CustomStore(cso);
    }

    getPayment(type: any): CustomStore {
        const cso = EntityStore.store(new EntityQuery('PaymentView').eq('t.description', type).addOrderBy(['p.refNumber']), false, 'p_id');
        return new CustomStore(cso);
    }

    checkForInvoicePayment(invoiceId: string) {
        return EntityStore.fromQuery(new EntityQuery('PaymentApplication').eq('invoiceId', invoiceId)).single();
    }

    getInventoryVariance(id: any): CustomStore {
        const cso = EntityStore.store(new EntityQuery('InventoryVariance').eq('inventoryId', id), false, 'id');
        return new CustomStore(cso);
    }

    getInventoryVarianceView() {
        const q = new EntityQuery('InventoryVarianceView').eq('i.facilityId', this.currentFacility.id).addOrderByDesc(['iv.date']);
        const cso = EntityStore.store(q, false, 'iv_id');
        return new CustomStore(cso);
    }

    getCurrency(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('Currency'), false, 'id');
        return new CustomStore(cso);
    }

    getOrder(getFinished: boolean = true) {
        const q = new EntityQuery('OrderHeader').addOrderByDesc(['date']).eq('facilityId', this.currentFacility.id);
        if (!getFinished) {
            q.neq('statusId', 2);
        }
        const cso = EntityStore.store(q, false, 'id');
        return new CustomStore(cso);
    }

    getReservation(): CustomStore {
        const q = new EntityQuery('InventoryReservationView').eq('ir.facilityId', this.currentFacility.id).addOrderByDesc(['eti.created']);
        const cso = EntityStore.store(q, false, 'ir_id');
        return new CustomStore(cso);
    }

    deleteReservation(id: string) {
        return EntityStore.fromQuery(new EntityQuery('InventoryReservation')).remove(id);
    }

    loadOrderItems(id: string) {
        const q = new EntityQuery('OrderItemProductView').eq('oi_orderId', id).addOrderBy(['oi.orderIndex']);
        return EntityStore.fromQuery(q).load();
    }

    getOrderItem(id: string) {
        const cso = EntityStore.store(new EntityQuery('OrderItem').eq('orderId', id).addOrderBy(['orderIndex']), false, 'id');
        return new CustomStore(cso);
    }

    getOrderItemView(id: string) {
        const q = new EntityQuery('OrderItemProductView').eq('oi_orderId', id).addOrderBy(['oi.orderIndex']);
        const cso = EntityStore.store(q, false, 'oi_id');
        return new CustomStore(cso);
    }


    getFacility(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('Facility')
            .linkEq('id', 'entityId', 'EntityRole', 'userId', this.auth.user.id).addOrderBy(['name']), false, 'id');
        return new CustomStore(cso);
    }

    getFacilitiesExcept(userId: string): CustomStore {
        const ex = new EntityQuery('Facility').linkEq('id', 'entityId', 'EntityRole', 'userId', userId);
        const cso = EntityStore.store(new EntityQuery('Facility')
            .linkEq('id', 'entityId', 'EntityRole', 'userId', this.auth.user.id).except(ex).addOrderBy(['name']), false, 'id');
        return new CustomStore(cso);
    }

    getAllFacilities(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('Facility')
            .link('id', 'entityId', new EntityQuery('EntityRole')).addOrderBy(['name']), false, 'id');
        return new CustomStore(cso);
    }

    getTransferFacility(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('Facility')
            .linkEq('id', 'entityId', 'EntityRole', 'userId', this.auth.user.id)
            .neq('id', this.currentFacility.id).addOrderBy(['name']), false, 'id');
        return new CustomStore(cso);
    }
    getFacilityByUser(userId: string): CustomStore {
        const q = new EntityQuery('Facility').linkEq('id', 'entityId', 'EntityRole', 'userId', userId)
            .addOrderBy(['name']);
        return new CustomStore(EntityStore.store(q, false, 'id'));
    }

    checkInitApp() {
        return new Promise((resolve, reject) => {
            EntityStore.fromQuery(new EntityQuery('Company')
                .linkEq('id', 'id', 'Party', 'typeId', 3)).totalCount().then(number => {
                    if (number === 0) {
                        resolve('FIRST_CONFIGURATION');
                    } else if (number > 0) {
                        EntityStore.fromQuery(new EntityQuery('Facility')
                            .linkEq('id', 'entityId', 'EntityRole', 'userId', this.auth.user.id)).totalCount().then(data => {
                                if (data === 0) {
                                    resolve('NO_FACILITY');
                                } else if (data > 0) {
                                    resolve('OK');
                                } else {
                                    reject();
                                }
                            });
                    }
                });
        });
    }


    getCatalog(): CustomStore {
        const cso = EntityStore.store(new EntityQuery('Catalog').addOrderByDesc(['fromDate']), false, 'id');
        return new CustomStore(cso);
    }

    getCatalogProduct(id: any): CustomStore {
        const cso = EntityStore.store(new EntityQuery('CatalogProduct').addOrderBy(['orderIndex']).eq('catalogId', id), false, 'id');
        return new CustomStore(cso);
    }

    getProductPromotion(productId: any) {
        const qp = new EntityQuery('CatalogProduct').eq('productId', productId);
        qp.fields.push('minQuantity', 'price', 'productId');
        const q = new EntityQuery('Catalog').gte('dueDate', EntityStore.toDateTimeFilter(this.clearDateTime(new Date())))
            .link('id', 'catalogId', qp).addOrderByDesc(['fromDate']);
        q.fields.push('comments');
        return EntityStore.fromQuery(q).query();
    }

    getFidelityCardPersonView(isValid?: boolean): CustomStore {
        const q = new EntityQuery('FidelityCardPersonView').addOrderBy(['p.fullName']);
        if (isValid) {
            const group = new EntityConditionGroup();
            group.useOr = true;
            group.gte('fc_closeDate', EntityStore.toDateTimeFilter(this.clearDateTime(new Date()))).eq('fc_closeDate', null);
            q.conditionGroups.groups.push(group);
            q.lte('fc_openDate', EntityStore.toDateTimeFilter(this.clearDateTime(new Date())));
        }
        const cso = EntityStore.store(q, false, 'p_id');
        return new CustomStore(cso);
    }

    getPersonWithoutCard(): CustomStore {
        const e = new EntityQuery('Person')
            .link('id', 'id', new EntityQuery('FidelityCard')).addOrderBy(['fullName']);
        const q = new EntityQuery('Person').addOrderBy(['fullName']);
        const cso = EntityStore.store(q.except(e), false, 'id');
        return new CustomStore(cso);
    }

    getFidelityCardDiscount(description: string) {
        const q = new EntityQuery('EnumValue').link('typeId', 'id', new EntityQuery('EnumType')).eq('description', description);
        return EntityStore.fromQuery(q).single();
    }

    getFidelityCardById(id: string) {
        const q = new EntityQuery('FidelityCardPersonView').eq('fc_id', id);
        return EntityStore.fromQuery(q).single();
    }

    getPrescription() {
        const cso = EntityStore.store(new EntityQuery('Prescription').addOrderByDesc(['date']), false, 'id');
        return new CustomStore(cso);
    }

    getExpiredProducts(date: Date) {
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        const q = new EntityQuery('InventoryView').eq('i.facilityId', this.currentFacility.id).addOrderByDesc(['i.expireDate']);
        q.lt('i.expireDate', EntityStore.toDateFilter(date));
        q.neq('i.expireDate', null);
        const cso = EntityStore.store(q, false, 'i_id');
        return new CustomStore(cso);
    }

    public printReceipt(data: any) {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        // headers = headers.set('Access-Control-Allow-Origin', '*');
        // for (const key in EntityStoreOptions.Headers) {
        //     if (EntityStoreOptions.Headers.hasOwnProperty(key)) {
        //         headers = headers.append(key, EntityStoreOptions.Headers[key]);
        //     }
        // }
        this.http.post('http://127.0.0.1:50259/Print/Print', JSON.stringify(data), { headers: headers }).subscribe(
            response => {
                console.log(response);
            },
            error => notify(error, 'error', 3000)
        );
    }

    public getFile(url: string) {
        let headers = new HttpHeaders();
        for (const key in EntityStoreOptions.Headers) {
            if (EntityStoreOptions.Headers.hasOwnProperty(key)) {
                headers = headers.append(key, EntityStoreOptions.Headers[key]);
            }
        }
        this.http.get(url, { responseType: 'blob', observe: 'response' as 'body', headers: headers }).subscribe((res: any) => {
            const header = res.headers.get('Content-Disposition');
            let fileName;
            if (header) {
                const startIndex = header.indexOf('filename=') + 9;
                const endIndex = header.length;
                fileName = header.substring(startIndex, endIndex);
            } else {
                notify('Download eronat', 'error', 3000);
                return;
            }

            const blob = new Blob([res.body], { type: res.type });
            const fileUrl = window.URL.createObjectURL(blob);
            const fileLink = document.createElement('a');
            fileLink.href = fileUrl;
            fileLink.download = fileName;
            // fileLink.click();
            fileLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window })); // for mozilla
            // const pwa = window.open(fileUrl);
            // if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
            //     alert( 'Please disable your Pop-up blocker and try again.');
            // }
        }, err => {
            console.log(err);
        });
    }

    getFileInfo(entityId: any): CustomStore {
        const q = new EntityQuery('FileInfo').eq('entityId', entityId).addOrderBy(['name']);
        const cso = EntityStore.store(q, false, 'id', environment.saasServer);
        return new CustomStore(cso);
    }

    uploadFile(fileData: any, entityId: string) {
        return new Promise((promise) => {
            const file: File = fileData;
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, entityId + '_' + file.name);

            let headers = new HttpHeaders();
            for (const key in EntityStoreOptions.Headers) {
                if (EntityStoreOptions.Headers.hasOwnProperty(key)) {
                    headers = headers.append(key, EntityStoreOptions.Headers[key]);
                }
            }
            const apiUrl1 = environment.saasServer + 'UploadFile';
            this.http.post(apiUrl1, formData, { headers: headers }).subscribe(
                data => {
                    promise(data);
                },
                error => notify(error, 'error', 3000)
            );
        });
    }

    serverError(error?: any) {
        let msg = '';
        if (error && typeof error === 'string') {
            const start = error.indexOf('The exception message is');
            const end = error.indexOf('The exception stack trace is:');
            msg = error.substring(start + 24, end - 34);
        } else if (error && typeof error === 'object') {
            const start = error._body.indexOf('The exception message is');
            const end = error._body.indexOf('The exception stack trace is:');
            msg = error._body.substring(start + 24, end - 34);
        }
        if (msg.toLocaleLowerCase().indexOf('unauthorized') > -1) {
            this.isLoginModalVisible = true;
        }
        notify('Server error ' + (error ? ('[' + msg + ']') : ''), 'error', 3000);
    }

    initUserFacility() {
        this.getFacilityByUser(this.auth.user.id).load().then(data => {
            this.dsFacility = [];
            if (data.data && data.data.length > 0) {
                if (!this.currentFacility) {
                    this.currentFacility = { id: data.data[0].id, text: data.data[0].name };
                    sessionStorage.setItem('currentFacility', JSON.stringify(this.currentFacility));
                }
                for (const pc of data.data) {
                    this.dsFacility.push({ icon: 'product', id: pc.id, text: pc.name });
                }
                this.dsFacility = this.dsFacility.filter(y => y.id !== this.currentFacility.id);
            }
            this.dsFacility.push({ icon: 'plus', text: 'Adaugă punct de lucru', type: 'add' });
            this.isFacilityLoaded.emit();
        });
    }

    checkCUI = (options) => {
        if (options.value && options.value.length !== 0) {
            let c = options.value.toUpperCase();
            const hasRo = c.indexOf('RO') > -1;
            if (hasRo) {
                c = c.substr(2, c.length);
            }
            c = c.trim();
            if (c.length < 2 || c.length > 10) { return false; }
            const cifraControl = c.substr(-1);
            let cui = c.substr(0, c.length - 1);

            while (cui.length !== 9) {
                cui = '0'.concat(cui);
            }

            let suma = cui[0] * 7 + cui[1] * 5 + cui[2] * 3 + cui[3] * 2 + cui[4] * 1 + cui[5] * 7 + cui[6] * 5 + cui[7] * 3 + cui[8] * 2;
            suma = suma * 10;
            let rest = suma % 11;
            if (rest === 10) {
                rest = 0;
            }

            if (rest == cifraControl) {
                return true;
            }
            return false;
        }
        return true;
    }

    checkCNP = (options) => {
        let m;
        if (options.value && options.value.length !== 0) {
            if (m = /^([1-8])(0[0-9]|[0-9][0-9])(0[1-9]|1[0-2])(\d{2})(\d{2})(\d{3})(\d)$/.exec(options.value)) {
                const ll = parseInt(m[3], 10);
                const zz = parseInt(m[4], 10);
                switch (ll) {
                    case 2: if (zz > 29) { return false; } break;
                    case 3:
                    case 4:
                    case 6:
                    case 9:
                    case 11:
                        if (zz > 30) {
                            return false;
                        } break;
                    default:
                        if (zz > 31) {
                            return false;
                        }
                }
                const jj = parseInt(m[5], 10);
                if (jj < 0 || (jj > 46 && jj < 51) || jj > 52) {
                    return false;
                }
                const nnn = parseInt(m[6], 10);
                if (nnn < 0) {
                    return false;
                }
                const c = parseInt(m[7], 10);
                const constanta = '279146358279';
                let suma = 0;

                for (let i = 0; i < constanta.length; i++) {
                    suma = suma + parseInt(m[0].charAt(i), 10) * parseInt(constanta.charAt(i), 10);
                }
                const rest = suma % 11;
                return (((rest < 10) && (rest === c)) || ((rest === 10) && (c === 1))) ? true : false;
            }
            return false;
        }
        return true;
    }

    redirectTo(uri: string) {
        this.router.navigateByUrl('/nomenclature/medic', { skipLocationChange: true }).then(() =>
            this.router.navigateByUrl(uri));
    }

    getMenuItems(breadcrumb = false) {
        const navItems = <any>nav;
        const admin = navItems.menuItems.find((x: any) => x.infoPath === '/admin');
        admin.visible = this.isAdmin();
        const selectedItem = sessionStorage.getItem('navMenuItem');
        if (selectedItem && !breadcrumb) {
            const menuItem = navItems.menuItems.find((x: any) => {
                if (x.items) {
                    return x.items.some((y: any) => {
                        return y.path === selectedItem;
                    });
                }
            });
            if (menuItem) {
                const item = menuItem.items.find((y: any) => y.path === selectedItem);
                if (item) {
                    item.selected = true;
                }
            }
        }
        return this.clearSelectionOnLogin(navItems.menuItems);
    }

    toggleUnprocessedTabVisible(navItems: any[]) {
        this.isPricipal().then((response: any) => {
            for (let i = 0; i < navItems.length; i++) {
                if (navItems[i].infoPath === '/nomenclature') {
                    const unprocessedTab = navItems[i].items.find((x: any) => x.path === '/nomenclature/unprocessed');
                    unprocessedTab.visible = response === 'YES';
                }
            }
        });
    }

    clearSelectionOnLogin(navItems: any) {
        if (this.router.url === '/login') {
            navItems.forEach((el: any) => {
                if (el.items) {
                    el.items.forEach((eli: any) => {
                        eli.selected = false;
                        if (eli.path === '/sales/receipt') {
                            eli.selected = true;
                        }

                    });
                }
            });
        }
        return navItems;
    }
}
